import React, {Component} from "react";
import PropTypes from "prop-types";
import {drawerWidth} from "../ResponsiveDrawer";
import NestedList from "./NestedList";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";

const WORD_CLASS_NOUN_ID = 'wordClassNoun'
const WORD_CLASS_VERB_ID = 'wordClassVerb'
const WORD_CLASS_ADJ_ID = 'wordClassAdj'
const WORD_CLASS_INTERJ_ID = 'wordClassInterj'
const WORD_CLASS_PRON_ID = 'wordClassPron'
const WORD_CLASS_ADV_ID = 'wordClassAdv'
const WORD_CLASS_CONJ_ID = 'wordClassConj'
const WORD_CLASS_PREP_ID = 'wordClassPrep'
const WORD_CLASS_NUM_ID = 'wordClassNum'
const WORD_CLASS_ABBR_ID = 'wordClassAbbrev'

const styles = theme => ({
    nested: {
        flex: 1,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginLeft: 0
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 10,
            marginLeft: drawerWidth
        },
    }
});

class DictionaryEntryList extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        details: PropTypes.object.isRequired,   // folk lexikon lookup details
        openNativeDialog: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        nativeLanguage: PropTypes.string.isRequired,
        openAlertDialog: PropTypes.func,
        openSaolInflectionDialog: PropTypes.func,
        setCurrentEntryText: PropTypes.func,
        i18nStrings: PropTypes.object.isRequired,
        onOpenSelectNativeDialog: PropTypes.func.isRequired,
        textTranslateEngine: PropTypes.string.isRequired,
        isAuthenticated: PropTypes.bool.isRequired, // if true, show the "add to new words list" button
    }

    // check if the word is already in the new words list
    isInNewWords = (wordId) => {
        let found = !!(this.props.newWords.find(newWord => newWord.wordId === wordId))
        return found
    }

    render() {
        return (
            <div className={this.props.classes.nested}>
                {
                    getWordsFromXMLResponse(this.props.details).map((xmlWord, i) =>{
                        let entryText = xmlWord.getAttribute("value")
                        let entryClass = xmlWord.getAttribute("class")
                        if (entryClass === null) {
                            entryClass = ''
                        }
                        let wordId = xmlWord.getAttribute("id")
                        let entryLang = xmlWord.getAttribute("lang")

                        return (<NestedList key={i}
                                    headString={getOneEntryFromOneWord(this.props.i18nStrings, entryText, entryClass,xmlWord)}
                                    entryLang={entryLang}
                                    entryText={entryText}
                                    entryClass={entryClass}
                                    entryComment={getEntryComment(xmlWord)}
                                    wordDetails={getWordDetailsFromWordNode(xmlWord, this.props.isAuthenticated)}
                                    openNativeDialog={this.props.openNativeDialog}
                                    dispatch={this.props.dispatch}
                                    nativeLanguage={this.props.nativeLanguage}
                                    openAlertDialog={this.props.openAlertDialog}
                                    openSaolInflectionDialog={this.props.openSaolInflectionDialog}
                                    setCurrentEntryText={this.props.setCurrentEntryText}
                                    i18nStrings={this.props.i18nStrings['NestedList']}
                                    onOpenSelectNativeDialog={this.props.onOpenSelectNativeDialog}
                                    textTranslateEngine={this.props.textTranslateEngine}
                                    wordId={wordId}
                                    isInNewWords={entryLang === 'sv' ? this.isInNewWords(wordId) : false}
                                    isAuthenticated={this.props.isAuthenticated}
                        />)})
                }
            </div>
        )
    }
}

function getWordsFromXMLResponse(xmlResponse) {
    if (xmlResponse instanceof Document) {
        let words = xmlResponse.getElementsByTagName("word")
        let arrWords = [...words]
        return arrWords
    } else {
        return []
    }
}

function getOneEntryFromOneWord(i18nStrings, entryText, entryClass, xmlWord) {

    if (entryClass !== null && entryClass !== '') {
        entryText = entryText + ' ' + getEntryTypeFullName(i18nStrings,entryClass, false)
    }
    let comment =  getEntryComment(xmlWord)
    if (comment !== null && comment !== '') {
        entryText = entryText + ' (' + comment + ')'
    }
    return entryText
}

function getEntryComment(xmlWord) {
    const comment = xmlWord.getAttribute("comment")
    if (comment === null) {
        return ''
    } else {
        return comment
    }
}

export function getEntryTypeFullName(i18nStrings, shortName, isFullName) {
    const suffix = isFullName ? 'Full' : ''
    if (shortName === "nn")
        return i18nStrings[WORD_CLASS_NOUN_ID + suffix]

    if (shortName === "vb")
        return i18nStrings[WORD_CLASS_VERB_ID + suffix]

    if (shortName === "jj")
        return i18nStrings[WORD_CLASS_ADJ_ID + suffix]

    if (shortName === "in")
        return i18nStrings[WORD_CLASS_INTERJ_ID + suffix]

    if (shortName === "pn")
        return i18nStrings[WORD_CLASS_PRON_ID + suffix]

    if (shortName === "ab")
        return i18nStrings[WORD_CLASS_ADV_ID + suffix]

    if (shortName === "kn")
        return i18nStrings[WORD_CLASS_CONJ_ID + suffix]

    if (shortName === "pp")
        return i18nStrings[WORD_CLASS_PREP_ID + suffix]

    if (shortName === "rg")
        return i18nStrings[WORD_CLASS_NUM_ID + suffix]

    if (shortName === "abbrev")
        return i18nStrings[WORD_CLASS_ABBR_ID + suffix]

    return ""
}

function getWordDetailsFromWordNode(xmlWord, isAuthenticated) {

    let xmlChildNodes = [...xmlWord.children]

    let wordDetails = {}
    xmlChildNodes.forEach(xmlChildNode => {
        const tagName = xmlChildNode.tagName

        switch (tagName) {
            case 'translation':
            case 'synonym':
            case 'definition':
            case 'explanation':
                if (wordDetails[tagName]) {
                    wordDetails[tagName] += (', ' + xmlChildNode.getAttribute("value"))
                } else {
                    wordDetails[tagName] = xmlChildNode.getAttribute("value")
                }
                break
            case 'phonetic':
                let phoneticObj = {}
                phoneticObj['value'] = xmlChildNode.getAttribute("value")
                phoneticObj['soundFile'] = xmlChildNode.getAttribute("soundFile")
                wordDetails[tagName] = phoneticObj
                break
            case 'paradigm':
                const entryLang = xmlWord.getAttribute("lang")
                if (entryLang === 'sv') {   // only show inflections of Swedish word ( we may show SAOL results when the item is pressed)
                    wordDetails[tagName] = getCombinedStringFromOneTag(xmlChildNode, 'inflection')
                }
                break
            case 'example': // here we combine all example nodes to a new "examples"/"idioms"/"compound" field
            case 'idiom':
            case 'compound':
                let oneObj = {}
                oneObj['value'] = xmlChildNode.getAttribute("value")
                oneObj['translation'] = getCombinedStringFromOneTag(xmlChildNode, 'translation')
                let newField = tagName + 's'
                if (wordDetails[newField]) {
                    wordDetails[newField].push(oneObj)
                } else {
                    wordDetails[newField] = [oneObj]
                }
                break
            default:
                break
        }
    })

    return wordDetails
}

/**
 * extract values of all the child nodes of xmlParentNode which has tagName and combine the values into one string
 * @param xmlParentNode
 * @param tagName
 */
function getCombinedStringFromOneTag(xmlParentNode, tagName) {
    let childNodes = [...xmlParentNode.getElementsByTagName(tagName)]

    if (childNodes === null || childNodes.length === 0) {
        return ''
    }

    if (childNodes.length === 1) {
        return childNodes[0].getAttribute("value")
    }

    let combinedString = childNodes.reduce((acc, curr, index) => {
        let accString
        if (index === 1) {
            accString = acc.getAttribute("value")
        } else {
            accString = acc
        }
        return accString + ', ' + curr.getAttribute("value")
    })

    return combinedString
}

const mapStateToProps = state => ({
    newWords: state.newWordsStatus
})

export default connect(mapStateToProps)(withStyles(styles)(DictionaryEntryList))