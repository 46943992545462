import React from 'react'
import {connect} from "react-redux"
import PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import NativeLanguageSelect from '../small/NativeLanguageSelect'
import {showMessage} from "../../actions/actions";
import {parseString} from "../../common/util";
import {getLanguageNameFromCode} from "../../i18n/i18ncommon";


const DIALOG_TITLE_ID = 'dialogTitle'
const DIALOG_TEXT_ID = 'dialogText'
const NO_NEED_TEXT_ID = 'noNeedToSetText'
const OK_BUTTON_TEXT_ID = 'okButtonText'

class SelectLanguageDialog extends React.Component {
    static propTypes = {
        onCloseDialog: PropTypes.func.isRequired,
        selectNativeLang: PropTypes.func.isRequired,
        nativeLanguage: PropTypes.string.isRequired,     // the current native language (fetched from the store)
        i18nStrings: PropTypes.object.isRequired
    }

    closeDialogWithOk = () => {
        const { nativeLanguage, onCloseDialog, selectNativeLang, i18nStrings, showMessage } = this.props
        if ( nativeLanguage !== '' && nativeLanguage !== 'noNeed') {
            selectNativeLang(nativeLanguage)
            onCloseDialog(true)
            showMessage(parseString(i18nStrings['okWithSelected'], getLanguageNameFromCode(nativeLanguage)), 3000, 2)
        } else {
            onCloseDialog(false)
            showMessage(i18nStrings['okWithNotSelected'], 3000, 1)
        }
    }

    render() {
        // we list dispatch here just to avoid passing it to the component's child nodes
        // passing it will cause a warning of "Invalid value for prop `dispatch` on <div>"
        const { nativeLanguage, dispatch, selectNativeLang, i18nStrings, onCloseDialog, showMessage, ...other } = this.props

        return (
            <Dialog onClose={() => {
                this.closeDialogWithOk()
            }} aria-labelledby="form-dialog-title" {...other}>
                <DialogTitle id="form-dialog-title">{i18nStrings[DIALOG_TITLE_ID]}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {i18nStrings[DIALOG_TEXT_ID]}
                    </DialogContentText>
                   <NativeLanguageSelect
                       nativeLanguage={nativeLanguage}
                       i18nStrings={i18nStrings}
                       selectNativeLang={selectNativeLang}/>
                </DialogContent>
                <DialogActions>
                    <Button style={{textTransform: 'none'}}
                            onClick={() => {
                                selectNativeLang('noNeed')
                                onCloseDialog(false)
                                showMessage(i18nStrings['selectNoNeed'], 3000, 1)
                            }}
                            size="small" color="primary">
                        {i18nStrings[NO_NEED_TEXT_ID]}
                    </Button>
                    <Button onClick={() => {
                            this.closeDialogWithOk()
                        }}
                            size="small" color="primary" style={{textTransform: 'none'}}>
                        {i18nStrings[OK_BUTTON_TEXT_ID]}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    const { nativeLanguage } = state

    return {
        nativeLanguage,
    }
}

const mapDispatchToProps = (dispatch) => ({
    showMessage: (message, showTime, messageType) => dispatch(showMessage(message, showTime, messageType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguageDialog)