import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAkWYQVEgxm8saVmDEODpq5bzF_TYvm-Nw",
    authDomain: "plugghast-serverless.firebaseapp.com",
    projectId: "plugghast-serverless",
    storageBucket: "plugghast-serverless.appspot.com",
    messagingSenderId: "60935498454",
    appId: "1:60935498454:web:70026f50c933453fc8b777",
    measurementId: "G-MB2T622HR5"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

export const firebaseTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export const database = firebase.firestore()