import { combineReducers } from "redux"
import {
    LOOKUP_KEY,
    RECEIVE_DETAILS,
    MARK_REQUEST_STATUS,
    SHOW_REQUEST_FAILURE,
    REQUEST_DETAILS,
    REQUEST_FAILURE,
    SELECT_DATA_SOURCE,
    DATA_SOURCE_FOLKLEX,
    SET_NATIVE_LANGUAGE,
    SET_UI_LANGUAGE,
    SET_TEXT_TRANSLATE_ENGINE,
    CLEAN_HISTORY_DATA,
    SHOW_FUZZY_DIALOG,
    MSAPI_REQUEST_TRANS_PAGE_TYPE,
    GAPI_REQUEST_TRANS_PAGE_TYPE,
    SAOL_REQUEST_INFLECTION_TYPE,
    MSAPI_REQUEST_TRANS_TYPE,
    GAPI_REQUEST_TRANS_TYPE
} from "../actions/actions"

function nativeLanguage(state = '', action) {
    switch (action.type) {
        case SET_NATIVE_LANGUAGE:
            return action.lang
        default:
            return state
    }
}

function uiLanguage(state = '', action) {
    switch (action.type) {
        case SET_UI_LANGUAGE:
            return action.lang
        default:
            return state
    }
}

function textTranslateEngine(state = 'google', action) {
    switch (action.type) {
        case SET_TEXT_TRANSLATE_ENGINE:
            return action.engine
        default:
            return state
    }
}

// the status of the current request
const initalRequestState = {
    code: 200,
    source: '',
    isShown: false
}
function currentRequestStatus(state = initalRequestState, action) {
    switch (action.type) {
        case MARK_REQUEST_STATUS:
            return {
                source: action.dataSource,
                code: action.code,
                isShown: false
            }
        case SHOW_REQUEST_FAILURE:
            return {
                ...state,
                isShown: action.isShown
            }
        default:
            return state
    }
}

function dataSource(state = DATA_SOURCE_FOLKLEX, action) {
    switch (action.type) {
        case SELECT_DATA_SOURCE:
            return action.dataSource
        default:
            return state
    }
}

function dataBySource(state = {}, action) {
    switch (action.type) {
        case LOOKUP_KEY:
        case REQUEST_DETAILS:
        case RECEIVE_DETAILS:
        case REQUEST_FAILURE:
        case SHOW_FUZZY_DIALOG:
           return{
                ...state,
                [action.dataSource]: getData(state[action.dataSource], action)
            }
        case CLEAN_HISTORY_DATA:
            return{
                ...state,
                [action.dataSource]: {}
            }
        default:
            return state
    }
}

function getData(state = {}, action ) {
    switch (action.type) {
        case LOOKUP_KEY:
        case REQUEST_DETAILS:
        case RECEIVE_DETAILS:
        case REQUEST_FAILURE:
        case SHOW_FUZZY_DIALOG:
            return {
                ...state,
                selectRequestType: action.requestType,
                [action.requestType]: getDetails(state[action.requestType], action)
            }
        default:
            return state
    }
}

const initialDetailsState = {
    isFetching: false,
    presentIndex: -1,
    history: []
}

function getDetails(state = initialDetailsState, action ) {
    switch (action.type) {
        case LOOKUP_KEY:
            let index = searchLookupHistory(state.history, action.keyWord, action.requestType, action.srcLang, action.toLang, action.wordClass)
            if (index === -1) { // not in history so create a new node
                return {
                    ...state,
                    presentIndex: state.history.length,
                    history: state.history.concat([{
                        keyWord: action.keyWord,
                        details: {}
                    }])
                }
            } else {    // already in history
                let historyNode = state.history[index]
                if (historyNode.details instanceof Array) { // it is a 'suggestions' node
                    historyNode.isShown = false // the keyWord is requested again. Let's show the fuzzy dialog again
                }
                return {
                    ...state,
                    presentIndex: index
                }
            }
        case REQUEST_DETAILS:
            return {
                ...state,
                isFetching: true,
            }
        case RECEIVE_DETAILS:
            state.history[state.presentIndex].details = action.details
            if (action.details instanceof Array) {  // the key word has no results. We need to show the suggestion is an alert dialog
                state.history[state.presentIndex].isShown = false
            }

            // for translate page request, we need to mark languages (otherwise uers cannot translate again if they changed from/to language with the same text)
            if (action.requestType === MSAPI_REQUEST_TRANS_PAGE_TYPE || action.requestType === GAPI_REQUEST_TRANS_PAGE_TYPE ) {
                state.history[state.presentIndex].srcLang = action.srcLang
                state.history[state.presentIndex].toLang = action.toLang
            }

            // for saol request, there might be different results with the same key word, for example, kort (noun and adj.)
            if (action.requestType === SAOL_REQUEST_INFLECTION_TYPE) {
                state.history[state.presentIndex].wordClass = action.wordClass
            }

            // sometimes the api translation results are the same as the origin text, for example, "eid al fitr"
            // so we have to mark the src lang so that we could do the api call
            if (action.requestType === GAPI_REQUEST_TRANS_TYPE || action.requestType === MSAPI_REQUEST_TRANS_TYPE) {
                state.history[state.presentIndex].srcLang = action.srcLang
            }

            return {
                ...state,
                isFetching: false,
            }
        case REQUEST_FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case SHOW_FUZZY_DIALOG:
            let indexFuzzy = searchLookupHistory(state.history, action.keyWord)
            if (indexFuzzy !== -1) {
                if (state.history[indexFuzzy].details instanceof Array) { //find the history record of the key word
                    state.history[indexFuzzy].isShown = true
                }
            }

            return state
        default:
            return state
    }
}

// search lookup history with current key word. If the key word is in the history, return the index. If not, return -1
function searchLookupHistory(history, keyWord, requestType, srcLang, toLang, wordClass) {
    if (history.length === 0) {
        return -1
    }

    return history.findIndex(node => {
        if (requestType !== MSAPI_REQUEST_TRANS_PAGE_TYPE && requestType !== GAPI_REQUEST_TRANS_PAGE_TYPE) {
            if ( requestType === SAOL_REQUEST_INFLECTION_TYPE ) {
                return node.keyWord === keyWord
                    && node.wordClass === wordClass
            }

            if ( requestType === MSAPI_REQUEST_TRANS_TYPE || requestType === GAPI_REQUEST_TRANS_TYPE ) {
                return node.keyWord === keyWord
                    && node.srcLang === srcLang
            }

            return node.keyWord === keyWord
        } else { // in Translate page we need to check keyWord, srcLang and toLang
            return node.keyWord === keyWord
                && node.srcLang === srcLang
                && node.toLang === toLang
        }

    })
}

function authStatus(state = {}, action ) {
    switch (action.type) {
        case 'LOGIN':
            return {
                uid: action.uid,
                email: action.email
            };
        case 'LOGOUT':
            return {};
        default:
            return state;
    }
}

function newWordsStatus(state = [], action) {
    switch (action.type) {
        case 'SET_NEW_WORDS':
            return action.newWords
        case 'ADD_NEW_WORD':
            return [
                ...state,
                action.newWord
            ]
        case 'REMOVE_NEW_WORD':
            return state.filter(word => word.wordId !== action.wordId)
        case 'UPDATE_NEW_WORD':
            return state.map(word => {
                if ( word.wordId === action.wordId ) {
                    return Object.assign({}, word, {reviewStatus: action.newStatus})
                }
                return word
            })
        case 'CLEAN_NEW_WORDS':
            return []
        default:
            return state
    }
}

function showMessageStatus(state = null, action ) {
    switch (action.type) {
        case 'SHOW_MESSAGE':
            return {
                message: action.message,
                showTime: action.showTime,
                messageType: action.messageType
            }
        case 'CLOSE_MESSAGE':
            return null
        default:
            return state
    }
}

function loadingNewWords(state = true, action) {
    switch (action.type) {
        case 'END_NEW_WORDS_LOADING':
            return false
        case 'START_NEW_WORDS_LOADING':
            return true
        default:
            return state
    }
}

function currentWordId(state= null, action) {
    switch (action.type) {
        case 'START_FIREBASE_ACTION':
            return action.currentWordId
        case 'END_FIREBASE_ACTION':
            return null
        default:
            return state
    }
}

function doingSignin(state = false, action) {
    switch (action.type) {
        case 'DOING_SIGN_IN':
            return true
        case 'END_SING_IN':
            return false
        default:
            return state
    }
}

// 0: by date; 1: by alpha; 2: by class; 3: by review status
function newWordsSortType(state = 0, action) {
    switch (action.type) {
        case 'SET_NEW_WORDS_SORT_TYPE':
            return action.sortType
        default:
            return state
    }
}

function isShowingRememberWords(state = true, action) {
    switch (action.type) {
        case 'SHOW_REMEMBER_WORDS':
            return true
        case 'HIDE_REMEMBER_WORDS':
            return false
        default:
            return state
    }
}

const rootReducer = combineReducers({
    dataSource,
    dataBySource,
    currentRequestStatus,
    nativeLanguage,
    uiLanguage,
    textTranslateEngine,
    authStatus,
    newWordsStatus,
    showMessageStatus,
    loadingNewWords,
    currentWordId,
    doingSignin,
    newWordsSortType,
    isShowingRememberWords
})

export default rootReducer
