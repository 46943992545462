import {isObjectEmpty, timeoutableFetch} from '../common/util'
import {auth, database, facebookAuthProvider, firebaseTimestamp, googleAuthProvider} from "../firebase/firebase";
import {history, pageName} from "../containers/App";
import {getI18nStrings} from "../i18n/i18ncommon";

export const SELECT_DATA_SOURCE = 'SELECT_DATA_SOURCE'
export const DATA_SOURCE_FOLKLEX = 'folkLexikon'
export const DATA_SOURCE_MSAPI = 'msApi'
export const DATA_SOURCE_GAPI = 'googleApi'
export const DATA_SOURCE_SAOL = 'saol'

export const LOOKUP_KEY = 'LOOKUP_KEY'
export const REQUEST_DETAILS = 'REQUEST_DETAILS'
export const RECEIVE_DETAILS = 'RECEIVE_DETAILS'
export const REQUEST_FAILURE = 'REQUEST_FAILURE'
export const MARK_REQUEST_STATUS = 'MARK_REQUEST_STATUS'
export const SHOW_REQUEST_FAILURE = 'SHOW_REQUEST_FAILURE'
export const SET_NATIVE_LANGUAGE = 'SET_NATIVE_LANGUAGE'
export const SET_UI_LANGUAGE = 'SET_UI_LANGUAGE'
export const CLEAN_HISTORY_DATA = 'CLEAN_HISTORY_DATA'
export const SHOW_FUZZY_DIALOG = 'SHOW_FUZZY_DIALOG'
export const SET_TEXT_TRANSLATE_ENGINE = 'SET_TEXT_TRANSLATE_ENGINE'

export const REQUEST_WORD_TYPE = 'lookupword'
export const REQUEST_COMPLETION_TYPE = 'generatecompletion'

export const MSAPI_REQUEST_DICT_TYPE = 'dictionary'
export const MSAPI_REQUEST_TRANS_TYPE = 'translate'

export const GAPI_REQUEST_TRANS_TYPE = 'googletranslate'

//It is actually translate request but we have to store it in the store separately, otherwise it will be mixed up with the translate data in Dictionary Page
export const MSAPI_REQUEST_TRANS_PAGE_TYPE = 'translatePage'
export const GAPI_REQUEST_TRANS_PAGE_TYPE = 'googletranslatePage'

export const SAOL_REQUEST_INFLECTION_TYPE = 'inflection'


const backendBaseUrl = '/queryWord/'

// the constants for analyzing xml file (Folk lexikon lookup results)
const KEY_BEFOREXML = "2600011424"
const OFFSET_BEFOREXML = 13
const OFFSET_AFTERXML = 10

// the constants for analyzing suggestion response
const KEY_BEFOREARR = '["com.google.gwt'
const OFFSET_AFTERARR = 5

// action creators for choosing data source (folk lexikon or ms api)
export const selectDataSource = dataSource => ({
    type: SELECT_DATA_SOURCE,
    dataSource
})

export const lookupKey = (dataSource, requestType, keyWord, srcLang, toLang, wordClass) => ({
    type: LOOKUP_KEY,
    dataSource,
    requestType,
    keyWord,
    srcLang,
    toLang,
    wordClass
})

export const requestDetails = (dataSource, requestType, keyWord) => ({
    type: REQUEST_DETAILS,
    dataSource,
    requestType,
    keyWord
})

export const receiveDetails = (dataSource, requestType, keyWord, details, srcLang, toLang, wordClass) => ({
    type: RECEIVE_DETAILS,
    dataSource,
    requestType,
    keyWord,
    details,
    srcLang,
    toLang,
    wordClass
})

// in case of failed request, use this action to mark 'isFetching' as false
export const requestFailed = (dataSource, requestType, keyWord) => ({
    type: REQUEST_FAILURE,
    dataSource,
    requestType,
    keyWord
})

// here we mark the current request status. If any failure, we can use the status to show the alert
export const markCurrentRequestStatus = (dataSource, code) => ({
    type: MARK_REQUEST_STATUS,
    dataSource,
    code
})

export const setNativeLanguage = (lang) => ({
    type: SET_NATIVE_LANGUAGE,
    lang
})

export const setUiLanguage = (lang) => ({
    type: SET_UI_LANGUAGE,
    lang
})

export const setTextTranslateEngine = (engine) => ({
    type: SET_TEXT_TRANSLATE_ENGINE,
    engine
})

// when changing native language, we have to clean up the ms api history
export const cleanHistoryData = (dataSource) => ({
    type: CLEAN_HISTORY_DATA,
    dataSource
})

export const showRequestFailure = (isShown) => ({
    type: SHOW_REQUEST_FAILURE,
    isShown
})

export const showFuzzyDialog = (dataSource, requestType, keyWord, isShown) => ({
    type: SHOW_FUZZY_DIALOG,
    dataSource,
    requestType,
    keyWord
})

function fetchDetails(dataSource, requestType, keyWord, srcLang, toLang, wordClass) {
    return dispatch => {
        dispatch(requestDetails(dataSource, requestType, keyWord))

        switch (dataSource) {
            case DATA_SOURCE_FOLKLEX:
                fetchDataFromFolkLexikon(dispatch, dataSource, requestType, keyWord)
                break
            case DATA_SOURCE_MSAPI:
                fetchDataFromMsApi(dispatch, dataSource, requestType, keyWord, srcLang, toLang)
                break
            case DATA_SOURCE_GAPI:
                fetchDataFromGoogleApi(dispatch, dataSource, requestType, keyWord, srcLang, toLang)
                break
            case DATA_SOURCE_SAOL:
                fetchDataFromSaol(dispatch, dataSource, requestType, keyWord, wordClass)
                break
            default:
                break
        }
    }
}

function fetchDataFromMsApi(dispatch, dataSource, requestType, keyWord, srcLang, toLang) {
    // here we handle both translate and translatePage request types
    const backendUrl = backendBaseUrl + requestType.replace('Page', '') + '/' + srcLang + '/' + toLang + '/' + encodeURIComponent(keyWord)
    //const backendUrl = backendBaseUrl + requestType.replace('Page', '') + '/' + srcLang + '/' + toLang + '/' + keyWord

    fetchData(backendUrl, dispatch, dataSource, requestType, keyWord, srcLang, toLang)
}

function fetchDataFromGoogleApi(dispatch, dataSource, requestType, keyWord, srcLang, toLang) {
    // here we handle both translate and translatePage request types
    const backendUrl = backendBaseUrl + requestType.replace('Page', '') + '/' + srcLang + '/' + toLang + '/' + encodeURIComponent(keyWord)
    //const backendUrl = backendBaseUrl + requestType.replace('Page', '') + '/' + srcLang + '/' + toLang + '/' + keyWord
    fetchData(backendUrl, dispatch, dataSource, requestType, keyWord, srcLang, toLang)
}

function fetchDataFromFolkLexikon(dispatch, dataSource, requestType, keyWord) {
    const backendUrl = backendBaseUrl + requestType + '/' + keyWord
    fetchData(backendUrl, dispatch, dataSource, requestType, keyWord)
}

function fetchDataFromSaol(dispatch, dataSource, requestType, keyWord, wordClass) {
    const backendUrl = backendBaseUrl + requestType + '/' + keyWord
    fetchData(backendUrl, dispatch, dataSource, requestType, keyWord, '', '', wordClass)
}

function fetchData(fetchUrl, dispatch, dataSource, requestType, keyWord, srcLang, toLang, wordClass) {
    timeoutableFetch(encodeURI(fetchUrl))
        .then(response => dataSource === DATA_SOURCE_MSAPI ? response.json() : response.text())
        .then(contents => {
            dispatch(markCurrentRequestStatus(dataSource, 200))    // mark request status
            let details
            if (dataSource === DATA_SOURCE_FOLKLEX) {
                details = analyzeFolketResponseText(contents, requestType, keyWord)
            } else if (dataSource === DATA_SOURCE_MSAPI) {
                details = contents
            } else if (dataSource === DATA_SOURCE_GAPI) {
                details = analyzeGoogleApiResponseText(contents, toLang)
            } else {    // SAOL
                details = analyzeSaolResponseText(contents, wordClass)
            }
            dispatch(receiveDetails(dataSource, requestType, keyWord, details, srcLang, toLang, wordClass))
        })
        .catch(error => {
            console.log('Network error: ' + error.message)
            let code
            switch (error.message) {
                case 'Failed to fetch':
                    code = 400
                    break
                case 'Time out':
                    code = 408
                    break
                default:
                    if (error.message.startsWith('Unexpected token'))
                        code = 404
                    else
                        code = 400
            }
            dispatch(markCurrentRequestStatus(dataSource, code))
            dispatch(requestFailed(dataSource, requestType, keyWord))
        })
}

// Google Api will return translated text. We make a fake json object so that we can reuse the code
function analyzeGoogleApiResponseText(responseText, toLang) {
    // for some network issue, for example, proxy error, google api still returns a string of error message
    // then the error message will be shown and stored as a "translation"
    // but for ms api, although the response is also an error string in this case, the error will be caught
    // when paring json (the response of ms api is in json format)
    // so here we check if the response string contains query uri, which means some error happened
    if (responseText.indexOf('/queryWord/googletranslate/') !== -1) {
        throw new Error("google api error")
    }

    return [
        {
            translations: [
                {
                    text: responseText,
                    to: toLang
                }
            ]
        }
    ]
}

const DictWordClassToSAOL = {
    nn: 'substantiv',
    vb: 'verb',
    jj: 'adjektiv'
}

function analyzeSaolResponseText(responseText, wordClass) {
    if (responseText.indexOf('i SAOL gav inga svar') !== -1) {
        return ''
    }

    // the ordformer information is a table in the html string
    // but sometimes saol will return more than one tables. So we have to choose the right one according to wordClass
    // examples: slå, tåg, sluta, kort, sticka
    const inflectionText = extractInflectionInfo(responseText, wordClass)

    const tableStart = inflectionText.indexOf('<table')
    const tableEnd = inflectionText.indexOf('table>')
    if (tableStart === -1 || tableEnd === -1) {
        return ''
    }

    return inflectionText.substring(tableStart, tableEnd + 6)
}

function extractInflectionInfo(responseText, wordClass) {
    switch (wordClass) {
        // Folket seems only provide inflection info for these three classes
        // So only the words with these classes will have inflection items in their entry
        case 'nn':
        case 'vb':
        case 'jj':
            const className = DictWordClassToSAOL[wordClass]
            let classNameIndex = responseText.indexOf('<a class="ordklass">' + className)
            if ( classNameIndex !== -1 ) {  // find the inflection with the same word class
                const tableIndex = responseText.indexOf('<table', classNameIndex)
                if ( tableIndex !== -1 ) {
                    return responseText.substring(tableIndex)
                }
            }

            return ''
        default:
            return responseText
    }
}

function analyzeFolketResponseText(responseText, requestType, keyWord) {
    if (requestType === REQUEST_WORD_TYPE) {
        if (responseText.indexOf('\\u003Cword') !== -1) {    // it is a "real" response: Folket found the word
            let subStart = responseText.indexOf(KEY_BEFOREXML) + OFFSET_BEFOREXML
            let subEnd = responseText.length - OFFSET_AFTERXML - keyWord.length
            let xmlString = responseText.substring(subStart, subEnd)

            // Remove some illegal char
            xmlString = xmlString.replace(/\\u003C/g, "<")
            xmlString = xmlString.replace(/\\u003D/g, "=")
            xmlString = xmlString.replace(/\\u003E/g, ">")
            xmlString = xmlString.replace(/\\u0026/g, "&")

            xmlString = xmlString.replace(/\\n/g, "")
            xmlString = xmlString.replace(/\\"/g, "\"")
            xmlString = xmlString.replace(/>","</g, "><")
            xmlString = xmlString.replace(/&#39;/g, "'")
            xmlString = xmlString.replace(/&quot;/g, "")

            xmlString = xmlString.replace(/&amp;quot;/g, "'")
            xmlString = xmlString.replace(/&amp;/g, "")
            xmlString = xmlString.replace(/&quot;/g, "")
            xmlString = xmlString.replace(/#39;/g, "'")

            // Make a complete xml file for further analysis
            xmlString = "<?xml version=\"1.0\" encoding=\"UTF-8\"?><result>" + xmlString + "</result>";

            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(xmlString,"text/xml")

            return xmlDoc
        } else {    // cannot find the word in Folket. In this case, it will return a list of suggestions
            let arrString = responseText.substring(responseText.indexOf('["se.algoritmica'), responseText.length - OFFSET_AFTERARR)
            let arrSuggestions = JSON.parse(arrString)

            return arrSuggestions.filter(suggestion => !suggestion.startsWith('se.algoritmica')
                && !suggestion.startsWith('[I/2970817851')
                && !suggestion.startsWith('[Ljava'))
                //&& suggestion !== keyWord)    // keep the original invalid input and use the item to show 'try native translation'
        }
    } else {
        // Folket will return the suggestions based on current input
        let arrString = responseText.substring(responseText.indexOf(KEY_BEFOREARR), responseText.length - OFFSET_AFTERARR)

        let arrSuggestions = JSON.parse(arrString)

        return arrSuggestions.filter(suggestion => !suggestion.startsWith('com.google')
                                                && !suggestion.startsWith('java.util')
                                                && !suggestion.startsWith('se.algoritmica')
                                                && !suggestion.startsWith('<img'))
    }
}

function shouldFetchDetails(dataSource, requestType, state, wordClass) {
    const {isFetching, history, presentIndex} = state.dataBySource[dataSource][requestType]

    const keyWord = history[presentIndex].keyWord
    const details = history[presentIndex].details
    const wordClassHistory = history[presentIndex].wordClass

    return !isFetching && keyWord !== null && keyWord !== '' && (isObjectEmpty(details) || (requestType === SAOL_REQUEST_INFLECTION_TYPE && wordClassHistory !== wordClass))
}

export function fetchDetailsIfNeeded(dataSource, requestType, keyWord, srcLang, desLang, wordClass) {
    return (dispatch, getState) => {
        if (shouldFetchDetails(dataSource, requestType, getState(), wordClass)){
            return dispatch(fetchDetails(dataSource, requestType, keyWord, srcLang, desLang, wordClass))
        }
    }
}

/*
    forPage: boolean, if the request is for the translate page
 */
export function dispatchQueryRequest(dispatch, transTextEngine, forPage, keyWord, srcLang, desLang) {
    let dataSourceType = transTextEngine + 'Api'
    let textTransType = transTextEngine === 'ms' ? MSAPI_REQUEST_TRANS_TYPE : GAPI_REQUEST_TRANS_TYPE
    if (forPage) {
        textTransType += 'Page'
    }

    dispatch(selectDataSource(dataSourceType))

    dispatch(lookupKey(dataSourceType, textTransType, keyWord, srcLang))
    dispatch(fetchDetailsIfNeeded(dataSourceType, textTransType, keyWord, srcLang, desLang))
}

export const login = (uid, email) => ({
    type: 'LOGIN',
    uid,
    email
});

export const startLogin = () => {
    return doSigninWithPopup(googleAuthProvider)
};

const doSigninWithPopup = (provider) => {
    return (dispatch, getState) => {
        dispatch(doingSignin())
        return auth.signInWithPopup(provider)
            .then((result) => {
                history.push("/" + pageName.DICTIONARY.description)
                dispatch(endSignin())
            }).catch((error) => {
                showErrorMessage(error, dispatch, getState)
                dispatch(endSignin())
            });
    };
}

export const startFacebookLogin = () => {
    return doSigninWithPopup(facebookAuthProvider)
};

export const startEmailLogin = (email, password) => {
    return (dispatch, getState) => {
        dispatch(doingSignin())
        return auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                history.push("/" + pageName.DICTIONARY.description)
                dispatch(endSignin())
            })
            .catch((error) => {
                showErrorMessage(error, dispatch, getState)
                dispatch(endSignin())
            });

    };
};

export const startEmailSignup = (email, password) => {
    return (dispatch, getState) => {
        return auth.createUserWithEmailAndPassword(email, password)
            .then((result) => {
                history.push("/" + pageName.DICTIONARY.description)
            }).catch((error) => {
                showErrorMessage(error, dispatch, getState)
            });
    };
};

const showErrorMessage = (error, dispatch, getState) => {
    const errorCode = error.code;
    const uiLanguage = getState().uiLanguage
    const errorMessage = getI18nStrings(uiLanguage, 'FirebaseErrors')[errorCode]
    dispatch(showMessage(errorMessage, 5000, 0))
}

export const logout = () => ({
    type: 'LOGOUT'
});

export const startLogout = () => {
    return () => {
        return auth.signOut();
    };
};

export const startResetPassword = (email, messageText) => {
    return (dispatch, getState) => {
        return auth.sendPasswordResetEmail(email)
            .then(() => {
                dispatch(showMessage(messageText, 3000, 2))
            }).catch((error) => {
                showErrorMessage(error, dispatch, getState)
            });
    };
}

export const setNewWords = (newWords) => ({
    type: 'SET_NEW_WORDS',
    newWords
})

export const startSetNewWords = (userRef) => {
    return (dispatch, getState) => {
        const newWords = []
        // load new words of the user
        userRef.collection('newWords').get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    //console.log(doc.id, " => ", doc.data())
                    newWords.push({
                        wordId: doc.id,
                        ...doc.data()
                    })
                })
                dispatch(setNewWords(newWords))
                dispatch(endNewWordsLoading())
        }).catch(error => {
            console.log('Error getting docs: ', error)
            dispatch(showMessage(error.message, 5000, 0))
            dispatch(endNewWordsLoading())
        })
    }
}

export const addNewWord = (newWord) => ({
    type: 'ADD_NEW_WORD',
    newWord
})

export const startAddNewWord = (wordData, successMessage) => {
    return (dispatch, getState) => {
        const uid = getState().authStatus.uid
        const userRef = database.collection('users').doc(uid)
        let wordRef = userRef.collection('newWords').doc(wordData.wordId)
        wordRef.get().then(doc => {
            if ( doc.exists ) {
                dispatch(endFirebaseAction())
                showErrorMessage({code: 'newWordExists'}, dispatch, getState)
            } else {
                wordRef.set({
                    entryText: wordData.entryText,
                    entryClass: wordData.entryClass,
                    entryComment: wordData.entryComment,
                    wordDetails: wordData.wordDetails,
                    reviewStatus: wordData.reviewStatus,
                    addedTime: firebaseTimestamp()
                }).then(() => {
                    wordRef = userRef.collection('newWords').doc(wordData.wordId)
                    wordRef.get().then(doc => {
                        if (doc.exists) {
                            dispatch(addNewWord({
                                wordId: doc.id,
                                ...doc.data()
                            }))
                            dispatch(endFirebaseAction())
                            dispatch(showMessage(successMessage, 3000, 2))
                        }
                    }).catch(error => {
                        dispatch(endFirebaseAction())
                        dispatch(showMessage(error.message, 5000, 0))
                    })
                }).catch(error => {
                    dispatch(endFirebaseAction())
                    dispatch(showMessage(error.message, 5000, 0))
                })
            }
        }).catch(error => {
            dispatch(endFirebaseAction())
            dispatch(showMessage(error.message, 5000, 0))
        })
    }
}

export const startRemoveNewWord = (wordId, successMessage) => {
    return (dispatch, getState) => {
        dispatch(startFirebaseAction(wordId))
        const uid = getState().authStatus.uid
        const userRef = database.collection('users').doc(uid)
        let wordRef = userRef.collection('newWords').doc(wordId)
        wordRef.get().then(doc => {
            if ( doc.exists ) {
                console.log('The word is in the list. Starting to remove it.')
                wordRef.delete().then(() => {
                    console.log('the word deleted successfully')
                    dispatch(removeNewWord(wordId))
                    dispatch(endFirebaseAction())
                    dispatch(showMessage(successMessage, 3000, 2))
                }).catch(error => {
                    dispatch(endFirebaseAction())
                    dispatch(showMessage(error.message, 5000, 0))
                })
            } else {
                dispatch(endFirebaseAction())
                showErrorMessage({code: 'newWordNotExists'}, dispatch, getState)
            }
        }).catch(error => {
            dispatch(endFirebaseAction())
            dispatch(showMessage(error.message, 5000, 0))
        })
    }
}

export const removeNewWord = (wordId) => ({
    type: 'REMOVE_NEW_WORD',
    wordId
})

// when logging out, the current new words list should be cleaned
export const cleanNewWords = () => ({
    type: 'CLEAN_NEW_WORDS'
})

export const startUpdateNewWord = (wordId, newStatus, successMessage) => {
    return (dispatch, getState) => {
        dispatch(startFirebaseAction(wordId))
        const uid = getState().authStatus.uid
        const userRef = database.collection('users').doc(uid)
        let wordRef = userRef.collection('newWords').doc(wordId)
        wordRef.get().then(doc => {
            if ( doc.exists ) {
                console.log('The word is in the list. Starting to update it.')
                wordRef.update({
                    reviewStatus: newStatus
                }).then(() => {
                    console.log('the word updated successfully')

                    dispatch(updateNewWord(wordId, newStatus))
                    dispatch(endFirebaseAction())
                    dispatch(showMessage(successMessage, 3000, 2))
                }).catch(error => {
                    dispatch(endFirebaseAction())
                    dispatch(showMessage(error.message, 5000, 0))
                })
            } else {
                dispatch(endFirebaseAction())
                showErrorMessage({code: 'newWordNotExists'}, dispatch, getState)
            }
        }).catch(error => {
            dispatch(endFirebaseAction())
            dispatch(showMessage(error.message, 5000, 0))
        })
    }
}

// any Firebase action (auth, database) might take time.
// so it's better to show some progress during waiting
// we use a global state to control the progress bar/circle on/off
export const startFirebaseAction = (currentWordId) => ({
    type: 'START_FIREBASE_ACTION',
    currentWordId
})

export const endFirebaseAction = () => ({
    type: 'END_FIREBASE_ACTION'
})

export const doingSignin = () => ({
    type: 'DOING_SIGN_IN'
})

export const endSignin = () => ({
    type: 'END_SING_IN'
})

export const updateNewWord = (wordId, newStatus) => ({
    type: 'UPDATE_NEW_WORD',
    wordId,
    newStatus
})

export const startNewWordsLoading = () => ({
    type: 'START_NEW_WORDS_LOADING'
})

export const endNewWordsLoading = () => ({
    type: 'END_NEW_WORDS_LOADING'
})

export const setNewWordsSortType = (sortType) => ({
    type: 'SET_NEW_WORDS_SORT_TYPE',
    sortType
})

export const hideRememberWords = () => ({
    type: 'HIDE_REMEMBER_WORDS'
})

export const showRememberWords = () => ({
    type: 'SHOW_REMEMBER_WORDS'
})

// 0: alert; 1: information; 2: success
export const showMessage = (message, showTime = 5000, messageType = 1) => ({
    type: 'SHOW_MESSAGE',
    message,
    showTime,
    messageType
})

export const closeMessage = () => ({
    type: 'CLOSE_MESSAGE'
})



