import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { copyTextToClipboard, isObjectEmpty } from '../../common/util'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AssignmentReturnOutlined } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import {Translate} from "mdi-material-ui";
import {dispatchQueryRequest} from "../../actions/actions";

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        textTransform: 'none'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
});

class TryLuckResultDialog extends React.Component {
    static propTypes = {
        svText: PropTypes.string.isRequired,    // the word input by the user
        nativeTranslateData: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        onDialogClose: PropTypes.func.isRequired,
        i18nStrings: PropTypes.object.isRequired,

        nativeLanguage: PropTypes.string.isRequired,
        textTranslateEngine: PropTypes.string.isRequired,
        openNativeDialog: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        onOpenSelectNativeDialog: PropTypes.func.isRequired
    }

    handleClose = () => {
        this.props.onDialogClose()
    }

    handleCopyText = () => {
        copyTextToClipboard(this.props.svText)
    }

    handleNativeTranslateClick = () => {
        this.props.onDialogClose()

        const { nativeLanguage, onOpenSelectNativeDialog } = this.props
        if (nativeLanguage === '') {// detected browser language is not in the language list
            onOpenSelectNativeDialog(this.getNativeFromLuckResult)
        } else if (nativeLanguage !== 'noNeed') {
            this.getNativeFromLuckResult()
        }
    }

    getNativeFromLuckResult = () => {
        const { nativeLanguage, textTranslateEngine, openNativeDialog, dispatch, svText, nativeTranslateData, isFetching } = this.props

        if ( !isFetching && !isObjectEmpty(nativeTranslateData) ) {
            // when using google api, the response just a "fake" array with one element
            const luckResult = nativeTranslateData['translations'][0].text
            dispatchQueryRequest(dispatch, textTranslateEngine, false, luckResult, 'en', nativeLanguage)
            openNativeDialog(svText, luckResult, 'en')
        }

    }

    render() {
        const {svText, nativeTranslateData, isFetching, classes, i18nStrings, onDialogClose,
            nativeLanguage, dispatch, textTranslateEngine, onOpenSelectNativeDialog, openNativeDialog, ...other} = this.props

        let translations
        // svText is from Dictionary page's state. When switching between the pages, the state will be lost. then we don't need to check further
        // Because null == undefined is true, the code will catch both null and undefined.
        if (svText === '' || nativeTranslateData == null || isObjectEmpty(nativeTranslateData))
            translations = []
        else
            translations = isFetching ? [] : nativeTranslateData['translations']

        // NOTE: for "try luck", we only use google api
        const fullText = i18nStrings['svLabelText'] + svText

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle onClick={() => this.handleClose()} id="simple-dialog-title">{i18nStrings['title']}</DialogTitle>
                <div >
                    {
                        isFetching
                            ? (
                                <CircularProgress size={68} color="primary" />
                            )
                            : (<div>
                                <List>
                                    {
                                        translations.length > 0 ?
                                            (translations.map((translation, i) => (
                                                <ListItem button key={i} onClick={() => this.handleNativeTranslateClick()}>
                                                    <ListItemText
                                                        primary={i18nStrings['translationLabel'] + translation.text}
                                                        secondary={fullText} />
                                                    <Translate style={{marginLeft: 10}}/>
                                                </ListItem>
                                            ))):
                                            (<p>{i18nStrings['noResultsText']}</p>)}
                                </List>
                            </div>)
                    }

                </div>
                <DialogActions>
                    <Button onClick={this.handleCopyText} size="small" color="primary" className={classes.button}>
                        <AssignmentReturnOutlined className={classes.iconSmall} />
                        {i18nStrings['copyToClipboardText']}
                    </Button>
                    <Button onClick={() => {
                        this.handleClose()
                    }}
                            size="small" color="primary" className={classes.button}>
                        {i18nStrings['okButtonText']}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    nativeLanguage: state.nativeLanguage,
    textTranslateEngine: state.textTranslateEngine
})

export default connect(mapStateToProps)(withStyles(styles)(TryLuckResultDialog))