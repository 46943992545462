import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PropTypes from "prop-types"
import Button from '@material-ui/core/Button'
import { drawerWidth } from "../ResponsiveDrawer"
import Avatar from '@material-ui/core/Avatar'



export default function SpecialLetterButtons(props) {
    const theme = useTheme();
    const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const { onClickSpecialButton, considerScreenSize, withFrame, hasMarginLeftMost } = props

    let marginLeft = hasMarginLeftMost ? 10 : 0
    if (considerScreenSize && isBigScreen) {
        marginLeft += drawerWidth
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', marginLeft: marginLeft}}>
            {createOneButton('å', onClickSpecialButton, withFrame)}
            {createOneButton('ä', onClickSpecialButton, withFrame)}
            {createOneButton('ö', onClickSpecialButton, withFrame)}
        </div>
    )
}

function createOneButton(letter, onClickButton, withFrame) {
    if (withFrame) {
        return (
            <Button onClick={() => onClickButton(letter)} style={{marginLeft: letter === 'å' ? 0 : 5}} variant="outlined" size="small" color="primary">{letter.toUpperCase()}</Button>
        )
    } else {
        return (
            <Avatar onClick={() => onClickButton(letter)}  style={{marginLeft: 0, color: '#4054b0', backgroundColor: '#fafafa'}}>{letter.toUpperCase()}</Avatar>
        )
    }
}

SpecialLetterButtons.propTypes = {
    onClickSpecialButton: PropTypes.func.isRequired,
    considerScreenSize: PropTypes.bool.isRequired,  // when positioning the component, do we need to consider screen size
    withFrame: PropTypes.bool,                      // if the buttons have frames
    hasMarginLeftMost: PropTypes.bool,              // if the compoment needs margin in leftmost
}

SpecialLetterButtons.defaultProps = {
    withFrame: true,
    hasMarginLeftMost: true
}