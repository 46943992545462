import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from "redux";
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {logger} from "redux-logger";
import reducer from './reducers/reducers'

import { auth, database, firebaseTimestamp } from './firebase/firebase'
import {login, logout, startSetNewWords, cleanNewWords, endNewWordsLoading, showMessage} from "./actions/actions";

import './css/index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

import { composeWithDevTools } from 'redux-devtools-extension'
import * as actionCreators from './actions/actions'

const middlewares = [thunk]
if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger)
}

const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });
const store = createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(...middlewares)
    )
)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// save auth info in the store
auth.onAuthStateChanged((user) => {
    if (user) {
        // check if the user has been written in db
        const userRef = database.collection('users').doc(user.uid);
        userRef.get().then(doc => {
            if ( doc.exists ) {
                store.dispatch(startSetNewWords(userRef))
            } else {
                store.dispatch(endNewWordsLoading())    // no need to load new words
                userRef.set({
                    email: user.email,
                    createdTime: firebaseTimestamp()
                }).then(() => {
                    console.log("the user was added")
                }).catch(error => {
                    console.log('Error adding user in db: ', error)
                    store.dispatch(showMessage(error.message, 5000, 0))
                })
            }
        }).catch(error => {
            console.log('Error reading user in db: ', error)
            store.dispatch(showMessage(error.message, 5000, 0))
        })

        store.dispatch(login(user.uid, user.email));
    } else {
        store.dispatch(logout())
        store.dispatch(cleanNewWords())
        store.dispatch(endNewWordsLoading())
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
