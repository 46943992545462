import React from "react"
import PropTypes from "prop-types"
import {appbarHeigh, drawerWidth} from "../ResponsiveDrawer"
import {withStyles} from "@material-ui/core"
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'


const styles = theme => ({
    nested: {
        flex: 1,
        maxWidth: 500,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,
            marginLeft: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 10,
            marginLeft: drawerWidth + 50
        },
    },
    headimage: {
        height: 250,
        overflowY: 'hidden'
    }
});

class InformationPaper extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        infoTitle: PropTypes.string.isRequired,
        infoText: PropTypes.string.isRequired,
        headimage: PropTypes.string.isRequired
    }

    render() {
        return (
            <div className={this.props.classes.nested}>
                <Card style={{ marginTop: appbarHeigh + 30}}>
                    <CardActionArea>
                        <CardMedia
                            className={this.props.classes.headimage}
                            image={this.props.headimage}
                            title="Page Head"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {this.props.infoTitle}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" align='left' component="p" style={{whiteSpace: 'pre-line'}}>
                                {this.props.infoText}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

            </div>
        )
    }
}

export default withStyles(styles)(InformationPaper)