import {Component} from "react";
import PropTypes from "prop-types";
import {
    fetchDetailsIfNeeded,
    lookupKey,
    REQUEST_WORD_TYPE,
    REQUEST_COMPLETION_TYPE,
    DATA_SOURCE_FOLKLEX,
    selectDataSource,
    showFuzzyDialog,
    dispatchQueryRequest
} from "../../actions/actions";
import Button from '@material-ui/core/Button'
import {connect} from "react-redux";
import React from "react";
import {appbarHeigh} from "../ResponsiveDrawer";
import DictionaryEntryList from "../small/DictionaryEntryList";
import AutoSuggestInput from '../small/AutoSuggestInput'
import IconButton from '@material-ui/core/IconButton'
import Cancel from '@material-ui/icons/Cancel'
import FuzzyLookupDialog from "../dialogs/FuzzyLookupDialog"
import { isObjectEmpty, loadStringFromLocalStorage, saveStringToLocalStorage, LOCAL_STORAGE_KEY_NAME } from '../../common/util'
import SaolInflectionDialog from '../dialogs/SaolInflectionDialog'
import SpecialLetterButtons from '../small/SpecialLetterButtons'

const LOOKUP_BUTTON_TEXT_ID = 'lookupButtonText'

class DictionaryPage extends Component {
    static propTypes = {
        isFetching: PropTypes.bool.isRequired,

        keyWord: PropTypes.string.isRequired,
        //details: PropTypes.object.isRequired,   // folk lexikon lookup details

        // when no result of keyWord, a fuzzy dialog will show up. isFuzzyDialogShown is used to fetch the visibility info of the dialog in state
        isFuzzyDialogShown: PropTypes.bool,

        isFetchingDictSuggestions: PropTypes.bool.isRequired,
        dictSuggestions: PropTypes.array.isRequired,  // the suggestions returned from folket dictionary

        suggestions: PropTypes.array.isRequired,    // we use lookup history as the suggestions for the autocomplete input

        //msApiNativeText: PropTypes.string.isRequired,   // input text for native translation
        apiDetails: PropTypes.object.isRequired,      // ms api response details
        apiIsFetching: PropTypes.bool.isRequired,

        nativeLanguage: PropTypes.string.isRequired,

        i18nStrings: PropTypes.object.isRequired,

        dispatch: PropTypes.func.isRequired,

        onOpenSelectNativeDialog: PropTypes.func.isRequired,
        handleNativeLangChange: PropTypes.func.isRequired,
        onOpenNativeTranslationDialog: PropTypes.func.isRequired,
        onOpenTryLuckDialog: PropTypes.func.isRequired,

        openAlertDialog: PropTypes.func.isRequired,
        textTranslateEngine: PropTypes.string.isRequired
    }

    constructor (props) {
        super(props)
        this.state = {
            wordLookup: '',
            shouldGenerateSuggestions: true,
            openNativeDialog: false,
            svText: '',      // the original text which will be used in NativeTranslateDialog
            enText: '',      // the English text for NativeTranslateDialog
            srcLang: '',     // in Native dialog, which language the native translation comes from

            openFuzzyDialog: false,

            currentEntryText: '',   // the current clicked dictionary entry text. For a verb as an example,, it should be the verb's infinit form
            currentEntryClass: '',  // verb, noun...
            openSaolDialog: false,
        }

        this.handleLookupChange = this.handleLookupChange.bind(this)
        this.lookup = this.lookup.bind(this)
    }

    // click lookup button
    lookup() {

        const { wordLookup } = this.state
        this.dispatchFolketLookupAction(wordLookup)
    }

    handleLookupChange(newValue, method) {
        const inputValue = newValue.replace(/[^A-Za-zÅåÄäÖö\s]/g, '')
        const {dispatch} = this.props
        let shouldGenerateSuggestions = true

        // if typing more than three letters, send request to folket to get suggestions
        // NOTE: if the method is invoked by selecting a suggestion, it means it must be a full word. So no need to send the request
        if (method === 'click' || method ==='enter') {
            shouldGenerateSuggestions = false
        }

        if (shouldGenerateSuggestions && inputValue.length >=3) {
            dispatch(selectDataSource(DATA_SOURCE_FOLKLEX))
            dispatch(lookupKey(DATA_SOURCE_FOLKLEX, REQUEST_COMPLETION_TYPE, inputValue))
            dispatch(fetchDetailsIfNeeded(DATA_SOURCE_FOLKLEX, REQUEST_COMPLETION_TYPE, inputValue))
        }

        this.setState(state =>
            ({
                ...state,
                wordLookup: inputValue,
                shouldGenerateSuggestions: shouldGenerateSuggestions
            }));
    }

    clickSpecialLetter = (letter) => {
        if (this.asInputRef) {
            this.asInputRef.focus()
        }

        const caretPostion = this.asInputRef.selectionEnd
        const { wordLookup } = this.state

        this.setState(state =>
            ({
                ...state,
                wordLookup: wordLookup.slice(0, caretPostion) + letter + wordLookup.slice(caretPostion)
            }));
    }

    // called when user selected a suggestion
    onSelectSuggestion = (event, { suggestion }) => {
        if (suggestion) {
            const selectedValue = suggestion.label
            this.setState(state =>
                ({
                    ...state,
                    wordLookup: selectedValue,
                    shouldGenerateSuggestions: false    // if a user selected a suggestion, it is unnecessary to show the suggestions
                }));

            this.dispatchFolketLookupAction(selectedValue)
        }
    }

    storeAutoSuggestInputRef = (asInputRef) => {
        this.asInputRef = asInputRef
    }

    handleClearClick = () => {
        this.setState(state =>
            ({
                ...state,
                wordLookup: ''
            }));
        saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_INPUT_DICTIONARY.description, '')

        if (this.asInputRef) {
            this.asInputRef.focus()
        }
    }

    closeFuzzyLookupDialog = (selectedValue) => {
        if ((typeof selectedValue === "string") && selectedValue !== '') {

            if (selectedValue !==  this.state.wordLookup) { // a suggestion was selected
                this.dispatchFolketLookupAction(selectedValue)

                this.setState(state =>
                    ({
                        ...state,
                        wordLookup: selectedValue
                    }));
            } else {    // choose to try native translation
                const {dispatch, textTranslateEngine} = this.props

                //dispatchQueryRequest(dispatch, textTranslateEngine, false, selectedValue, 'sv', this.props.nativeLanguage)
                dispatchQueryRequest(dispatch, textTranslateEngine, false, selectedValue, 'sv', 'en')

                //this.props.onOpenNativeTranslationDialog(selectedValue, selectedValue, 'sv')
                this.props.onOpenTryLuckDialog(selectedValue, 'sv')
            }
        }

        this.setState(state =>
            ({
                ...state,
                openFuzzyDialog: false
            }));
    }

    onKeyPress = (event) => {
        if (event.key === 'Enter' ) {
            this.lookup()
            this.setState(state =>
                ({
                    ...state,
                    shouldGenerateSuggestions: false
                }));
        }
    }

    // when the user clicks a dictionary entry, we use this method to get the entry text. For a verb as an example,, it should be the verb's infinit form
    setCurrentEntryText = (entryText, entryClass) => {
        this.setState(state =>
            ({
                ...state,
                currentEntryText: entryText,
                currentEntryClass: entryClass
            }));
    }

    openSaolInflectionDialog = () => {
        this.setState(state =>
            ({
                ...state,
                openSaolDialog: true
            }));
    }

    closeSaolInflectionDialog = () => {
        this.setState(state =>
            ({
                ...state,
                openSaolDialog: false
            }));
    }

    dispatchFolketLookupAction = (wordLookup) => {
        const {dispatch} = this.props
        dispatch(selectDataSource(DATA_SOURCE_FOLKLEX))

        dispatch(lookupKey(DATA_SOURCE_FOLKLEX, REQUEST_WORD_TYPE, wordLookup))
        dispatch(fetchDetailsIfNeeded(DATA_SOURCE_FOLKLEX, REQUEST_WORD_TYPE, wordLookup))

        if (wordLookup !== '')
            saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_LOOKUP.description, wordLookup)
    }

    onAutoSuggestInputBlur = () => {
        const { wordLookup } = this.state

        saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_INPUT_DICTIONARY.description, wordLookup)
    }

    componentDidMount() {
        const { keyWord } = this.props
        const { wordLookup } = this.state

        // should be the first load (no lookup so far)
        // then load the saved last lookup
        if (keyWord === '') {
            let lastLookup = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_LOOKUP.description)

            let wordLookupLoad = lastLookup === '' ? 'välkommen' : lastLookup
            this.setState(state => ({
                ...state,
                wordLookup: wordLookupLoad
            }))

            this.dispatchFolketLookupAction(wordLookupLoad)
        } else if (wordLookup === '') {// should be the case of page switching
            let lastInput = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_INPUT_DICTIONARY.description)

            this.setState(state => ({
                ...state,
                wordLookup: lastInput
            }))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { details, isFuzzyDialogShown, dispatch } = this.props
        if (!this.state.openFuzzyDialog && details instanceof Array && details.length > 0 && !isFuzzyDialogShown) {
            dispatch(showFuzzyDialog(DATA_SOURCE_FOLKLEX, REQUEST_WORD_TYPE, this.state.wordLookup))
            this.setState(state =>
                ({
                    ...state,
                    openFuzzyDialog: true
                }));
        }

        if (!this.props.apiIsFetching && !isObjectEmpty(this.props.apiDetails) && this.props.apiDetails['translations'].length === 0) {
            // no result from ms dictionary query, try google translate
            dispatchQueryRequest(dispatch, 'google', false, this.state.enText, this.state.srcLang, this.props.nativeLanguage)
        }
    }

    render() {
        const { suggestions, dictSuggestions, keyWord, details,
            i18nStrings, textTranslateEngine, onOpenSelectNativeDialog, onOpenNativeTranslationDialog } = this.props
        const { wordLookup, shouldGenerateSuggestions } = this.state

        return (
            <div>
                <div style={{marginTop: appbarHeigh + 10, flex: 1, flexDirection: 'column', alignItems: 'center'}}>
                    <SpecialLetterButtons onClickSpecialButton={this.clickSpecialLetter} considerScreenSize={true}/>
                    <div style={{display: 'flex', marginTop: 5, marginRight: 10, alignItems: 'center'}} >
                        <AutoSuggestInput
                            wordLookup={wordLookup}
                            handleLookupChange={this.handleLookupChange}
                            onInputBlur={this.onAutoSuggestInputBlur}
                            storeAutoSuggestInputRef={this.storeAutoSuggestInputRef}
                            lookupSuggestions={shouldGenerateSuggestions ? generateOverallSuggestions(suggestions, dictSuggestions) : []}
                            onSelectSuggestion={this.onSelectSuggestion}
                            onKeyPress={this.onKeyPress}
                            i18nStrings={i18nStrings['AutoSuggestInput']}
                        />
                        {
                            wordLookup.length > 0
                            && <IconButton onClick={this.handleClearClick} style={{padding: 2, marginRight: 10}}>
                                <Cancel />
                                </IconButton>
                        }
                        <Button style={{marginLeft: 5, textTransform: 'none'}} onClick={this.lookup} variant="contained" size="small" color="primary">
                            {i18nStrings[LOOKUP_BUTTON_TEXT_ID]}
                        </Button>
                    </div>
                    {
                        details
                        && details instanceof Document
                        && <DictionaryEntryList
                            details={details}
                            openNativeDialog={onOpenNativeTranslationDialog}
                            dispatch={this.props.dispatch}
                            nativeLanguage={this.props.nativeLanguage}
                            openAlertDialog={this.props.openAlertDialog}
                            openSaolInflectionDialog={this.openSaolInflectionDialog}
                            setCurrentEntryText={this.setCurrentEntryText}
                            i18nStrings={i18nStrings['DictionaryEntryList']}
                            onOpenSelectNativeDialog={onOpenSelectNativeDialog}
                            textTranslateEngine={textTranslateEngine}
                            isAuthenticated={this.props.isAuthenticated}
                        />
                    }
                    <div style={{height: 500}}/>
                </div>
                {
                    details instanceof Array
                    && details.length > 0
                    && <FuzzyLookupDialog
                        open={this.state.openFuzzyDialog}
                        keyWord={keyWord}
                        fuzzySuggestions={details}
                        onClose={this.closeFuzzyLookupDialog}
                        i18nStrings={i18nStrings['FuzzyLookupDialog']}
                    />
                }
                {
                    <SaolInflectionDialog
                        open={this.state.openSaolDialog}
                        keyWord={this.state.currentEntryText}
                        wordClass={this.state.currentEntryClass}
                        onClose={this.closeSaolInflectionDialog}
                        i18nStrings={i18nStrings['SaolInflectionDialog']}
                    />
                }
            </div>
        );
    }
}

// the autosuggest input need a list of suggestions. The list is composed of two lists:
// 1. lookup suggestions generated from lookup history
// 2. dictionary suggestions which was fetched from Folket with the current partial input
// NOTE: We need to remove the duplicated ones when combining the two lists
function generateOverallSuggestions(lookupSuggestions, dictSuggestions) {
    let overallSuggestions = lookupSuggestions.slice()
    dictSuggestions.forEach(dictSuggestion => {
        if (lookupSuggestions.findIndex(lookupSuggestion => lookupSuggestion.label === dictSuggestion.label) === -1) {
            overallSuggestions.push(dictSuggestion)
        }
    })

    return overallSuggestions
}

const mapStateToProps = state => {
    const { nativeLanguage, dataBySource, textTranslateEngine, dataSource } = state

    let isFetching, keyWord, isFuzzyDialogShown, details
    let presentIndex, suggestions
    let dictPresentIndex, dictSuggestions, isFetchingDictSuggestions

    if (dataBySource['folkLexikon'] && dataBySource['folkLexikon']['lookupword']) {
        isFetching = dataBySource['folkLexikon']['lookupword'].isFetching
        presentIndex = dataBySource['folkLexikon']['lookupword'].presentIndex
        keyWord = dataBySource['folkLexikon']['lookupword'].history[presentIndex].keyWord
        if (isFetching) {
            details = {}
            suggestions = []
        } else {
            details = dataBySource['folkLexikon']['lookupword'].history[presentIndex].details
            suggestions = dataBySource['folkLexikon']['lookupword'].history
                .filter(hisNode => hisNode.details instanceof Document) // only pick up valid input
                .map(hisNode => ({label: hisNode.keyWord}))

            if (details instanceof Array && details.length > 0) {
                isFuzzyDialogShown = dataBySource['folkLexikon']['lookupword'].history[presentIndex].isShown
            }
        }
    } else {
        isFetching = false
        keyWord = ''
        details = {}
        presentIndex = -1
        suggestions = []
        isFuzzyDialogShown = false
    }

    if (dataBySource['folkLexikon'] && dataBySource['folkLexikon']['generatecompletion']) {
        isFetchingDictSuggestions = dataBySource['folkLexikon']['generatecompletion'].isFetching
        dictPresentIndex = dataBySource['folkLexikon']['generatecompletion'].presentIndex
        if (!isFetchingDictSuggestions) {
            const suggestionData = dataBySource['folkLexikon']['generatecompletion'].history[dictPresentIndex].details
            dictSuggestions = (suggestionData && !isObjectEmpty(suggestionData) && !suggestionData.hasOwnProperty('errorMsg')) ? suggestionData.map(suggestNode => ({label: suggestNode})) : []
        } else {
            dictSuggestions = []
        }
    } else {
        isFetchingDictSuggestions = false
        dictSuggestions = []
    }

    let isAuthenticated = !!state.authStatus.uid

    return {
        nativeLanguage,
        textTranslateEngine,
        isFetching,
        keyWord,
        details,
        isFuzzyDialogShown,
        suggestions,
        isFetchingDictSuggestions,
        dictSuggestions,
        dataSource,
        isAuthenticated
    }
}

export default connect(mapStateToProps)(DictionaryPage)