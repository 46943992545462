/**
 * this list is for the sub entries of a word. It is embeded in the word entry.
 **/
import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {ExpandMore, ExpandLess } from '@material-ui/icons'
import {BookOpenOutline, Checkbook, VolumeHigh, Translate, DotsHorizontalCircleOutline } from "mdi-material-ui";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {polishText, isStringText, saveStringToLocalStorage, LOCAL_STORAGE_KEY_NAME} from '../../common/util'
import {
    DATA_SOURCE_SAOL,
    DATA_SOURCE_MSAPI,
    fetchDetailsIfNeeded,
    lookupKey,
    MSAPI_REQUEST_DICT_TYPE,
    MSAPI_REQUEST_TRANS_TYPE,
    GAPI_REQUEST_TRANS_TYPE,
    SAOL_REQUEST_INFLECTION_TYPE,
    selectDataSource, setNativeLanguage, cleanHistoryData,
    dispatchQueryRequest
} from "../../actions/actions"

const urlSoundfilePrefix = 'http://lexin.nada.kth.se/sound/'

const EN_DEFINITION_TEXT_ID = 'enDefinitionText'
const SV_DEFINITION_TEXT_ID = 'svDefinitionText'
const SYNONYM_TEXT_ID = 'synonymText'
const INFLECTIONS_TEXT_ID = 'inflectionsText'
const PRONUNCIATION_TEXT_ID = 'pronunciationText'
const EXPLANATION_TEXT_ID = 'explanationText'
const EXAMPLES_TEXT_ID = 'examplesText'
const IDIOMS_TEXT_ID = 'idiomsText'
const COMPOUNDS_TEXT_ID = 'compoundsText'


const styles = theme => ({
    embedded: {
        paddingLeft: theme.spacing(2),
    },
    subentry: {
        paddingLeft: theme.spacing(4),
    }
});

function getSoundFileUrl(soundData) {
    if (soundData['soundFile']) {
        let fileName = soundData['soundFile'].replace('swf', 'mp3')
        fileName = fileName.replace(/å/g, '0345')
        fileName = fileName.replace(/ä/g, '0344')
        fileName = fileName.replace(/ö/g, '0366')
        return urlSoundfilePrefix + fileName
    }

    return ''
}

class EmbeddedList extends React.Component{
    static propTypes = {
        classes: PropTypes.object.isRequired,
        headString: PropTypes.string.isRequired,    // the string shown in the parent item (normally it is the Swedish entry word/text
        entryKey: PropTypes.string.isRequired,      // the name of the entry
        entryLang: PropTypes.string.isRequired,     // en or sv?
        entryText: PropTypes.string.isRequired,     // the dictionary entry text. For a verb as an example,, it should be the verb's infinit form
        entryClass: PropTypes.string.isRequired,    // verb, noun, adj., ...
        //entryDetails: PropTypes.object,             // the data for the entry which will be used to populate the list
        openNativeDialog: PropTypes.func.isRequired,
        expandable: PropTypes.bool.isRequired,      // if this list is expandable. For example, examples entry
        dispatch: PropTypes.func.isRequired,
        nativeLanguage: PropTypes.string.isRequired,
        openAlertDialog: PropTypes.func,
        openSaolInflectionDialog: PropTypes.func,
        setCurrentEntryText: PropTypes.func,
        i18nStrings: PropTypes.object.isRequired,
        onOpenSelectNativeDialog: PropTypes.func.isRequired,
        textTranslateEngine: PropTypes.string.isRequired
    }

    constructor (props) {
        super(props)
        this.state = {
            open: false,        // if expandable==true, we use open to mark if the sub items are shown
        }

        this.handleClick = this.handleClick.bind(this)
        this.getNativeFromTranslation = this.getNativeFromTranslation.bind(this)
    }

    getItemStringFromKey = (i18nStrings, entrykey) => {
        switch (entrykey) {
            case 'translation':
                return (this.props.entryLang === 'sv' ? i18nStrings[EN_DEFINITION_TEXT_ID] : i18nStrings[SV_DEFINITION_TEXT_ID]) + this.props.entryDetails
            case 'synonym':
                return i18nStrings[SYNONYM_TEXT_ID] + this.props.entryDetails
            case 'definition':
                return i18nStrings[SV_DEFINITION_TEXT_ID] + this.props.entryDetails
            case 'paradigm':
                return i18nStrings[INFLECTIONS_TEXT_ID] + this.props.entryDetails
            case 'phonetic':
                return i18nStrings[PRONUNCIATION_TEXT_ID] + '[' + this.props.entryDetails['value'] + ']'
            case 'explanation':
                return i18nStrings[EXPLANATION_TEXT_ID] + this.props.entryDetails
            case 'examples': // here we combine all example nodes to a new "examples"/"idioms" field
                return i18nStrings[EXAMPLES_TEXT_ID]
            case 'idioms':
                return i18nStrings[IDIOMS_TEXT_ID]
            case 'compounds':
                return i18nStrings[COMPOUNDS_TEXT_ID]
            default:
                return ''
        }
    }

    setNoNeedForNativeTranslate = () => {
        const { dispatch } = this.props
        dispatch(setNativeLanguage('noNeed'))
        dispatch(cleanHistoryData(DATA_SOURCE_MSAPI))

        saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.NATIVE_LANGUAGE.description, 'noNeed')
    }

    handleClick() {
        const { dispatch, entryText, entryKey, nativeLanguage, onOpenSelectNativeDialog,
            openSaolInflectionDialog, setCurrentEntryText } = this.props
        switch (entryKey) {
            case 'translation':
                if (nativeLanguage === '') {// detected browser language is not in the language list
                    onOpenSelectNativeDialog(this.getNativeFromTranslation)
                } else if (nativeLanguage !== 'noNeed') {
                    this.getNativeFromTranslation()
                }
                break
            case 'phonetic':
                this.playPronunciation()
                break
            case 'explanation':
            case 'definition':
                if (nativeLanguage === '') {// detected browser language is not in the language list
                    onOpenSelectNativeDialog(this.getNativeFromDefinition)
                } else if (nativeLanguage !== 'noNeed') {
                    this.getNativeFromDefinition()
                }
                break
            case 'paradigm':
                setCurrentEntryText(entryText, this.props.entryClass)
                dispatch(selectDataSource(DATA_SOURCE_SAOL))

                dispatch(lookupKey(DATA_SOURCE_SAOL, SAOL_REQUEST_INFLECTION_TYPE, this.props.entryText, '', '', this.props.entryClass))
                dispatch(fetchDetailsIfNeeded(DATA_SOURCE_SAOL, SAOL_REQUEST_INFLECTION_TYPE, this.props.entryText, '', '', this.props.entryClass))

                openSaolInflectionDialog()

                break
            default:
                break
        }

        if (this.props.expandable) {
            this.setState(state =>
                ({
                    ...state,
                    open: !state.open
                }));
        }
    }

    getNativeFromDefinition = () => {
        const {dispatch, entryDetails, nativeLanguage, textTranslateEngine, openNativeDialog} = this.props
        const originalText = polishText('sv', entryDetails)
        dispatchQueryRequest(dispatch, textTranslateEngine, false, originalText, 'sv', nativeLanguage)

        openNativeDialog(entryDetails, entryDetails, 'sv')
    }

    getNativeFromTranslation() {
        const {dispatch, headString, entryDetails, nativeLanguage, textTranslateEngine} = this.props

        let isText = isStringText(entryDetails)

        let transEngine = textTranslateEngine
        if (!isText) {  // use ms dictionary query for sigle word
            transEngine = 'ms'
        }

        let apiDataSource = transEngine + 'Api'
        dispatch(selectDataSource(apiDataSource))

        let originalText = entryDetails

        let textTransType = transEngine === 'ms' ? MSAPI_REQUEST_TRANS_TYPE : GAPI_REQUEST_TRANS_TYPE
        let apiRequestType

        if (this.props.entryLang === 'sv') { // for Swedish entry, the translation is in English. For English -> Chinese, we need to check if it is text or word
            apiRequestType = ( isText? textTransType : MSAPI_REQUEST_DICT_TYPE)
        } else {    // since there is no Swedish -> Chinese dictionary lookup in MS Api, here we just use translate option
            apiRequestType = textTransType
            originalText = polishText('sv', entryDetails)
        }

        const srcLang = this.props.entryLang === 'sv' ? 'en' : 'sv'
        dispatch(lookupKey(apiDataSource, apiRequestType, originalText, srcLang))
        dispatch(fetchDetailsIfNeeded(apiDataSource, apiRequestType, originalText, srcLang, nativeLanguage))

        let {openNativeDialog} = this.props
        this.props.entryLang === 'sv' ? openNativeDialog(headString, entryDetails, srcLang) : openNativeDialog(entryDetails, headString, srcLang)
    }

    playPronunciation() {
        this.pronunciation.load()
        this.pronunciation.play();
    }

    render() {
        const { classes, entryKey, entryDetails, i18nStrings, nativeLanguage, textTranslateEngine,
            openNativeDialog, entryLang, dispatch, onOpenSelectNativeDialog } = this.props

        return (
            <List key={entryKey} component="div" disablePadding>
                <ListItem button className={classes.embedded} onClick={this.handleClick}>
                    <ListItemIcon>
                        <BookOpenOutline />
                    </ListItemIcon>
                    <ListItemText primary={this.getItemStringFromKey(i18nStrings, entryKey)}/>
                    {this.props.expandable && (this.state.open ? <ExpandLess /> : <ExpandMore />)}
                    {
                        entryKey === 'phonetic'
                        && (<div>
                            <VolumeHigh />
                            <audio ref={(pronunciation) => { this.pronunciation = pronunciation }}>
                                <source src={getSoundFileUrl(entryDetails)} type="audio/mpeg" >
                                </source>
                            </audio>
                            </div>
                            )
                    }
                    {
                        ( entryKey === 'translation' || entryKey === 'explanation' || entryKey === 'definition' )
                        && (
                                <Translate />

                        )
                    }
                    {
                        ( entryKey === 'paradigm' )
                        && (
                                <DotsHorizontalCircleOutline />

                        )
                    }
                </ListItem>
                {this.props.expandable && (
                    <Collapse in={this.state.open} timeout="auto" unmountOnExit >
                        <List  component="div" disablePadding>
                        {entryDetails.map((subEntry, i) =>
                                <DictSubEntry key={i}
                                              classes={classes}
                                              nativeLanguage={nativeLanguage}
                                              textTranslateEngine={textTranslateEngine}
                                              openNativeDialog={openNativeDialog}
                                              entryLang={entryLang}
                                              dispatch={dispatch}
                                              subEntryText={subEntry['value']}
                                              subEntryTranslation={subEntry['translation']}
                                              onOpenSelectNativeDialog={onOpenSelectNativeDialog}
                                />
                        )}
                        </List>
                    </Collapse>
                )}
            </List>
        );
    }
}

// for the subentries of 'examples' and 'idioms', user can click them to translate their English translation
//  here we subclassify the subentry so that it is easy to handle the click
class DictSubEntry extends React.Component {
    handleClick = () => {
        const { nativeLanguage, onOpenSelectNativeDialog } = this.props
        if (nativeLanguage === '') {// detected browser language is not in the language list
            onOpenSelectNativeDialog(this.getNativeFromSubEntry)
        } else if (nativeLanguage !== 'noNeed') {
            this.getNativeFromSubEntry()
        }
    }

    getNativeFromSubEntry = () => {
        const { nativeLanguage, textTranslateEngine, openNativeDialog, entryLang, subEntryText, subEntryTranslation, dispatch } = this.props
        // normally English -> Chinese translation is more accurate than Swedish -> Chinese with translate API
        // so here we will use English text
        let textForApi
        let srcLang = 'en'
        if (subEntryTranslation === '') {
            textForApi = subEntryText
            srcLang = 'sv'
        } else {
            textForApi = entryLang === 'sv' ? subEntryTranslation : subEntryText
        }

        dispatchQueryRequest(dispatch, textTranslateEngine, false, textForApi, srcLang, nativeLanguage)

        entryLang === 'sv' ? openNativeDialog(subEntryText, subEntryTranslation, 'en') : openNativeDialog(subEntryTranslation, subEntryText, 'en')
    }

    render() {
        return (
            <ListItem button className={this.props.classes.subentry} onClick={() => this.handleClick()}>
                <ListItemIcon>
                    <Checkbook />
                </ListItemIcon>
                <ListItemText
                    primary={this.props.subEntryText}
                    secondary={this.props.subEntryTranslation}
                />
                <div>
                    <Translate />
                </div>
            </ListItem>
        )
    }
}

export default withStyles(styles)(EmbeddedList)