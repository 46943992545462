import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {DiceMultiple}  from "mdi-material-ui";
import Typography from '@material-ui/core/Typography'

const NO_ENTRY_IN_DICT_TEXT_ID = 'noEntryInDictText'
const LIST_SUGGESTIONS_TEXT_ID = 'listSuggestionsText'
const TRY_NATIVE_TRANS_TEXT_ID = 'tryNativeTranslationText'
const NOT_IN_SUGGESTION_LIST_TEXT_ID = 'notInSuggestionListText'
const CLOSE_BUTTON_TEXT_ID = 'closeButtonText'

class FuzzyLookupDialog extends React.Component {
    static propTypes = {
        keyWord: PropTypes.string.isRequired,
        fuzzySuggestions:PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
        i18nStrings: PropTypes.object.isRequired
    }

    handleClose = () => {
        this.props.onClose('')
    }

    handleListItemClick = value => {
        this.props.onClose(value);
    }

    render() {
        const { keyWord, fuzzySuggestions, i18nStrings, ...other } = this.props
        let tryNativeTranslateText =  i18nStrings[TRY_NATIVE_TRANS_TEXT_ID]
        if (fuzzySuggestions.length > 1)
            tryNativeTranslateText = i18nStrings[NOT_IN_SUGGESTION_LIST_TEXT_ID] + tryNativeTranslateText

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">{i18nStrings[NO_ENTRY_IN_DICT_TEXT_ID]}'{keyWord}'{fuzzySuggestions.length > 1 ? i18nStrings[LIST_SUGGESTIONS_TEXT_ID] : ''}</DialogTitle>
                <div>
                    <List>
                        {fuzzySuggestions.map((suggestion, i) => (
                            <ListItem button onClick={() => this.handleListItemClick(suggestion)} key={i}>
                                {
                                    suggestion === keyWord
                                    && <ListItemIcon>
                                            <DiceMultiple />
                                       </ListItemIcon>
                                }
                                <ListItemText
                                    disableTypography={suggestion === keyWord}
                                    primary={suggestion !== keyWord ? suggestion : (<Typography type="body2" style={{ color: '#4054b0' }}>{tryNativeTranslateText}</Typography>)}

                                />
                            </ListItem>
                        ))}
                    </List>

                </div>
                <DialogActions>
                    <Button onClick={this.handleClose} size="small" color="primary" style={{textTransform: 'none'}}>
                        {i18nStrings[CLOSE_BUTTON_TEXT_ID]}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FuzzyLookupDialog