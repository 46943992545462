export default {
    FirebaseErrors: {
        'auth/email-already-in-use': 'There already exists an account with the given email address.',
        'auth/invalid-email': 'The email address is not valid.',
        'auth/operation-not-allowed': 'This account type is not enabled. Please contact the developer of the web site.',
        'auth/weak-password': 'The password is not strong enough.',

        'auth/account-exists-with-different-credential': 'There already exists an account with the email address asserted by the credential.',
        'auth/auth-domain-config-required': 'Domain configuration error. Please contact the developer of the web site.',
        'auth/cancelled-popup-request': 'Successive popup operations are triggered. Please contact the developer of the web site.',
        'auth/operation-not-supported-in-this-environment': 'This operation is not supported. Please contact the developer of the web site.',
        'auth/popup-blocked': 'The sign in popup was blocked by the browser. Please check your browser configuration.',
        'auth/popup-closed-by-user': 'The sign in popup is closed by the user without completing the sign in. Please try it again.',
        'auth/unauthorized-domain': 'The app domain is not authorized. Please contact the developer of the web site.',

        'auth/user-disabled': 'The user corresponding to the given email has been disabled. Please contact the developer of the web site.',
        'auth/user-not-found': 'There is no user corresponding to the given email. Please sign up first.',
        'auth/wrong-password': 'The password is invalid for the given email. Please try it again.',

        newWordExists: 'The word is already in your new words. Please click the sync button in top app bar to load the latest new words from the cloud.',
        newWordNotExists: 'The word is not in your new words. Please click the sync button in top app bar to load the latest new words from the cloud.',
    },
    App: {
        loadingLookupText: 'Looking up...',
        loadingSuggestionText: 'Fetching suggestions...',
        loadingTransPageText: 'Translating...',
        alertNetworkIssueTitle: 'Network Issue?',
        alertNetworkTimeoutText: 'Network connection time out. Please try it again later.',
        alertNetworkUnavailableText: 'Network service is unavailable. Please try it again later or contact web site admin.',
        alertNetworkIssueText: 'Network service cannot be connected. Please check your network configuration.',
        closeButtonText: 'Close',
    },
    SelectLanguageDialog: {
        dialogTitle: 'Select Native Language',
        dialogText: 'Please tell Plugghäst your native language. Then when you click Translation, Definition, Explanation, Examples, Idioms and Compounds in dictionary entries, Plugghäst will help translate them to your native language.',
        noNeedToSetText: 'No need',
        notSelectedText: 'Not selected',
        closeButtonText: 'Close',
        okButtonText: 'OK',
        okWithNotSelected: 'You have not selected native language. ',
        okWithSelected: 'You select "%s" as your native language',
        selectNoNeed: 'You selected "No need". You can specify native language later in "App settings" page.'
    },
    ResponsiveDrawer: {
        dictItemText: 'Dictionary',
        transItemText: 'Translate',
        newWordItemText: 'New words',
        settingsItemText: 'App settings',

        newsItemText: 'News in Sv',
        specialtySwedish: 'Professional Sv',
        gameAndTestItemText: 'Quiz and game',
        tipOfTheDayItemText: 'Tip of the day',
        dailyTasksItemText: 'Daily review task',
        loginItemText: 'Sign In',
        logoutItemText: 'Sign Out',

        sortByDateItemText: 'Sort by date',
        sortByAlphaItemText: 'Sort by alpha',
        sortByClassItemText: 'Sort by class',
        sortByReviewStatusItemText: 'Sort by review status',
        hideRemembersItemText: 'Hide remembers',
        showRemembersItemText: 'Show remembers',
        syncCloudItemText: 'Sync with cloud'
    },
    PageContainer: {
        dictionaryTitle: 'Dictionary',
        translateTitle: 'Translate',
        settingsTitle: 'App Settings',
        new_wordsTitle: 'New Words',
        daily_taskTitle: 'Daily Review Task',
        daily_taskDesc: 'Based on Ebbinghaus forgetting curve, Plugghäst will prepare daily tasks for you to review your new words. \n The page is coming soon!',
        newsTitle: 'News in Swedish',
        newsDesc: 'Improve your Swedish by reading news in Swedish. ',
        specialtyTitle: 'Professional Swedish',
        specialtyDesc: 'Here you will learn how to use Swedish more effectively at work. ',
        quiz_gameTitle: 'Quiz & Game',
        quiz_gameDesc: 'Learn while having fun. Innovative, games hands on learning. ',
        tipsTitle: 'Tip of the Day',
        tipsDesc: 'Constant accumulation of knowledge is the driver behind your Swedish development. \n The page is under construction.',
        signinTitle: 'Sign In',
        signupTitle: 'Sign Up',
        reset_passwordTitle: 'Reset password',

        NativeTranslateDialog: {
            svLabelText: 'sv: ',
            enLabelText: ' en: ',
            nativeTranslationTitle: 'Native Translation',
            confidenceLabelText: '. confidence: ',
            noResultsText: 'No query results',
            copyToClipboardText: 'Copy text to clipboard',
            detectBrowserLanguageDesc: 'Plugghäst can help translate the information in dictionary entries to your native language. Plugghäst guesses your native language is "%s" according to your system settings. If so, please click "OK". If "%s" is not your native language, you can click "Set language". If you need not native translation, please click "No need".',
            setNativeLangButtonText: 'Set language',
            noNeedToSetText: 'No need',
            closeButtonText: 'Close',
            okButtonText: 'OK',
            ms: 'Microsoft Service',
            google: 'Google Service',
            usingEngine: ' using '
        },

        TryLuckResultDialog: {
            svLabelText: 'sv: ',
            title: 'Try Your Luck',
            noResultsText: 'No query results',
            copyToClipboardText: 'Copy text to clipboard',
            okButtonText: 'OK',
            translationLabel: 'Translation: '
        },

        DictionaryPage: {
            pageTile: 'Dictionary',
            lookupButtonText: 'Look up',
            AutoSuggestInput: {
                inputPlaceholderText: 'input Swedish or English word/phrase...'
            },
            DictionaryEntryList: {
                wordClassNoun: 'n.',
                wordClassVerb: 'v.',
                wordClassAdj: 'adj.',
                wordClassInterj: 'interj.',
                wordClassPron: 'pron.',
                wordClassAdv: 'adv.',
                wordClassConj: 'conj.',
                wordClassPrep: 'prep.',
                wordClassNum: 'num.',
                wordClassAbbrev: 'abbr.',
                NestedList: {
                    addNewWord: ' is added to your New Words',
                    removeNewWord: ' is removed from your New Words',
                    EmbeddedList: {
                        enDefinitionText: 'Translation: ',
                        svDefinitionText: 'Definition: ',
                        synonymText: 'Synonyms: ',
                        inflectionsText: 'Inflections: ',
                        pronunciationText: 'Pronunciation: ',
                        explanationText: 'Explanation: ',
                        examplesText: 'Examples',
                        idiomsText: 'Idioms',
                        compoundsText: 'Compounds',
                        noNeedToSetText: 'No need',
                        setNativeLangButtonText: 'Set language',
                    }
                }
            },
            NativeTranslateDialog: {
                svLabelText: 'sv: ',
                enLabelText: ' en: ',
                nativeTranslationTitle: 'Native Translation',
                confidenceLabelText: '. confidence: ',
                noResultsText: 'No query results',
                copyToClipboardText: 'Copy text to clipboard',
                detectBrowserLanguageDesc: 'Plugghäst can help translate the information in dictionary entries to your native language. Plugghäst guesses your native language is "%s" according to your system settings. If "%s" is not your native language, you can click "Set language" below. If you do not need it, please click "No need".',
                setNativeLangButtonText: 'Set language',
                noNeedToSetText: 'No need',
                closeButtonText: 'Close',
                okButtonText: 'OK',
                ms: 'Microsoft Service',
                google: 'Google Service',
                usingEngine: ' using '
            },
            FuzzyLookupDialog: {
                noEntryInDictText: 'No such entry in the dictionary: ',
                listSuggestionsText: '. Do you want to search one of following?',
                notInSuggestionListText: 'If none of the above is what you want, ',
                tryNativeTranslationText: 'click me to try your luck? :)',
                closeButtonText: 'Close'
            },
            SaolInflectionDialog: {
                saolDialogTitle: ' Different Forms',
                noResultsText: 'No query results',
                closeButtonText: 'Close',
                wordClassNoun: 'n.',
                wordClassVerb: 'v.',
                wordClassAdj: 'adj.',
                wordClassInterj: 'interj.',
                wordClassPron: 'pron.',
                wordClassAdv: 'adv.',
                wordClassConj: 'conj.',
                wordClassPrep: 'prep.',
                wordClassNum: 'num.',
                wordClassAbbrev: 'abbr.',
            }
        },
        TranslatePage: {
            pageTile: 'Translate',
            pageDescription: 'Here you can translate',
            transButtonText: 'Translate',
            sameLanguagesAlert: 'The From Language and To Language must be different',
            emptyInputAlert: 'Please input the text to be translated',
            labelFrom: 'From',
            labelTo: 'To',
            placeholderFrom: 'input the text you want to translate',
            TranslateLanguageSelect: {
                englishName: 'English',
                swedishName: 'Swedish',
            },
            jump2DictButtonText: 'Look up',
            dictItemText: 'Dictionary',
        },
        NewWordsPage: {
            pageTile: 'New words',
            enDefinitionText: 'Translation: ',
            svDefinitionText: 'Definition: ',
            synonymText: 'Synonyms: ',
            inflectionsText: 'Inflections: ',
            pronunciationText: 'Pronunciation: ',
            explanationText: 'Explanation: ',
            examplesText: 'Examples: ',
            idiomsText: 'Idioms: ',
            compoundsText: 'Compounds: ',
            forgetStatus: 'Forget',
            knowStatus: 'Know',
            rememberStatus: 'Remember',
            notReviewedStatus: 'Not-review',
            signinText: 'Sign In',
            askSinginText: 'You can add or review new words after ',
            landingText1: 'You can add new words by clicking ',
            landingText2: ' button of an entry in the dictionary, then you can review your new words here; If you want to delete an existing new word, in the new word card or the dictionary entry you can click the button ',
            headText: 'You have %s new words:',
            secondaryText: 'Remember %r, Know %k, Forget %f, Not-review %n',
            wordClassNounFull: 'Noun',
            wordClassVerbFull: 'Verb',
            wordClassAdjFull: 'Adjective',
            wordClassInterjFull: 'Interjection',
            wordClassPronFull: 'Pronoun',
            wordClassAdvFull: 'Adverb',
            wordClassConjFull: 'Conjunction',
            wordClassPrepFull: 'Preposition',
            wordClassNumFull: 'Numeral',
            wordClassAbbrevFull: 'abbreviation.',
            wordClassNone: 'N/A',
            wordClassNoun: 'n.',
            wordClassVerb: 'v.',
            wordClassAdj: 'adj.',
            wordClassInterj: 'interj.',
            wordClassPron: 'pron.',
            wordClassAdv: 'adv.',
            wordClassConj: 'conj.',
            wordClassPrep: 'prep.',
            wordClassNum: 'num.',
            wordClassAbbrev: 'abbr.',
            reviewStatusUpdating: 'The review status of "%s" is changed to ',
            removeNewWord: ' is removed from your New Words',
        },
        SettingsPage: {
            pageTile: 'Settings',
            pageDescription: 'App settings',
            setNativeLangHead: 'Native language',
            setNativeLangDesc: 'After setting your native language, if you click Translation, Definition, Explanation, Examples, Idioms and Compounds in dictionary entries, Plugghäst will help translate them to your native language.',
            setUILanguageHead: 'UI language',
            setUILanguageDesc: 'The language Plugghäst is using in UI elements, such as dialogs or buttons. Because of our limited language level, in this version we provide only two UI lanugage options : English and Chinese。If you are interested，welcome to join us to make Plugghäst be easy to use for more people:)',
            setTextTranslateEngineHead: 'Text translate engine',
            setTextTranslateEngineDesc: 'By default Plugghäst uses Google Translate API to translate text. You can also select Microsoft Translator API.',
            ms: 'Microsoft',
            google: 'Google',
            aboutHead: 'About Plugghäst',
            aboutDesc: `	Plugghäst was launched on Google Play in May 2014. One and half year later, Plugghäst was launched on App Store as well. On the both two platforms, Plugghäst had been downloaded for more than 20,000 times with an average user-rating at around 4.6 stars. After that we had received feedbacks and suggestions from many enthusiastic users. It had been a pity that we had not had time to improve Plugghäst due to various reasons. In early 2019, we finally decided to rewrite Plugghäst thoroughly in order to give our users better experiences in learning and practicing Swedish. 
     This time, version 1.0 of the new Plugghäst was released and now "New words" page is available!!! You do not have to install the new version of Plugghäst. In your device (Android，iOS or your desktop computer), you only have to put in the link (www.plugghast.nu) in the browser (it is recommended to use the latest Chrome. Other browsers should also work well) to load the app. If you have opened Plugghäst in your browser, you only need to refresh the browser to load the latest version.
     “Dictionary” page in the new Plugghäst has greater functions than before. The page provides detailed derivation forms regarding verbs, nouns and adjectives for your reference during your studies. The new Plugghäst further enhances its translation functions based on your native language. For the English translation of Swedish entries, if a translation is just a single English word, Plugghäst will provide comprehensive explanations in your native language, so that you can make choices and match your searches more easily. For the translations in English in general, the quality of translation has been obviously enhanced. Moreover, exemplary sentences, idioms, compounds and definition in Swedish in a dictionary entry can all be translated to your native language – which makes it easier for you to understand the entry. The newest version of Plugghäst supports translations to more than 40 languages, when it comes to translation based on the user’s native language. Please refer to the setting of "native language" belonging to the page of “Settings”, if you would like to check which languages are included in the service. 
     “Translate” page in the newest version provides translation between the user’s native language, Swedish and English. It is recommended to use the page when you want to translate sentences.
     “New words” page is now available! The page is built up based on cloud. So your own new words will be synchronised on all of your devices.  
     Adopting scientific and effective methods can improve the efficiency of remembering new words greatly. So Plugghäst will soon introduce daily tasks of reviewing your new words. The tasks will be based on Ebbinghaus forgetting curv and help you improve your vocabulary. We also have listed several channels that can be developed in the future – “News in Swedish”, “Professional  Swedish” and “Quiz and game”. If you have any new ideas or suggestions, please feel free to contact us.
     If you have discovered any performance-related problems（for instance, how fast the app responds to your queries）when using new Plugghäst, please feel free to inform us. The back-end server for the current version of Plugghäst is deployed on Microsoft Azure. In order to save costs, we use the basic service program provided by Azure. If most of our users give feedbacks about problems in terms of performance, we will consider an upgrade of the existing program. 
     Lastly, we would like to once again express our heartfelt thanks to your support for Plugghäst. If our work could be of help to your studies in the Swedish language, it would be very heartening for us. We look sincerely forward to your feedbacks and suggestions to the current version. Hereby, we would wish you all the best with your studies！`,
            userManualHead: 'How to use',
            userManualDesc: `Overview
Version: 1.0.2

New features
Version 1.0 (2021.10) releases "New words" page! With the page, your own new words will be synchronised on all of your devices. NOTE: You need to sign in first to enable the page; after signing in, you could find "A+" button in each Swedish entry in Dictionary page. You can add a dictionary entry to your new words by tapping the button.
Version 0.2 (2019.12) provides you a better user experience as native app. On iOS, open www.plugghast.nu in Safari, then click the share button in the broswer and select the option 'Add to Home Screen'. After that, you will see an icon of Plugghäst on your phone's home screen. Then you can use the new Plugghäst as a native app! The setup is similar on Android. Just use Chrome to open www.plugghast.nu, tap the menu button (3 vertical dots) and then tap "Add to homescreen" (sometimes the menu item is shown as "Install app"). Chrome will then add Plugghäst to your home screen.
Applicable devices: Android or iOS based phone or tablet, and PC (Windows/Linux/Mac)
Recommended browser: Chrome（It is advisable to upgrade it to the newest version）
The new Plugghäst is a web-based app and you do not have to run any installations. You only need to put in the link www.plugghast.nu on your web-browser (Chrome is recommended) in your device, in order to load Plugghäst.
The interface for the new Plugghäst includes the navigation bar and the main view. When you use the app in your PC and tablet, the navigation bar will always be on the left-hand side of the screen. When you click on a certain item in the navigation bar, the main view will indicate the corresponding function page. When you use the app on your mobile phone, the navigation bar is hidden by default. Please click on the menu button on the top of the left (shown in three white lines), so that the navigation bar will appear. Afterwards, you can click on the corresponding item and then be directed to the function page. 


Function Pages
•	User Sign Up and Sign In: You as a user now can sign up/in in Plugghäst v1.0. It's worth noting that you can use New words page only after signing in. 
	o	After loading Plugghäst, you can sign in by clicking the "SIGN IN" button at the top right of the UI. 
	o	To sign up, you can first go to Sign Up page and register your account by inputting your email and setting your password (at least 6 letters or numbers). We recommend to use your frequently used email address so that you could reset your password via email when you forget it.
	
•	Dictionary：This page provides Swedish-English dictionaries (in both directions) and translational functions targeting entries in the dictionaries based on the user’s native language. On this page, you can look up words or phrases in Swedish or English. After putting in the phrase or word, you may click on "Look up" button to start the search. The entries marked with a Swedish national flag in the result are Swedish ones, while those marked with a British flag are English ones. In the default mode, the entries remain folded. Information in the folded entries include part of speech and compact explanations. For instance, you may click on an entry in Swedish so that it can unfold. Generally, you will see the following subordinate sections:
	o	Explanations in English (Translation): Explanations of entries provided in English. When you click on an entry, Plugghäst will translate the explanation (provided in English) to your native language (you will have to set up your native language on the page of ”Settings”)
	o	Pronunciation: This section indicates pronunciation note for the entries. When you click on a certain entry, you will hear standard pronunciation Swedish for the entry
	o	Inflections: This section indicates inflections for the entries (mainly for nouns, verbs and adjectives). When you click on the entry, Plugghäst will indicate very detailed inflections for your reference
	o	Synonyms: This section indicates synonym(s) to a certain entry
	o	Examples: This section is folded by default. When clicking on this section, you will see exemplary sentences related to the entry. When you click on a certain exemplary sentence, Plugghäst will translate it to your native language.
	o	Compounds: This section indicates the composite words related to the entry. Please be aware of that the symbol for word-divider (”|”) may appear in some of the composite words. When you click on a certain composite word, Plugghäst will translate this composite word to your native language.
	o	Idioms: This section is folded by default. When you click on this section, you can see idiom(s) or their usage in relation to the entry. When you click on a certain idiom, Plugghäst will translate it to your native language.
	o	Explanations in Swedish (Definition): Some of the entries in Swedish even have explanations in Swedish. When you click on the entry, Plugghäst will translate the entry’s Swedish explanation to your native language.

    Tips:
	o	After signing in, you will see "A+" button in each Swedish entry. By tapping the button of an entry, the word of the entry will be added to your new words. Then the button will be shown as "A-". You can remove the word from your new words by clicking "A-".
	o	If you see an icon on the right-hand side of the subordinate section, it implies that you can click on it. Please click on it and see what happens.
	o	If your search for a certain phrase or word does not get any relevant results in the dictionary, Plugghäst will provide a list of fuzzy results based on your input. If none of the results matches your request, you can also try the button “try your luck”.

•	Translate: This page provides translations between Swedish, English and your native language. We recommend that you use this function when you look up sentences.

•	New words: In this page, your own new words will be synchronized on cloud – which will facilitate your viewing and reviewing vocabulary on all of your devices. 
    o	There are three review statuses: Forget, Know and Remember. "Remember" can be defined as: when you see the word, its precise meaning will be shown immediately in your mind without any delay. "Know" means you have only a vague impression of the word.
    o	By default, the new words will be sorted into the groups by the date when they were added. You can sort the new words by alphabet, word class or review status. You can also choose to show/hide the words with the status "Remember".
    o	When you manage to add/remove/update new words in any of your devices, in the same time the changes will be synchronized to the cloud. If you have some problem when you try to add/remove/update a new word in a device, it's probably because the new words in the device are not the latest. Please click the sync button in top app bar to load the latest new words from the cloud.
    o	Click the group label to expand the words under the group; click further a word to show the card of word details. You can update the review status in the card. The card also provides native translation button for the word. You can delete the new word by clicking the button "A-" in the card.
    o	Every day we may have many time fragments on the bus/metro or in the toilet :) The fragments can be utilized to review new words. More importantly, your new words will be stored on the cloud permanently and you can review your new words in all of your devices.

•	Settings：This page will include the following settings
	o	Native language: After the setting of native language has been completed, information in the entries in the dictionary can be translated to your native language. Currently, Plugghäst supports 46 languages
	o	UI language: The language being used in the interfaces (for instance, dialogs and buttons) in Plugghäst. Currently, the user can choose between Chinese and English
	o	Text translation engine: The native language translate function in Plugghäst uses the service of Google Translate by default. If you encounter problems when using the function, you may consider making an attempt to use translation services provided by Microsoft`,
            contactHead: 'Contact us',
            contactDesc: 'If you have any comments or suggestions on Plugghäst, please feel free to send email to ',
            donationHead: 'Donation',
            donationDesc: '\tFrom the very beginning, we have carried out Plugghäst as a non-profit project. In the future, we will continue our work in Plugghäst as a non-profit project. Right now, the new Plugghäst v1.0 is released. We hope that this new version will bring forth new experiences and help for you. If you feel that Plugghäst has been of help to you in the process of studying Swedish, you are welcomed to make donation to our work. Regardless of how much or how little your donation is, it always is a huge encouragement to us. We can hereby guarantee that every krona and öre in your donation will be used to maintain and further develop Plugghäst, for instance, to keep the domain name and cover the costs related to cloud service. Thank you very much for your support!',
            notSelectedText: 'Not selected'
        },
        NewsPage: {
            pageTile: 'News in Sv',
            pageDescription: 'Read news in Swedish'
        },
        TipsPage: {
            pageTile: 'Tips of the Day',
            pageDescription: 'useful tips'
        },
        QuestionPage: {
            pageTile: 'Ask Question',
            pageDescription: 'Ask questions'
        },
        SignInPage: {
            headText: 'Sign in with email',
            emailLabel: 'Email Address',
            passwordLabel: 'Password',
            signinText: 'Sign In',
            forgetPasswordText: 'Forget password?',
            dontHaveAccountText: 'Don\'t have an account? Sign Up',
            signinDirectlyText: 'Or sign in directly with',
            facebookAccoount: 'Facebook Account',
            googleAccount: 'Google Account'
        },
        SignUpPage: {
            headText: 'Sign up with email',
            emailLabel: 'Email Address',
            passwordLabel: 'Password',
            signupText: 'Sign Up',
            signinText: 'Sign In',
            haveAccountText: 'Already have an account? Sign in'
        },
        ResetPasswordPage: {
            headText: 'Reset password',
            emailLabel: 'Email Address',
            resetText: 'Reset',
            signinText: 'Sign in',
            signupText: 'Sign up',
            sentMsgText: 'A password reset email was sent. Click the link in the email to create a new password.'
        }
    }
}