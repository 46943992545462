import en from '../i18n/en'
import zhHans from '../i18n/zhHans'

export const languages = [
    '中文',
    'Afrikaans',
    'العربية',
    'বাংলা',
    'Bosanski',
    'Български',
    'Català',
    'Hrvatski',
    'Česky',
    'Dansk',
    'Nederlands',
    'Eesti',
    'Suomi',
    'Français',
    'Deutsch',
    'Ελληνικά',
    'Krèyol ayisyen',
    'עברית',
    'हिन्दी',
    'Magyar',
    'Íslenska',
    'Bahasa Indonesia',
    'Italiano',
    '日本語',
    '한국어',
    'Latviešu',
    'Lietuvių',
    'Bahasa Melayu',
    'bil-Malti',
    'Norsk (bokmål)',
    'فارسی',
    'Polski',
    'Português',
    'Română',
    'Русский',
    'Српски',
    'Slovenčina',
    'Slovenščina',
    'Español',
    'தமிழ்',
    'ไทย',
    'Türkçe',
    'Українська',
    'اردو',
    'Việtnam',
    'Cymraeg'
]

export const langCodes = [
    'zh-Hans',
    'af',
    'ar',
    'bn',
    'bs',
    'bg',
    'ca',
    'hr',
    'cs',
    'da',
    'nl',
    'et',
    'fi',
    'fr',
    'de',
    'el',
    'ht',
    'he',
    'hi',
    'hu',
    'is',
    'id',
    'it',
    'ja',
    'ko',
    'lv',
    'lt',
    'ms',
    'mt',
    'nb',
    'fa',
    'pl',
    'pt',
    'ro',
    'ru',
    'sr-Latn',
    'sk',
    'sl',
    'es',
    'ta',
    'th',
    'tr',
    'uk',
    'ur',
    'vi',
    'cy'
]

const i18nStrings = {
    en,
    zhHans
}

export function getI18nStrings(language, componentName) {
    let uiLang
    if (language === '') {  // haven't set UI language yet
        uiLang = getPossibleUiLangFromBrowserLang(navigator.language)
    } else {
        uiLang = language.replace('-', '')

        if (uiLang !=='zhHans') {
            uiLang = 'en'
        }
    }

    return i18nStrings[uiLang][componentName]
}

export function getLanguageNameFromCode(langCode) {
    let index = langCodes.findIndex(code => code === langCode)
    if (index === -1)
        return ''
    else
        return languages[index]
}

// we detect the browser language and it is probably the user's native language
export function getPossibleNativeLangFromBrowserLang(browserLang) {
    const bLang = browserLang.substring(0, 2)
    const index = langCodes.findIndex(code => code.substring(0, 2) === bLang)
    if (index === -1 )
        return ''
    else
        return langCodes[index]
}

export function getPossibleUiLangFromBrowserLang(browserLang) {
    if (browserLang.substring(0, 2) === 'zh')
        return 'zhHans'
    else
        return 'en'
}