import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PropTypes from "prop-types"
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Cancel from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'


export default function TranslateTextField(props) {
    const theme = useTheme();
    const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const { textValue, onTextChange, onTextBlur, saveTextFieldRef, textLabel, textPlaceholder, isAutoFocus, showClearButton, clearInputText } = props

    return (
        <TextField
            style={{ width: '100%', marginTop: 10}}
            label={textLabel}
            multiline
            rows={isBigScreen ? '8' : '4'}
            placeholder={textPlaceholder}
            margin="none"
            variant="outlined"
            value={textValue}
            autoFocus={isAutoFocus}
            onChange={onTextChange}
            onBlur={onTextBlur}
            inputRef={saveTextFieldRef}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                endAdornment: (
                    (showClearButton && textValue.length > 0)
                    && (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                onClick={clearInputText}
                            >
                                <Cancel/>
                            </IconButton>
                        </InputAdornment>
                    )
                ),
            }}
        />
    )
}

TranslateTextField.propTypes = {
    textValue: PropTypes.string.isRequired,
    textLabel: PropTypes.string.isRequired,
    textPlaceholder: PropTypes.string.isRequired,
    isAutoFocus: PropTypes.bool.isRequired,
    onTextChange: PropTypes.func,
    onTextBlur: PropTypes.func,
    saveTextFieldRef: PropTypes.func,
    showClearButton: PropTypes.bool.isRequired,
    clearInputText: PropTypes.func
}

TranslateTextField.defaultProps = {
    showClearButton: false
}