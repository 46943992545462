import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { isStringText, copyTextToClipboard, isObjectEmpty, loadStringFromLocalStorage, saveStringToLocalStorage, LOCAL_STORAGE_KEY_NAME, parseString } from '../../common/util'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AssignmentReturnOutlined, InfoOutlined } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { getLanguageNameFromCode } from '../../i18n/i18ncommon'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import { DATA_SOURCE_GAPI} from "../../actions/actions"

const SV_LABEL_TEXT_ID = 'svLabelText'
const EN_LABEL_TEXT_ID = 'enLabelText'
const NATIVE_TRANSLATION_TITLE_ID = 'nativeTranslationTitle'
const CONFIDENCE_LABEL_TEXT_ID = 'confidenceLabelText'
const NO_RESULT_TEXT_ID = 'noResultsText'
const COPY_TO_CLIPBOARD_TEXT_ID = 'copyToClipboardText'
const DETECT_BROWSER_LANG_DESC_ID = 'detectBrowserLanguageDesc'
const SET_NATIVE_LANG_BUTTON_TEXT_ID = 'setNativeLangButtonText'
const NO_NEED_SET_TEXT_ID = 'noNeedToSetText'
const CLOSE_BUTTON_TEXT_ID = 'closeButtonText'
const OK_BUTTON_TEXT_ID = 'okButtonText'

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        textTransform: 'none'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
});

class NativeTranslateDialog extends React.Component {
    static propTypes = {
        svText: PropTypes.string.isRequired,
        nativeTranslateText: PropTypes.string.isRequired,
        srcLang: PropTypes.string.isRequired,    // which language the native translation comes from. For example, for Swedish definition, the srcLang is sv
        nativeTranslateData: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        nativeLanguage: PropTypes.string.isRequired,
        i18nStrings: PropTypes.object.isRequired,
        onOpenSelectNativeDialog: PropTypes.func.isRequired,
        handleNativeLangChange: PropTypes.func.isRequired,
        textTranslateEngine: PropTypes.string.isRequired,
        apiSource: PropTypes.string.isRequired
    }

    handleClose = () => {
        this.props.onClose()
    }

    handleCopyText = () => {
        const { svText, nativeTranslateText } = this.props
        let copyText = svText === nativeTranslateText ? svText : (svText + '\n' + nativeTranslateText)
        copyTextToClipboard(copyText)
    }

    render() {
        const {svText, nativeTranslateText, nativeTranslateData, isFetching, classes, nativeLanguage, textTranslateEngine,
            i18nStrings, onOpenSelectNativeDialog, handleNativeLangChange, apiSource, srcLang, ...other} = this.props

        let translations
        // svText is from Dictionary page's state. When switching between the pages, the state will be lost. then we don't need to check further
        // Because null == undefined is true, the code will catch both null and undefined.
        if (svText === '' || nativeTranslateData == null || isObjectEmpty(nativeTranslateData))
            translations = []
        else
            translations = isFetching ? [] : nativeTranslateData['translations']

        // NOTE: for the case of Swedish->Chinese, MS api only supports text translate
        const isText = (srcLang === 'sv' || apiSource === DATA_SOURCE_GAPI) ? true : isStringText(nativeTranslateText)
        const fullText = i18nStrings[SV_LABEL_TEXT_ID] + svText + (nativeTranslateText === svText ? '' : i18nStrings[EN_LABEL_TEXT_ID] + nativeTranslateText)

        const savedNative = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.NATIVE_LANGUAGE.description)
        const languageName = getLanguageNameFromCode(nativeLanguage)


        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle onClick={() => this.handleClose()} id="simple-dialog-title">{i18nStrings[NATIVE_TRANSLATION_TITLE_ID]}({languageName})</DialogTitle>
                <div >
                    {
                       isFetching
                        ? (
                                <CircularProgress size={68} color="primary" />
                           )
                         : (<div>
                               {
                                   savedNative === ''
                                   && (
                                       <Card style={{marginLeft: 10, marginRight: 10}}>

                                           <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                               <InfoOutlined/>
                                               <Typography color='textSecondary'>
                                                   {
                                                       parseString(i18nStrings[DETECT_BROWSER_LANG_DESC_ID], languageName, languageName)
                                                   }
                                               </Typography>
                                           </div>
                                           <Button color="primary" size="small" onClick={() => {
                                               this.handleClose()
                                               onOpenSelectNativeDialog()
                                           }}
                                                   className={classes.button}>
                                               {i18nStrings[SET_NATIVE_LANG_BUTTON_TEXT_ID]}
                                           </Button>
                                           <Button color="primary" size="small" onClick={() => {
                                               this.handleClose()
                                               handleNativeLangChange('noNeed')
                                           }}
                                                   className={classes.button}>
                                               {i18nStrings[NO_NEED_SET_TEXT_ID]}
                                           </Button>
                                       </Card>
                                   )

                               }
                                <List>
                                    {
                                        translations.length > 0 ?
                                        (translations.map((translation, i) => (
                                        <ListItem key={i}>
                                            <ListItemText
                                                primary={isText ? translation.text : translation.displayTarget}
                                                secondary={fullText + (isText ? '' : (' ' + translation.posTag.toLowerCase() + i18nStrings[CONFIDENCE_LABEL_TEXT_ID] + translation.confidence))} />
                                        </ListItem>
                                    ))):
                                        (<p>{i18nStrings[NO_RESULT_TEXT_ID]}</p>)}
                                </List>
                            </div>)
                    }

                </div>
                <DialogActions>
                    <Button onClick={this.handleCopyText} size="small" color="primary" className={classes.button}>
                        <AssignmentReturnOutlined className={classes.iconSmall} />
                        {i18nStrings[COPY_TO_CLIPBOARD_TEXT_ID]}
                    </Button>
                    <Button onClick={() => {
                            if (savedNative === '') {
                                saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.NATIVE_LANGUAGE.description, nativeLanguage)
                            }
                            this.handleClose()
                        }}
                            size="small" color="primary" className={classes.button}>
                        {savedNative === '' ? i18nStrings[OK_BUTTON_TEXT_ID] : i18nStrings[CLOSE_BUTTON_TEXT_ID]}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(NativeTranslateDialog)