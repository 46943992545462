import React from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import PropTypes from "prop-types"

export default function CustomSelect(props) {
    const handleChange = (event) => {
        let newOption = event.target.value
        props.onSelectOption(newOption)
    }

    return (
        <NativeSelect
            value={props.initialValue}
            onChange={handleChange}
        >
            {
                props.optionValues
                && props.optionValues.map((optionValue, i) => (
                    <option value={optionValue} key={'option' + i}>{props.optionTexts[i]}</option>
                ))
            }
        </NativeSelect>
    )
}

CustomSelect.propTypes = {
    initialValue: PropTypes.string.isRequired,
    optionValues: PropTypes.array.isRequired,
    optionTexts: PropTypes.array.isRequired,
    onSelectOption: PropTypes.func.isRequired
}