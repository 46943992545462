import React from "react"
import PropTypes from "prop-types"
import {appbarHeigh, drawerWidth} from "../ResponsiveDrawer"
import {withStyles} from "@material-ui/core"
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import {isObjectEmpty, LOCAL_STORAGE_KEY_NAME, saveStringToLocalStorage, loadStringFromLocalStorage} from "../../common/util";
import {
    DATA_SOURCE_GAPI,
    fetchDetailsIfNeeded, lookupKey,
    GAPI_REQUEST_TRANS_PAGE_TYPE,
    selectDataSource,
    dispatchQueryRequest,
    DATA_SOURCE_FOLKLEX, REQUEST_WORD_TYPE
} from "../../actions/actions"
import AdaptiveSwapButton from '../small/AdaptiveSwapButton'
import TranslateLanguageSelect from '../small/TranslateLanguageSelect'
import SpecialLetterButtons from '../small/SpecialLetterButtons'
import TranslateTextField from '../small/TranslateTextField'
import { pageName } from "../../containers/App"
import {connect} from "react-redux";
import {showMessage} from "../../actions/actions";
import { history } from "../../containers/App";

const TRANS_BUTTON_TEXT_ID = 'transButtonText'
const SAME_LANG_ALERT_ID = 'sameLanguagesAlert'
const EMPTY_INPUT_ALERT_ID = 'emptyInputAlert'
const LABEL_FROM_TEXT_ID = 'labelFrom'
const LABEL_TO_TEXT_ID = 'labelTo'
const PLACEHOLDER_FROM_TEXT_ID = 'placeholderFrom'
const JUMP_TO_DICT_BUTTON_TEXT_ID = 'jump2DictButtonText'

const styles = theme => ({
    nested: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,
            marginLeft: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 10,
            marginLeft: drawerWidth + 10
        },
    },
    inputGroup: {
        display: 'flex',
        marginTop: 10,
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'row'
        }
    },
    swapButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 30
        }
    }
});

class TranslatePage extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        apiNativeText: PropTypes.string.isRequired,
        apiDetails: PropTypes.object.isRequired,      // api response details
        apiIsFetching: PropTypes.bool.isRequired,
        transPageFromLang: PropTypes.string.isRequired,
        transPageToLang: PropTypes.string.isRequired,

        dispatch: PropTypes.func.isRequired,

        i18nStrings: PropTypes.object.isRequired,
        nativeLanguage: PropTypes.string.isRequired,
        openAlertDialog: PropTypes.func.isRequired,

        textTranslateEngine: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            fromText: '',

            fromLang: '',
            toLang: '',
        }
    }

    componentDidMount() {
        const { apiNativeText, nativeLanguage, dispatch, textTranslateEngine } = this.props
        const { fromText, fromLang, toLang } = this.state

        let lastFromLang = fromLang
        if (fromLang === '') {  // first load
            let savedValue = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_FROM_LANGUAGE.description)
            lastFromLang = savedValue !== '' ? savedValue : 'sv'
            this.setState(state =>
                ({
                    ...state,
                    fromLang: lastFromLang
                }));
        }

        let lastToLang = toLang
        if (toLang === '') {
            let savedValue = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_TO_LANGUAGE.description)
            lastToLang = savedValue !== ''
                        ? savedValue
                        : ((nativeLanguage !== 'noNeed' && nativeLanguage !== '')
                                ? nativeLanguage : 'en')
            this.setState(state =>
                ({
                    ...state,
                    toLang: lastToLang
                }));
        }

        if (apiNativeText === '') {
            let lastTranslate = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_TRANSLATE.description)
            if (lastTranslate && lastTranslate !== '') {
                dispatchQueryRequest(dispatch, textTranslateEngine, true, lastTranslate, lastFromLang, lastToLang)
            }
        }

        if (fromText === '') {
            let lastInput = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_INPUT_TRANSLATE.description)
            this.setState(state =>
                ({
                    ...state,
                    fromText: lastInput
                }));
        }
    }

    handleTranslateClick = () => {
        const { dispatch, i18nStrings } = this.props
        const { fromText, fromLang, toLang} = this.state
        if (fromLang === toLang) {
            dispatch(showMessage(i18nStrings[SAME_LANG_ALERT_ID]))
            return
        }

        if (fromText === '') {
            dispatch(showMessage(i18nStrings[EMPTY_INPUT_ALERT_ID]))
            return
        }

        dispatch(selectDataSource(DATA_SOURCE_GAPI))
        dispatch(lookupKey(DATA_SOURCE_GAPI, GAPI_REQUEST_TRANS_PAGE_TYPE, fromText, fromLang, toLang))
        dispatch(fetchDetailsIfNeeded(DATA_SOURCE_GAPI, GAPI_REQUEST_TRANS_PAGE_TYPE, fromText, fromLang, toLang))

    }

    handleFromTextChange = (event) => {
        let newText = event.target.value
        this.setState(state =>
            ({
                ...state,
                fromText: newText
            }));
    }

    onSelectFromLang = (newLang) => {
        let { toLang } = this.state
        if (toLang === newLang) {
            toLang = this.getDifferentToLang(toLang)
        }

        this.setState(state =>
            ({
                ...state,
                fromLang: newLang,
                toLang: toLang
            }));
    }

    getDifferentToLang = (toLang) => {
        const { nativeLanguage } = this.props
        let langList = ['sv', 'en']
        if (nativeLanguage !== 'noNeed' && nativeLanguage !== '') {
            langList.unshift(nativeLanguage)
        }

        for (let lang of langList) {
            if (lang !== toLang)
                return lang
        }
    }

    onSelectToLang = (newLang) => {
        this.setState(state =>
            ({
                ...state,
                toLang: newLang
            }));
    }

    clickSpecialLetter = (letter) => {
        if (this.fromTextFieldRef) {
            this.fromTextFieldRef.focus()
        }

        const caretPostion = this.fromTextFieldRef.selectionEnd
        const { fromText } = this.state

        this.setState(state =>
            ({
                ...state,
                fromText: fromText.slice(0, caretPostion) + letter + fromText.slice(caretPostion)
            }));
    }

    onSwapFields = (toText) => {
        const { dispatch, i18nStrings, textTranslateEngine } = this.props
        const { fromLang, toLang } = this.state
        if (fromLang === toLang) {
            dispatch(showMessage(i18nStrings[SAME_LANG_ALERT_ID]))
            return
        }

        if (toText === '') {
            dispatch(showMessage(i18nStrings[EMPTY_INPUT_ALERT_ID]))
            return
        }

        dispatchQueryRequest(dispatch, textTranslateEngine, true, toText, toLang, fromLang)

        this.setState(state =>
            ({
                ...state,
                fromText: toText,
                fromLang: toLang,
                toLang: fromLang
            }));


        saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_FROM_LANGUAGE.description, toLang)
        saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_TO_LANGUAGE.description, fromLang)
        saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_TRANSLATE.description, toText)
        saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_INPUT_TRANSLATE.description, toText)
    }

    onFromTextFieldBlur = () => {
        const { fromText } = this.state

        if (fromText !== '')
            saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_INPUT_TRANSLATE.description, fromText)
    }

    saveFromTextFieldRef = (ref) => {
        this.fromTextFieldRef = ref
    }

    saveToTextFieldRef = (ref) => {
        this.toTextFieldRef = ref
    }

    clearFromTextField = () => {
        this.setState(state => ({
            ...state,
            fromText: ''
        }))

        if (this.fromTextFieldRef) {
            this.fromTextFieldRef.focus()
        }
    }

    onClickJump2Dictionary = () => {
        const { dispatch } = this.props
        const toText = this.toTextFieldRef.value

        if (toText !== '')
            saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.LAST_INPUT_DICTIONARY.description, toText)

        history.push("/" + pageName.DICTIONARY.description)

        dispatch(selectDataSource(DATA_SOURCE_FOLKLEX))

        dispatch(lookupKey(DATA_SOURCE_FOLKLEX, REQUEST_WORD_TYPE, toText))
        dispatch(fetchDetailsIfNeeded(DATA_SOURCE_FOLKLEX, REQUEST_WORD_TYPE, toText))
    }

    render() {
        const { classes, apiDetails, apiIsFetching, apiNativeText, i18nStrings, nativeLanguage, transPageFromLang, transPageToLang } = this.props
        const { fromText, fromLang, toLang } = this.state

        let toText
        if (fromText === '' || fromText !== apiNativeText || fromLang !== transPageFromLang || toLang !== transPageToLang ) {
            toText = ''
        } else {
            let translations
            if (apiDetails == null || isObjectEmpty(apiDetails)) // Because null == undefined is true, the code will catch both null and undefined.
                translations = []
            else
                translations = apiIsFetching ? [] : apiDetails['translations']

            toText = translations.length > 0 ? translations[0].text : ''

            if (toText !== '')
                toText = toText.toLowerCase()
        }

        return (
            <div className={classes.nested}>
                <div style={{marginTop: appbarHeigh + 10, flex: 1, flexDirection: 'column', alignItems: 'center'}}>
                    <div id='testdiv' className={classes.inputGroup}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1, width: '100%'}}>
                            <TranslateLanguageSelect
                                nativeLanguage={nativeLanguage}
                                selectedLang={fromLang}
                                onSelectLang={this.onSelectFromLang}
                                i18nStrings={i18nStrings['TranslateLanguageSelect']}/>
                            <TranslateTextField
                                textValue={fromText}
                                textLabel={i18nStrings[LABEL_FROM_TEXT_ID]}
                                textPlaceholder={i18nStrings[PLACEHOLDER_FROM_TEXT_ID]}
                                isAutoFocus={true}
                                onTextChange={this.handleFromTextChange}
                                onTextBlur={this.onFromTextFieldBlur}
                                saveTextFieldRef={this.saveFromTextFieldRef}
                                showClearButton={true}
                                clearInputText={this.clearFromTextField}
                            />
                            <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                                <SpecialLetterButtons onClickSpecialButton={this.clickSpecialLetter} considerScreenSize={false} withFrame={false} hasMarginLeftMost={false}/>
                                <Button onClick={this.handleTranslateClick} style={{marginLeft: 5, textTransform: 'none'}} variant="contained" size="small" color="primary">
                                    {i18nStrings[TRANS_BUTTON_TEXT_ID]}
                                </Button>
                            </div>
                        </div>
                        <IconButton className={classes.swapButton} onClick={() => this.onSwapFields(toText)}>
                            <AdaptiveSwapButton/>
                        </IconButton>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1, width: '100%'}}>
                            <TranslateLanguageSelect
                                nativeLanguage={nativeLanguage}
                                selectedLang={toLang}
                                onSelectLang={this.onSelectToLang}
                                i18nStrings={i18nStrings['TranslateLanguageSelect']}/>
                            <TranslateTextField
                                textValue={ toText }
                                textLabel={i18nStrings[LABEL_TO_TEXT_ID]}
                                textPlaceholder=""
                                saveTextFieldRef={this.saveToTextFieldRef}
                                isAutoFocus={false}/>
                            {
                                (toText !== '' && (toLang === 'sv' || toLang === 'en'))
                                && <Button onClick={this.onClickJump2Dictionary} style={{marginTop: 10, textTransform: 'none'}} variant="contained" size="small" color="primary">
                                    {i18nStrings[JUMP_TO_DICT_BUTTON_TEXT_ID]}
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    showMessageStatus: state.showMessageStatus
})

export default connect(mapStateToProps)(withStyles(styles)(TranslatePage))