import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'


class AlertDialog extends React.Component{
    static propTypes = {
        dialogTitle: PropTypes.string.isRequired,
        dialogText: PropTypes.string.isRequired,
        dialogOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        dialogActions: PropTypes.array
    }

    handleClose = () => {
        this.props.onClose()
    }

    render() {

        return (
            <Dialog
                open={this.props.dialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.dialogText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        this.props.dialogActions.map(action => (
                            <Button
                                key={action.actionText}
                                style={{textTransform: 'none'}}
                                onClick={() => {
                                        if (action.action) {action.action()}

                                        this.handleClose()
                                    }}
                                color="primary">
                                {action.actionText}
                            </Button>
                            )
                        )
                    }

                </DialogActions>
            </Dialog>
        )
    }
}

export default AlertDialog