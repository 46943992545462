import React from "react"
import PropTypes from "prop-types"
import InformationPaper from '../components/pages/InformationPaper'
import DictionaryPage from '../components/pages/DictionaryPage'
import TranslatePage from '../components/pages/TranslatePage'
import SettingsPage from '../components/pages/SettingsPage'
import NewWordsPage from "../components/pages/NewWordsPage"
import {PAGE_INFORMATION} from "./App"
import {connect} from "react-redux"
import {
    MSAPI_REQUEST_TRANS_PAGE_TYPE,
    GAPI_REQUEST_TRANS_PAGE_TYPE,
    DATA_SOURCE_MSAPI,
    DATA_SOURCE_GAPI,
    closeMessage
} from '../actions/actions'
import { isObjectEmpty } from '../common/util'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import SignInPage from "../components/pages/SignInPage";
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import CloseIcon from "@material-ui/icons/Close";
import SignUpPage from "../components/pages/SignUpPage";
import ResetPasswordPage from "../components/pages/ResetPasswordPage";
import { withStyles } from "@material-ui/core/styles"
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import NativeTranslateDialog from "../components/dialogs/NativeTranslateDialog";
import TryLuckResultDialog from "../components/dialogs/TryLuckResultDialog";

const styles = {
    snackbarStyleAlert: {
        backgroundColor: "#EE2B29"
    },
    snackbarStyleInfo: {
        backgroundColor: "#1E80F0"
    },
    snackbarStyleSuccess: {
        backgroundColor: "#3FA33F"
    }
};

const snackbarIcons = [ReportProblemOutlinedIcon, InfoOutlinedIcon, CheckCircleOutlinedIcon]
const snackbarStyleNames = ['snackbarStyleAlert', 'snackbarStyleInfo', 'snackbarStyleSuccess']

class PageContainer extends React.Component{
    static propTypes = {
        i18nStrings: PropTypes.object.isRequired,

        // the data of api call (ms or google)
        apiDetails: PropTypes.object.isRequired,
        apiIsFetching: PropTypes.bool.isRequired,
        apiNativeText: PropTypes.string.isRequired,

        transPageFromText: PropTypes.string.isRequired,
        transPageDetails: PropTypes.object.isRequired,
        transPageIsFetching: PropTypes.bool.isRequired,
        transPageFromLang: PropTypes.string.isRequired,
        transPageToLang: PropTypes.string.isRequired,

        handleNativeLangChange: PropTypes.func.isRequired,
        nativeLanguage: PropTypes.string.isRequired,

        onOpenSelectNativeDialog: PropTypes.func.isRequired,
        openAlertDialog: PropTypes.func.isRequired,

        onSelectUiLang: PropTypes.func.isRequired,
        uiLanguage: PropTypes.string.isRequired,

        onSelectTextTransEngine: PropTypes.func.isRequired,
        textTranslateEngine: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            openNativeDialog: false,
            svText: '',      // the original text which will be used in NativeTranslateDialog
            enText: '',      // the English text for NativeTranslateDialog
            srcLang: '',     // in Native dialog, which language the native translation comes from

            openTryLuckDialog: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const currentLocation = this.props.location
        if (currentLocation !== prevProps.location || this.props.i18nStrings !== prevProps.i18nStrings) {
            const currentPath = currentLocation.pathname
            if (currentPath && currentPath.length > 1) {
                document.getElementById("header").innerText = this.props.i18nStrings[currentPath.substring(1) + 'Title']
            }
        }
    }

    componentDidMount() {
        const currentPath = this.props.location.pathname
        if (currentPath && currentPath.length > 1) {
            document.getElementById("header").innerText = this.props.i18nStrings[currentPath.substring(1) + 'Title']
        }
    }

    onCloseSnackBar = () => {
        this.props.closeSnackbar()
    }

    onCloseNativeTranslateDialog = () => {
        this.setState(state =>
            ({
                ...state,
                openNativeDialog: false
            }));
    }

    onOpenNativeTranslationDialog = (svText, enText, srcLang) => {
        this.setState(state =>
            ({
                ...state,
                openNativeDialog: true,
                svText: svText,
                enText: enText,
                srcLang: srcLang
            }));
    }

    onOpenTryLuckDialog = (svText, srcLang) => {
        this.setState(state =>
            ({
                ...state,
                openTryLuckDialog: true,
                svText: svText,
                srcLang: srcLang
            }));
    }

    onCloseTryLuckDialog = () => {
        this.setState(state =>
            ({
                ...state,
                openTryLuckDialog: false
            }));
    }

    renderPageWithRoute = () => {
        const { i18nStrings, nativeLanguage, apiIsFetching, apiDetails,
            transPageFromText, transPageIsFetching, transPageDetails, dispatch, onOpenSelectNativeDialog,
            handleNativeLangChange, openAlertDialog, onSelectUiLang, uiLanguage, onSelectTextTransEngine,
            textTranslateEngine, transPageFromLang, transPageToLang, location } = this.props

        return (

                <div>
                    <Switch>
                        <Redirect exact from="/" to="/dictionary"/>
                        <Route exact path="/dictionary"
                               render={(props) => <DictionaryPage {...props}
                                                      apiDetails={apiDetails}
                                                      apiIsFetching={apiIsFetching}
                                                      i18nStrings={i18nStrings['DictionaryPage']}
                                                      onOpenSelectNativeDialog={onOpenSelectNativeDialog}
                                                      onOpenNativeTranslationDialog={this.onOpenNativeTranslationDialog}
                                                      onOpenTryLuckDialog={this.onOpenTryLuckDialog}
                                                      handleNativeLangChange={handleNativeLangChange}
                                                      openAlertDialog={openAlertDialog}
                                                      textTranslateEngine={textTranslateEngine}/>} />
                        <Route exact path="/translate"
                               render={(props) => <TranslatePage {...props}
                                                     apiIsFetching={transPageIsFetching}
                                                     apiNativeText={transPageFromText}
                                                     apiDetails={transPageDetails}
                                                     dispatch={dispatch}
                                                     i18nStrings={i18nStrings['TranslatePage']}
                                                     nativeLanguage={nativeLanguage}
                                                     openAlertDialog={openAlertDialog}
                                                     transPageFromLang={transPageFromLang}
                                                     transPageToLang={transPageToLang}
                                                     textTranslateEngine={textTranslateEngine}/>} />

                        <Route exact path="/settings"
                               render={(props) => <SettingsPage {...props}
                                                    nativeLanguage={nativeLanguage}
                                                    i18nStrings={i18nStrings['SettingsPage']}
                                                    handleNativeLangChange={handleNativeLangChange}
                                                    onSelectUiLang={onSelectUiLang}
                                                    uiLanguage={uiLanguage}
                                                    textTranslateEngine={textTranslateEngine}
                                                    onSelectTextTransEngine={onSelectTextTransEngine}/>} />
                        <Route exact path="/new_words"
                               render={(props) => <NewWordsPage {...props}
                                                    onOpenNativeTranslationDialog={this.onOpenNativeTranslationDialog}
                                                    i18nStrings={i18nStrings['NewWordsPage']}
                                                                            />} />
                        <Route exact path="/signin"
                               render={(props) => <SignInPage {...props}
                                                             i18nStrings={i18nStrings['SignInPage']}
                               />} />
                        <Route exact path="/signup"
                               render={(props) => <SignUpPage {...props}
                                                              i18nStrings={i18nStrings['SignUpPage']}
                               />} />
                        <Route exact path="/reset_password"
                               render={(props) => <ResetPasswordPage {...props}
                                                              i18nStrings={i18nStrings['ResetPasswordPage']}
                               />} />
                        <Route exact path={["/daily_task","/tips", "/news", "/specialty", "/quiz_game"]}
                               render={(props) => {
                                   const currentPath = location.pathname.substring(1)
                                   return (
                                       <InformationPaper {...props}
                                                         infoTitle={i18nStrings[currentPath + 'Title']}
                                                         infoText={i18nStrings[currentPath + 'Desc']}
                                                         headimage={PAGE_INFORMATION[currentPath].headImage}/>
                                   )
                               }} />
                    </Switch>
                </div>

        )
    }

    render() {
        const {i18nStrings, nativeLanguage, apiIsFetching, apiDetails, onOpenSelectNativeDialog,
            handleNativeLangChange, textTranslateEngine, dataSource, dispatch} = this.props
        const snackbarType = this.props.showMessageStatus !== null ? this.props.showMessageStatus.messageType : 1
        return (
            <div>
                {
                    this.renderPageWithRoute()
                }
                {
                    this.props.showMessageStatus !== null
                    && <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        ContentProps={{
                            className: this.props.classes[snackbarStyleNames[snackbarType]]
                        }}
                        open={true}
                        autoHideDuration={this.props.showMessageStatus.showTime}
                        onClose={this.onCloseSnackBar}
                        message={<div id="message-id" style={{textAlign: 'left', display: 'flex'}}>
                            {
                                (() => {
                                    const SnackIcon = snackbarIcons[snackbarType]
                                    return <SnackIcon />
                                })()
                            }
                            <div style={{marginLeft: 5}}>
                                {this.props.showMessageStatus !== null ? this.props.showMessageStatus.message : ''}
                            </div>
                        </div>}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.onCloseSnackBar}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                }
                {
                    <NativeTranslateDialog
                        open={this.state.openNativeDialog}
                        onClose={this.onCloseNativeTranslateDialog}
                        svText={this.state.svText}
                        nativeTranslateText={this.state.enText}
                        srcLang={this.state.srcLang}
                        nativeTranslateData={apiDetails}
                        isFetching={apiIsFetching}
                        nativeLanguage={nativeLanguage}
                        i18nStrings={i18nStrings['NativeTranslateDialog']}
                        onOpenSelectNativeDialog={onOpenSelectNativeDialog}
                        handleNativeLangChange={handleNativeLangChange}
                        textTranslateEngine={textTranslateEngine}
                        apiSource={dataSource}
                    />
                }
                <TryLuckResultDialog
                    open={this.state.openTryLuckDialog}
                    svText={this.state.svText}
                    onDialogClose={this.onCloseTryLuckDialog}
                    nativeTranslateData={apiDetails}
                    isFetching={apiIsFetching}
                    i18nStrings={i18nStrings['TryLuckResultDialog']}
                    openNativeDialog={this.onOpenNativeTranslationDialog}
                    dispatch={dispatch}
                    onOpenSelectNativeDialog={onOpenSelectNativeDialog}
                    />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { dataBySource, dataSource, showMessageStatus } = state

    let apiIsFetching, apiNativeText, apiDetails, transPageFromText, transPageDetails, transPageIsFetching, transPageFromLang, transPageToLang
    apiIsFetching = false
    apiNativeText = ''
    apiDetails = {}

    transPageIsFetching = false
    transPageFromText = ''
    transPageDetails = {}
    transPageFromLang = ''
    transPageToLang = ''

    if (dataSource === DATA_SOURCE_MSAPI || dataSource === DATA_SOURCE_GAPI) {
        if (dataBySource[dataSource] && !isObjectEmpty(dataBySource[dataSource])) {
            let apiRequstType = dataBySource[dataSource]['selectRequestType']
            let { isFetching, nativeText, details, translateToLang, translateFromLang } = getApiResultsFromData(dataBySource, dataSource, apiRequstType)
            if (apiRequstType === MSAPI_REQUEST_TRANS_PAGE_TYPE || apiRequstType === GAPI_REQUEST_TRANS_PAGE_TYPE) {
                transPageIsFetching = isFetching
                transPageFromText = nativeText
                transPageDetails = details
                transPageFromLang = translateFromLang
                transPageToLang = translateToLang
            } else {
                apiIsFetching = isFetching
                apiNativeText = nativeText
                apiDetails = details
            }
        }
    }

    return {
        apiDetails,
        apiNativeText,
        apiIsFetching,
        dataSource,

        transPageIsFetching,
        transPageFromText,
        transPageDetails,
        transPageFromLang,
        transPageToLang,
        showMessageStatus
    }
}

function getApiResultsFromData(dataBySource, dataSource, apiRequstType) {
    let isFetching, presentIndex, nativeText, details, translateFromLang, translateToLang
    isFetching = false
    nativeText = ''
    details = {}
    translateFromLang = ''
    translateToLang = ''

    if (dataBySource[dataSource][apiRequstType] && !isObjectEmpty(dataBySource[dataSource][apiRequstType])) {
        isFetching = dataBySource[dataSource][apiRequstType].isFetching
        presentIndex = dataBySource[dataSource][apiRequstType].presentIndex
        nativeText = dataBySource[dataSource][apiRequstType].history[presentIndex].keyWord
        if (isFetching) {
            details = {}
        } else {
            const translateData = dataBySource[dataSource][apiRequstType].history[presentIndex].details
            const isDetailsEmpty = isObjectEmpty(translateData)
            details = (translateData && !isDetailsEmpty) ? translateData[0] : {}  // it is an array which has only one element

            if ((apiRequstType === MSAPI_REQUEST_TRANS_PAGE_TYPE || apiRequstType === GAPI_REQUEST_TRANS_PAGE_TYPE) && !isDetailsEmpty) {
                translateFromLang = dataBySource[dataSource][apiRequstType].history[presentIndex].srcLang
                translateToLang = dataBySource[dataSource][apiRequstType].history[presentIndex].toLang
            }
        }
    }

    return {
        isFetching,
        nativeText,
        details,
        translateToLang,
        translateFromLang
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeSnackbar: () => dispatch(closeMessage())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PageContainer)))