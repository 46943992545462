import React, { useState, useEffect }  from 'react';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom"
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
//import Divider from '@material-ui/core/Divider';
import {startLogin, startFacebookLogin, startEmailLogin} from "../../actions/actions";
//import fblogo from '../../images/fblogo.png'
//import glogo from '../../images/glogo.png'
import CircularProgress from '@material-ui/core/CircularProgress';
import {LOCAL_STORAGE_KEY_NAME, saveStringToLocalStorage, loadStringFromLocalStorage} from "../../common/util";
import {appbarHeigh, drawerWidth} from "../ResponsiveDrawer"


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,
            marginLeft: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 10,
            marginLeft: drawerWidth + 50
        },
        marginTop: appbarHeigh + 10
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignInPage({startLogin, startFacebookLogin, startEmailLogin, i18nStrings, isAuthenticated, isDoingSignin}) {
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const savedEmail = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.USER_EMAIL_INPUT.description)
        if (savedEmail !== '') {
            setEmail(savedEmail)
        }
    })

    const onSubmit = event => {
        event.preventDefault()
        startEmailLogin(email, password)
    }

    const renderSignInForm = () => (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <CssBaseline />
            <div className={classes.paper}>
                <Box mt={2}>
                    <Typography component="h2" variant="h5">
                        {i18nStrings['headText']}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={i18nStrings['emailLabel']}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={event => {
                            const email = event.target.value
                            setEmail(email)
                            saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.USER_EMAIL_INPUT.description, email)
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={i18nStrings['passwordLabel']}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={event => setPassword(event.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {i18nStrings['signinText']}
                    </Button>
                    <Grid container>
                        <Grid item xs style={{textAlign: 'left'}}>
                            <Link to="/reset_password" >
                                {i18nStrings['forgetPasswordText']}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/signup">
                                {i18nStrings['dontHaveAccountText']}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {/* there are many problems for FB/Google login. so we temporarily disable it for 1.0 release
            <Divider style={{marginTop: 20}}/>
            <Box mt={2}>
                <Typography component="h2" variant="h5">
                    {i18nStrings['signinDirectlyText']}
                </Typography>
            </Box>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{backgroundColor: '#425A95', color: '#FFFFFF', textTransform: 'none'}}
                className={classes.submit}
                onClick={() => {
                    startFacebookLogin()
                }}
            >
                <img src={fblogo} alt="Facebook" style={{height: '30px', width: '30px', marginRight: 20}}/>
                {i18nStrings['facebookAccoount']}
            </Button>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{backgroundColor: '#5086ED', color: '#FFFFFF', textTransform: 'none'}}
                className={classes.submit}
                onClick={() => {
                    startLogin()
                }}
            >
                <img src={glogo} alt="Google" style={{height: '30px', width: '30px', marginRight: 20}}/>
                {i18nStrings['googleAccount']}
            </Button>
            */}
        </Container>
    )

    return (
        isAuthenticated ?
            (
                <Redirect to="/dictionary" />
            )
            :
            (
                isDoingSignin ?
                    (
                        <Box mt={2} className={classes.paper}>
                            <div style={{position: 'relative'}}>
                                <CircularProgress size={60}
                                                  style={{position: 'absolute',
                                                      top: '50%',
                                                      left: '50%',
                                                      marginTop: 30,
                                                      marginLeft: -30}}/>
                            </div>
                        </Box>
                    )
                    :
                    (
                        renderSignInForm()
                    )
            )
    );
}

const mapStateToProps = state => ({
    isAuthenticated: !!state.authStatus.uid,
    isDoingSignin: state.doingSignin
})

const mapDispatchToProps = (dispatch) => ({
    startLogin: () => dispatch(startLogin()),
    startFacebookLogin: () => dispatch(startFacebookLogin()),
    startEmailLogin: (email, password) => dispatch(startEmailLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage)