import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactCountryFlag from "react-country-flag";
import EmbeddedList from './EmbeddedList'
import { startAddNewWord, startRemoveNewWord, startFirebaseAction } from "../../actions/actions";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from '@material-ui/core/CircularProgress';
import {FormatAnnotationPlus, FormatAnnotationMinus} from "mdi-material-ui";

function getCountryCodeFromEntryLang(entryLang) {
    let countryCode = 'se'
    if ('en' === entryLang) {
        countryCode = 'gb'
    }
    return countryCode
}

function isEntryListExpandable(entryKey) {
    switch (entryKey) {
        case 'examples':
        case 'idioms':
        case 'compounds':
            return true
        default:
            return false
    }
}


class NestedList extends React.Component {
    static propTypes = {
        headString: PropTypes.string.isRequired,
        entryLang: PropTypes.string.isRequired,
        entryText: PropTypes.string.isRequired,     // the dictionary entry text. For a verb as an example,, it should be the verb's infinit form
        entryClass: PropTypes.string.isRequired,    // verb, noun, adj., ...
        entryComment: PropTypes.string.isRequired,
        wordDetails: PropTypes.object.isRequired,
        openNativeDialog: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        nativeLanguage: PropTypes.string.isRequired,
        openAlertDialog: PropTypes.func,
        openSaolInflectionDialog: PropTypes.func,
        setCurrentEntryText: PropTypes.func,
        i18nStrings: PropTypes.object.isRequired,
        onOpenSelectNativeDialog: PropTypes.func.isRequired,
        textTranslateEngine: PropTypes.string.isRequired,
        wordId: PropTypes.string.isRequired,         // the id of the word. It is uniq in the dictionary
        isInNewWords: PropTypes.bool.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
    }

    state = {
        open: false,
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    // called when trying to add/remove the current word to new words
    handleAddNewWord = () => {
        if (this.props.currentWordId !== null && this.props.currentWordId === this.props.wordId) {
            return  // is adding the new word
        }

        const { dispatch, wordDetails, wordId, isInNewWords, headString, entryText, entryClass, entryComment, i18nStrings } = this.props
        if (isInNewWords) {
            dispatch(startRemoveNewWord(wordId, "\"" + headString + "\"" + i18nStrings['removeNewWord']))
        } else {
            dispatch(startAddNewWord({
                wordId,
                entryText,
                entryClass,
                entryComment,
                reviewStatus: '3',    // 0: forget; 1: know; 2: remember; 3: not reviewed
                wordDetails: JSON.stringify(wordDetails),
            }, "\"" + headString + "\"" + i18nStrings['addNewWord']))
        }
    }

    render() {
        return (
            <List component="div" >
                <ListItem button onClick={this.handleClick}>
                    <ListItemIcon>
                        <ReactCountryFlag code={getCountryCodeFromEntryLang(this.props.entryLang)} svg />
                    </ListItemIcon>
                    <ListItemText primary={this.props.headString} secondary={this.state.open ? "" : this.props.wordDetails['translation']}/>
                    {
                        this.props.isAuthenticated && this.props.entryLang === 'sv'
                        && (
                            <div style={{position: 'relative', marginRight: 10}}>
                                <IconButton
                                    disabled={this.props.currentWordId !== null && this.props.currentWordId === this.props.wordId}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        this.handleAddNewWord()
                                    }}
                                    onFocus={(event) => event.stopPropagation()}>
                                    { this.props.isInNewWords ? <FormatAnnotationMinus /> : <FormatAnnotationPlus />}
                                </IconButton>
                                {this.props.currentWordId !== null && this.props.currentWordId === this.props.wordId
                                    && <CircularProgress size={40}
                                          style={{position: 'absolute',
                                              top: '50%',
                                              left: '50%',
                                              marginTop: -20,
                                              marginLeft: -20}}/>}
                            </div>
                        )
                    }
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit >
                    {Object.keys(this.props.wordDetails).map((key, i) =>
                        <EmbeddedList
                            key={i}
                            headString={this.props.headString}
                            entryKey={key}
                            entryDetails={this.props.wordDetails[key]}
                            entryLang={this.props.entryLang}
                            entryText={this.props.entryText}
                            entryClass={this.props.entryClass}
                            expandable={isEntryListExpandable(key)}
                            openNativeDialog={this.props.openNativeDialog}
                            dispatch={this.props.dispatch}
                            nativeLanguage={this.props.nativeLanguage}
                            openAlertDialog={this.props.openAlertDialog}
                            openSaolInflectionDialog={this.props.openSaolInflectionDialog}
                            setCurrentEntryText={this.props.setCurrentEntryText}
                            i18nStrings={this.props.i18nStrings['EmbeddedList']}
                            onOpenSelectNativeDialog={this.props.onOpenSelectNativeDialog}
                            textTranslateEngine={this.props.textTranslateEngine}
                        />
                    )}
                </Collapse>
            </List>
        );
    }
}

const mapStateToProps = state => ({
    currentWordId: state.currentWordId
})

const mapDispatchToProps = (dispatch) => ({
    startFirebaseAction: (currentWordId) => dispatch(startFirebaseAction(currentWordId))
});


export default connect(mapStateToProps, mapDispatchToProps)(NestedList)