import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper'
import {Translate} from "@material-ui/icons";
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import {appbarHeigh, drawerWidth} from "../ResponsiveDrawer"
import { Link } from 'react-router-dom'
import {getDayOfDate, isStringText} from "../../common/util";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from "@material-ui/core/Box";
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux'
import {
    startUpdateNewWord,
    showMessage,
    startRemoveNewWord,
    selectDataSource,
    MSAPI_REQUEST_TRANS_TYPE, GAPI_REQUEST_TRANS_TYPE, MSAPI_REQUEST_DICT_TYPE, lookupKey, fetchDetailsIfNeeded
} from "../../actions/actions";
import {getEntryTypeFullName} from "../small/DictionaryEntryList";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    CalendarMonthOutline,
    OrderAlphabeticalAscending,
    SortReverseVariant,
    OrderBoolAscendingVariant,
    FormatAnnotationMinus
} from "mdi-material-ui";
import IconButton from "@material-ui/core/IconButton";

const listHeadIcons = [CalendarMonthOutline, OrderAlphabeticalAscending, SortReverseVariant, OrderBoolAscendingVariant]


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,
            marginLeft: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 10,
            marginLeft: drawerWidth + 50
        },
        marginTop: appbarHeigh + 10
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    secondaryHeading: {
        marginLeft: 10,
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}))

function WordItem(props) {
    const classes = useStyles()

    const newWord = props.newWord
    const [isExpanded, setIsExpanded] = useState(props.isCardExpanded(newWord.wordId))

    const handleWordReviewChange = (selectedValue, wordId, successMessage) => {
        props.startUpdateNewWord(wordId, selectedValue, successMessage)
    };

    return (
        <Accordion expanded={isExpanded} onChange={(event, expanded) => {
            props.updateExpandedCardsSet(newWord.wordId, expanded)
            setIsExpanded(expanded)
        }}>
            <AccordionSummary style={{ display: 'flex', alignItems: 'center'}}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1}}>
                    <Typography className={classes.heading}>{newWord.entryText}</Typography>
                    <Typography className={classes.secondaryHeading}>{getWordSecondaryHeading(newWord, props.i18nStrings)}</Typography>
                </div>
                {
                    isExpanded
                    && <IconButton style={{marginRight: 10}}
                        onClick={(event) => {
                            event.stopPropagation()
                            if (props.currentWordId !== null && props.currentWordId === newWord.wordId) {
                                return  // is removing the new word
                            }
                            props.startRemoveNewWord(props.newWord.wordId, "\"" + newWord.entryText + "\"" + props.i18nStrings['removeNewWord'])
                        }}
                        onFocus={(event) => {
                            event.stopPropagation()
                        }}>
                        <FormatAnnotationMinus />
                    </IconButton>
                }
            </AccordionSummary>
            <div style={{position: 'relative'}}>
                <AccordionDetails style={{alignItems: 'flex-start'}}>
                    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1}}>
                        <Typography component="div" >
                            {renderWordDetails(props.wordDetails, props.i18nStrings)}
                        </Typography>

                    </div>
                    {
                        <IconButton style={{marginTop: -10}}
                            onClick={(event) => {
                                props.fetchNativeTranslation(newWord.entryText, props.wordDetails.translation, props.nativeLanguage)
                                props.onOpenNativeTranslationDialog(newWord.entryText, props.wordDetails.translation, 'en')
                            }}>
                            <Translate />
                        </IconButton>
                    }
                    {props.currentWordId !== null && props.currentWordId === newWord.wordId
                    && <CircularProgress size={48}
                                         style={{position: 'absolute',
                                             top: '50%',
                                             left: '50%',
                                             marginTop: -24,
                                             marginLeft: -24}}/>}
                </AccordionDetails>
            </div>
            <Divider />
            <AccordionActions style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="reviewstatus" name="status"
                                value={newWord.reviewStatus ? newWord.reviewStatus : '3'}
                                onChange={(event) => {
                                    const selectedValue = event.target.value
                                    let message = props.i18nStrings['reviewStatusUpdating'].replace('%s', newWord.entryText)
                                    message += getReviewStatusStringFromValue(selectedValue, props.i18nStrings)
                                    handleWordReviewChange(selectedValue, newWord.wordId, message)
                                }} row>
                        <FormControlLabel value={'0'} control={<Radio />} label={props.i18nStrings['forgetStatus']} disabled={props.currentWordId !== null && props.currentWordId === newWord.wordId}/>
                        <FormControlLabel value={'1'} control={<Radio />} label={props.i18nStrings['knowStatus']} disabled={props.currentWordId !== null && props.currentWordId === newWord.wordId}/>
                        <FormControlLabel value={'2'} control={<Radio />} label={props.i18nStrings['rememberStatus']} disabled={props.currentWordId !== null && props.currentWordId === newWord.wordId}/>
                    </RadioGroup>
                </FormControl>
            </AccordionActions>
        </Accordion>
    )
}

function WordGroup(props) {
    const [open, setOpen] = useState(props.isGroupExpanded(props.groupName))
    const handleClick = (index) => {
        props.updateExpandedGroupsSet(props.groupName, !open)
        setOpen(!open);
    }

    const getNumOfItems = () => {
        let num = 0
        for ( let i = props.startIndex; i < props.endIndex; i++ ) {
            if ( !props.isShowingRememberWords && props.newWords[i].reviewStatus === '2' ) {
                continue
            }
            num++
        }

        return num
    }

    const renderWordItems = () => {
        let items = []
        for ( let i = props.startIndex; i < props.endIndex; i++) {
            if ( !props.isShowingRememberWords && props.newWords[i].reviewStatus === '2' ) {
                continue
            }

            const newWord = props.newWords[i]
            const wordDetails = JSON.parse(newWord.wordDetails)
            items.push(
                <WordItem key={newWord.wordId}
                    newWord={newWord}
                    i18nStrings={props.i18nStrings}
                    currentWordId={props.currentWordId}
                    startUpdateNewWord={props.startUpdateNewWord}
                    wordDetails={wordDetails}
                    startRemoveNewWord={props.startRemoveNewWord}
                    updateExpandedCardsSet={props.updateExpandedCardsSet}
                    isCardExpanded={props.isCardExpanded}
                    onOpenNativeTranslationDialog={props.onOpenNativeTranslationDialog}
                    nativeLanguage={props.nativeLanguage}
                    fetchNativeTranslation={props.fetchNativeTranslation}
                />
            )
        }

        return items
    }

    return (
        <List aria-label="word groups" dense={true} >
            <ListItem button key={props.groupName} onClick={() => handleClick(props.index)}>
                <ListItemIcon>
                    {
                        (() => {
                                const ItemIcon = listHeadIcons[props.newWordsSortType]
                                return <ItemIcon />
                            }
                        )()
                    }

                </ListItemIcon>
                <ListItemText primary={props.groupName + ' (' + getNumOfItems() + ')'} disableTypography style={{fontWeight: 600}}/>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{textAlign: 'left'}}>
                    {
                        renderWordItems()
                    }
                </div>
            </Collapse>
        </List>
    )
}

const getWordSecondaryHeading = (word, i18nStrings) => {
    let secondaryHead = ''
    if (word.entryClass !== null && word.entryClass !== '') {
        secondaryHead += getEntryTypeFullName(i18nStrings, word.entryClass, false)
    }

    if (word.entryComment !== null && word.entryComment !== '') {
        secondaryHead += (' (' + word.entryComment + ')')
    }

    return secondaryHead
}

const renderWordDetails = (wordDetails, i18nStrings) => {
    return Object.keys(wordDetails).map((key, i) =>
        <Typography component="div" key={key}>
            {getWordDetailsItem(key, wordDetails, i18nStrings)}
        </Typography>
    )
}

const getWordDetailsItem = (itemKey, wordDetails, i18nStrings) => {
    switch (itemKey) {
        case 'translation':
            return renderSimpleItem(i18nStrings['enDefinitionText'], wordDetails.translation)
        case 'synonym':
            return renderSimpleItem(i18nStrings['synonymText'], wordDetails.synonym)
        case 'definition':
            return renderSimpleItem(i18nStrings['svDefinitionText'], wordDetails.definition)
        case 'paradigm':
            return renderSimpleItem(i18nStrings['inflectionsText'], wordDetails.paradigm)
        case 'phonetic':
            return renderSimpleItem(i18nStrings['pronunciationText'], '[' + wordDetails.phonetic['value'] + ']')
        case 'explanation':
            return renderSimpleItem(i18nStrings['explanationText'], wordDetails.explanation)
        case 'examples': // here we combine all example nodes to a new "examples"/"idioms" field
            return renderComplexItem(i18nStrings['examplesText'], wordDetails.examples)
        case 'idioms':
            return renderComplexItem(i18nStrings['idiomsText'], wordDetails.idioms)
        case 'compounds':
            return renderComplexItem(i18nStrings['compoundsText'], wordDetails.compounds)
        default:
            return renderSimpleItem('', '')
    }
}

const renderSimpleItem = (head, secondary) => {
    return (
        <Box display="flex" fontSize="fontSize">
            <Box fontWeight="fontWeightBold" >
                {head}
            </Box>
            <Box fontWeight="fontWeightRegular" marginLeft={1}>
                {secondary}
            </Box>
        </Box>
    )
}

const renderComplexItem = (head, infoList) => {
    return (
        <Box fontSize="fontSize">
            <Box fontWeight="fontWeightBold" >
                {head}
            </Box>
            {
                infoList.map((info) => {
                    return (
                        <Box fontWeight="fontWeightRegular" key={info.value + info.translation}>
                            •	{info.value}
                            <Box fontWeight="fontWeightLight" component="span">
                                {' (' + info.translation + ')'}
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

// the index to sort word classes
const getWordClassIndex = (entryClass) => {
    switch (entryClass) {
        case 'nn':
            return 0
        case 'vb':
            return 1
        case 'jj':
            return 2
        case 'ab':
            return 3
        case 'pp':
            return 4
        case 'pn':
            return 5
        case 'kn':
            return 6
        case 'in':
            return 7
        case 'rg':
            return 8
        case 'abbrev':
            return 9
        default:
            return 10
    }
}

const getReviewStatusStringFromValue = (value, i18nStrings) => {
    if (value === '0') {
        return i18nStrings['forgetStatus'].toUpperCase()
    } else if (value === '1') {
        return i18nStrings['knowStatus'].toUpperCase()
    } else if (value === '2') {
        return  i18nStrings['rememberStatus'].toUpperCase()
    } else {
        return i18nStrings['notReviewedStatus'].toUpperCase()
    }
}

const expandedGroupsSet = new Set()
const expandedCardsSet = new Set()

function NewWordsPage(props) {
    const classes = useStyles()

    const updateExpandedCardsSet = (wordId, status) => {
        if (status) {
            expandedCardsSet.add(wordId)
        } else {
            expandedCardsSet.delete(wordId)
        }
    }

    const updateExpandedGroupsSet = (groupName, status) => {
        if (status) {   // true: add a newly expanded group; false: the group is closed
            expandedGroupsSet.add(groupName)
        } else {
            expandedGroupsSet.delete(groupName)
        }
    }

    const isGroupExpanded = (groupName) => {
        return expandedGroupsSet.has(groupName)
    }

    const isCardExpanded = (wordId) => {
        return expandedCardsSet.has(wordId)
    }

    const sortNewWordsByDate = () => {
        props.newWords.sort((firstEl, secondEl) => {
            const date1 = firstEl.addedTime.toDate()
            const date2 = secondEl.addedTime.toDate()
            if ( date1 < date2 ) {
                return -1
            }
            if ( date1 > date2 ) {
                return 1
            }
            return 0
        })
    }

    const sortNewWordsByAlpha = () => {
        props.newWords.sort((firstEl, secondEl) => {
            const entryText1 = firstEl.entryText
            const entryText2 = secondEl.entryText
            if ( entryText1 < entryText2 ) {
                return -1
            }
            if ( entryText1 > entryText2 ) {
                return 1
            }
            return 0
        })
    }

    const sortNewWordsByClass = () => {
        props.newWords.sort((firstEl, secondEl) => {
            const entryClass1 = firstEl.entryClass
            const entryClass2 = secondEl.entryClass
            if ( entryClass1 === null || entryClass1 === '') {
                return 1
            }

            if ( entryClass2 === null || entryClass2 === '') {
                return -1
            }

            const index1 = getWordClassIndex(entryClass1)
            const index2 = getWordClassIndex(entryClass2)

            if ( index1 < index2 ) {
                return -1
            }
            if ( index1 > index2 ) {
                return 1
            }
            return 0
        })
    }

    const sortNewWordsByReviewStatus = () => {
        props.newWords.sort((firstEl, secondEl) => {
            const status1 = firstEl.reviewStatus
            const status2 = secondEl.reviewStatus
            if ( status1 < status2 ) {
                return -1
            }
            if ( status1 > status2 ) {
                return 1
            }
            return 0
        })
    }

    const sortNewWords = () => {
        switch (props.newWordsSortType) {
            case 0:
                sortNewWordsByDate()
                break
            case 1:
                sortNewWordsByAlpha()
                break
            case 2:
                sortNewWordsByClass()
                break
            case 3:
                sortNewWordsByReviewStatus()
                break
            default:
                break
        }
    }

    const getSortIndexOfWord = (word) => {
        switch (props.newWordsSortType) {
            case 0:
                return getDayOfDate(word.addedTime.toDate())
            case 1:
                return word.entryText.substring(0, 1).toUpperCase()
            case 2:
                const classFullName = getEntryTypeFullName(props.i18nStrings, word.entryClass, true)
                if (classFullName !== '') {
                    return classFullName.toUpperCase()
                } else {
                    return 'N/A'
                }
            case 3:
                return getReviewStatusStringFromValue(word.reviewStatus, props.i18nStrings)
            default:
                break
        }
    }

    const generatePageHead = () => {
        const length = props.newWords.length
        let numOfRemember = 0;
        let numOfKnow = 0;
        let numOfForget = 0;
        let numOfNotReviewed = 0;
        props.newWords.forEach((newWord) => {
                if (newWord.reviewStatus) {
                    switch (newWord.reviewStatus) {
                        case "0":
                            numOfForget++
                            break
                        case "1":
                            numOfKnow++
                            break
                        case "2":
                            numOfRemember++
                            break
                        case "3":
                            numOfNotReviewed++
                            break
                        default:
                            break
                    }
                } else {
                    numOfForget++
                }
            }
        )
        const headText = props.i18nStrings['headText'].replace('%s', length.toString())
        const secondaryText = props.i18nStrings['secondaryText']
                                    .replace('%r', numOfRemember.toString())
                                    .replace('%k', numOfKnow.toString())
                                    .replace('%f', numOfForget.toString())
                                    .replace('%n', numOfNotReviewed.toString())

        return (
            <Box mt={2} key={"header"} >
                <Paper elevation={3} >
                    <Typography  variant="subtitle1" style={{textAlign: 'left', marginLeft: 5, fontWeight: 600, fontStyle: 'italic'}}>
                        {headText}
                    </Typography>
                    <Typography  variant="subtitle2" style={{textAlign: 'left', marginLeft: 10, fontWeight: 600, fontStyle: 'italic'}}>
                        {secondaryText}
                    </Typography>
                </Paper>
            </Box>
        )
    }

    const renderWordGroups = () => {
        const length = props.newWords.length
        if ( length === 0 ) {
            return (
                <Box mt={2} >
                    <Typography variant="h6" style={{textAlign: 'left'}}>
                        {props.i18nStrings['landingText1']}A<sup>+</sup>{props.i18nStrings['landingText2']}A<sup>-</sup>
                    </Typography>
                </Box>
            )
        }

        sortNewWords()

        let groups = []
        // the head of the new words page (showing statistics)
        groups.push(
            generatePageHead()
        )

        for ( let i = 0; i < length; ) {
            if ( !props.isShowingRememberWords && props.newWords[i].reviewStatus === '2' ){
                i++
                continue
            }

            let currentIndex = getSortIndexOfWord(props.newWords[i])
            let j
            for (j = i + 1; j < length; j++) {
                if ( getSortIndexOfWord(props.newWords[j]) !== currentIndex ) {
                    break
                }
            }

            groups.push(
                <WordGroup
                    key={currentIndex}
                    groupName={currentIndex}
                    newWords={props.newWords}
                    startIndex={i} endIndex={j}
                    i18nStrings={props.i18nStrings}
                    startUpdateNewWord={props.startUpdateNewWord}
                    newWordsSortType={props.newWordsSortType}
                    isShowingRememberWords={props.isShowingRememberWords}
                    currentWordId={props.currentWordId}
                    showMessage={props.showMessage}
                    startRemoveNewWord={props.startRemoveNewWord}
                    updateExpandedGroupsSet={updateExpandedGroupsSet}
                    isGroupExpanded={isGroupExpanded}
                    updateExpandedCardsSet={updateExpandedCardsSet}
                    isCardExpanded={isCardExpanded}
                    onOpenNativeTranslationDialog={props.onOpenNativeTranslationDialog}
                    nativeLanguage={props.nativeLanguage}
                    fetchNativeTranslation={props.fetchNativeTranslation}
                    index={groups.length} />
            )

            if ( j >= length ) {
                break
            } else {
                i = j
            }
        }

        return groups
    }

    if ( props.isLoading ) {
        return (
            <Box mt={2} className={classes.root} >
                <div style={{position: 'relative'}}>
                    <CircularProgress size={60}
                                      style={{position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          marginTop: 30,
                                          marginLeft: -30}}/>
                </div>
            </Box>
        )
    } else {
        return (
            <div className={classes.root}>
                {
                    props.isAuthenticated ?
                        (
                            renderWordGroups()
                        )
                        :
                        (
                            <Box mt={2}>
                                <Typography variant="h6">
                                    {props.i18nStrings['askSinginText']}<Link to="/signin">{props.i18nStrings['signinText']}</Link>
                                </Typography>
                            </Box>
                        )
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: !!state.authStatus.uid,
    newWords: state.newWordsStatus,
    isLoading: state.loadingNewWords, // if the page has rendered (if not, that is the first loading. Just showing some loading ui until new words are loaded)
    newWordsSortType: state.newWordsSortType,
    isShowingRememberWords: state.isShowingRememberWords,
    currentWordId: state.currentWordId,
    nativeLanguage: state.nativeLanguage
})

const mapDispatchToProps = (dispatch) => ({
    startUpdateNewWord: (wordId, newReviewStatus, successMessage) => dispatch(startUpdateNewWord(wordId, newReviewStatus, successMessage)),
    startRemoveNewWord: (wordId, successMessage) => dispatch(startRemoveNewWord(wordId, successMessage)),
    showMessage: (message, showTime) => dispatch(showMessage(message, showTime, 2)),
    fetchNativeTranslation: (entryText, translation, nativeLang) => {
        const isText = isStringText(translation)
        const transEngine = isText ? 'google' : 'ms'
        let apiDataSource = transEngine + 'Api'
        dispatch(selectDataSource(apiDataSource))

        let textTransType = transEngine === 'ms' ? MSAPI_REQUEST_TRANS_TYPE : GAPI_REQUEST_TRANS_TYPE
        const apiRequestType = ( isText ? textTransType : MSAPI_REQUEST_DICT_TYPE)
        dispatch(lookupKey(apiDataSource, apiRequestType, translation, 'en'))
        dispatch(fetchDetailsIfNeeded(apiDataSource, apiRequestType, translation, 'en', nativeLang))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewWordsPage)
