import React from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import PropTypes from "prop-types"
import {getLanguageNameFromCode} from '../../i18n/i18ncommon'

const ENGLISH_NAME_TEXT_ID = 'englishName'
const SWEDISH_NAME_TEXT_ID = 'swedishName'

export default function TranslateLanguageSelect(props) {
    const { nativeLanguage, selectedLang } = props

    const handleChange = (event) => {
        let newLang = event.target.value
        props.onSelectLang(newLang)
    }

    return (
        <NativeSelect
            value={selectedLang}
            onChange={handleChange}
        >
            {
                nativeLanguage !== 'noNeed' && nativeLanguage !== ''
                && (
                    <option value={nativeLanguage}>{getLanguageNameFromCode(nativeLanguage)}</option>
                )
            }
            <option value={'sv'}>{props.i18nStrings[SWEDISH_NAME_TEXT_ID]}</option>
            <option value={'en'}>{props.i18nStrings[ENGLISH_NAME_TEXT_ID]}</option>
        </NativeSelect>
    )
}

TranslateLanguageSelect.propTypes = {
    nativeLanguage: PropTypes.string.isRequired,
    selectedLang: PropTypes.string.isRequired,
    onSelectLang: PropTypes.func.isRequired,
    i18nStrings: PropTypes.object.isRequired,
}

