import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Translate, AccountCircle} from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {CloudPrintOutline, BookSearchOutline, NewspaperVariantOutline, CogOutline,
    GamepadVariantOutline, SortAlphabeticalAscending, SortCalendarAscending, SortBoolAscendingVariant,
    SortAscending, AccountTieOutline, EyeOutline, EyeOffOutline, CloudSyncOutline, CalendarCheckOutline } from "mdi-material-ui";
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../images/logo.png'
import { pageName } from "../containers/App"
import Button from '@material-ui/core/Button'
import {
    startLogout,
    setNewWordsSortType,
    showRememberWords,
    hideRememberWords,
    startNewWordsLoading,
    startSetNewWords,
    cleanNewWords
} from "../actions/actions";
import {history} from "../containers/App"
import {database} from "../firebase/firebase";


export const drawerWidth = 240;
export const appbarHeigh = 64;

const DICT_ITEM_TEXT_ID = 'dictItemText'
const TRANS_ITEM_TEXT_ID = 'transItemText'
const NEWWORD_ITEM_TEXT_ID = 'newWordItemText'
const SETTINGS_ITEM_TEXT_ID = 'settingsItemText'
const NEWS_ITEM_TEXT_ID = 'newsItemText'
const TIP_ITEM_TEXT_ID = 'tipOfTheDayItemText'
const SPECIALTY_SWEDISH_TEXT_ID = 'specialtySwedish'
const GAME_QUIZ_TEXT_ID = 'gameAndTestItemText'
const LOGIN_ITEM_TEXT_ID = 'loginItemText'


const styles = theme => ({
    root: {
        display: 'flex',
        //flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            height: appbarHeigh
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
    },
});

const iconComponentsMain = [BookSearchOutline, Translate, CloudPrintOutline, CogOutline]
const iconComponentsOther = [CalendarCheckOutline, NewspaperVariantOutline, AccountTieOutline, GamepadVariantOutline]

const mobileMenuItemNames = ['sortByDateItemText', 'sortByAlphaItemText', 'sortByClassItemText', 'sortByReviewStatusItemText']
const mobileMenuIcons = [SortCalendarAscending, SortAlphabeticalAscending, SortAscending, SortBoolAscendingVariant]

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        //elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

class ResponsiveDrawer extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,

        nativeLanguage: PropTypes.string.isRequired,

        i18nStrings: PropTypes.object.isRequired,
    }

    constructor (props) {
        super(props)

        this.state = {
            mobileOpen: false,
            appBarMenuAnchorEl: null,    // anchor for the popup menu
            vertMoreAnchorEl: null     // anchor for the vert more menu
        }
    }

    handleDrawerToggle = () => {
        this.setState(state => ({
            ...state,
            mobileOpen: !state.mobileOpen
        }));
    }

    handleDrawerItemClick = (i18nStrings, text) => {
        let pageType = this.getPageNameFromItemText(i18nStrings, text)
        history.push("/" + pageType.description)

        this.setState(state => ({
            ...state,
            mobileOpen: false
        }));
    }

    handleAppBarMenu = (event) => {
        let element = event.currentTarget
        this.setState( state => ({
            ...state,
            appBarMenuAnchorEl: element
        }))
    }

    handleVertMoreMenu = (event) => {
        let element = event.currentTarget
        this.setState( state => ({
            ...state,
            vertMoreAnchorEl: element
        }))
    }

    closeAppBarMenu = () => {
        this.setState( state => ({
            ...state,
            appBarMenuAnchorEl: null
        }))
    }

    closeVertMoreMenu = () => {
        this.setState( state => ({
            ...state,
            vertMoreAnchorEl: null
        }))
    }

    getPageNameFromItemText = (i18nStrings, text) => {
        switch (text) {
            case i18nStrings[DICT_ITEM_TEXT_ID]:
                return pageName.DICTIONARY
            case i18nStrings[TRANS_ITEM_TEXT_ID]:
                return pageName.TRANSLATE
            case i18nStrings[NEWWORD_ITEM_TEXT_ID]:
                return pageName.NEW_WORDS
            case i18nStrings[SETTINGS_ITEM_TEXT_ID]:
                return pageName.SETTINGS
            case i18nStrings['dailyTasksItemText']:
                return pageName.DAILY_TASK
            case i18nStrings[NEWS_ITEM_TEXT_ID]:
                return pageName.NEWS
            case i18nStrings[TIP_ITEM_TEXT_ID]:
                return pageName.TIPS
            case i18nStrings[SPECIALTY_SWEDISH_TEXT_ID]:
                return pageName.SPECIALTY
            case i18nStrings[GAME_QUIZ_TEXT_ID]:
                return pageName.QUIZ_GAME
            case i18nStrings[LOGIN_ITEM_TEXT_ID]:
                return pageName.LOGIN
            default:
                return null
        }
    }

    generateMenuItem = (i18nStrings, itemStrings, iconComponents) => {
        return (
            <List>
                {
                    itemStrings.map((text, i) => (
                        <ListItem button key={i} >
                            <ListItemIcon>{
                                (() => {
                                    let DrawerIcon = iconComponents[i]
                                    return <DrawerIcon />
                                })()

                            }</ListItemIcon>
                            <ListItemText primary={text} onClick={() => this.handleDrawerItemClick(i18nStrings, text)}/>
                        </ListItem>
                    ))}
            </List>
        );
    }

    render() {
        const { classes, theme, i18nStrings } = this.props

        const drawer = (
            <div>
                <div className={classes.toolbar} style={{ display: 'flex', alignItems: 'center'}} >
                    <img style={{marginLeft: 20}} src={logo} alt="Logo" width={64} height={64}/>
                    <Typography variant="h5" color="primary" noWrap >
                        Plugghäst
                    </Typography>

                </div>
                <Divider />
                {this.generateMenuItem(i18nStrings,[i18nStrings[DICT_ITEM_TEXT_ID],
                                                    i18nStrings[TRANS_ITEM_TEXT_ID],
                                                    i18nStrings[NEWWORD_ITEM_TEXT_ID],
                                                    i18nStrings[SETTINGS_ITEM_TEXT_ID]], iconComponentsMain)}
                <Divider />
                {this.generateMenuItem(i18nStrings,[i18nStrings['dailyTasksItemText'],
                                                    i18nStrings[NEWS_ITEM_TEXT_ID],
                                                    i18nStrings[SPECIALTY_SWEDISH_TEXT_ID],
                                                    i18nStrings[GAME_QUIZ_TEXT_ID]], iconComponentsOther)}
            </div>
        );

        const mobileMenuId = 'primary-menu-mobile';
        const renderMobileMenu = (
            <StyledMenu
                anchorEl={this.state.vertMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(this.state.vertMoreAnchorEl)}
                onClose={this.closeVertMoreMenu}
            >
                {mobileMenuItemNames.map((itemName, i) => {
                    const ItemIcon = mobileMenuIcons[i]
                    return <StyledMenuItem key={itemName}
                                           selected={this.props.newWordsSortType === i}
                                           onClick={() => {
                                               this.props.setNewWordsSortType(i)
                                               this.closeVertMoreMenu()
                                            }}>
                                <ListItemIcon>
                                    <ItemIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText primary={i18nStrings[itemName]} />
                            </StyledMenuItem>
                })}
                <Divider />
                {
                    (() => {
                        const ItemIcon = this.props.isShowingRememberWords ? EyeOffOutline : EyeOutline
                        const itemText = this.props.isShowingRememberWords ? i18nStrings['hideRemembersItemText'] : i18nStrings['showRemembersItemText']
                        return (
                            <StyledMenuItem onClick={() => {
                                                if (this.props.isShowingRememberWords) {
                                                    this.props.hideRememberWords()
                                                } else {
                                                    this.props.showRememberWords()
                                                }
                                                this.closeVertMoreMenu()
                                            }}>
                                <ListItemIcon >
                                    <ItemIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText primary={itemText} />
                            </StyledMenuItem>
                        )
                    })()
                }
            </StyledMenu>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" noWrap id="header" align="left" className={classes.title}>
                            {''}
                        </Typography>
                        {   // the more button for new words page
                            (history.location.pathname === '/new_words' && this.props.isAuthenticated)
                            &&
                            (
                                <div >
                                    <IconButton
                                        aria-label="show more"
                                        aria-controls={mobileMenuId}
                                        aria-haspopup="true"
                                        onClick={this.handleVertMoreMenu}
                                        color="inherit"
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                </div>
                            )
                        }
                        {   // sync with cloud button
                            this.props.isAuthenticated
                            && <div style={{position: 'relative'}}>
                                <IconButton
                                    aria-label="sync with cloud"
                                    aria-haspopup="true"
                                    onClick={() => {
                                        if (this.props.isLoadingNewWords) {
                                            return
                                        }

                                        this.props.startNewWordsLoading()
                                        this.props.cleanNewWords()
                                        const userRef = database.collection('users').doc(this.props.uid);
                                        this.props.startSetNewWords(userRef)
                                    }}
                                    color="inherit"
                                >
                                    <CloudSyncOutline />
                                </IconButton>
                                {
                                    this.props.isLoadingNewWords
                                    && <CircularProgress size={40} color="inherit"
                                                     style={{position: 'absolute',
                                                         top: '50%',
                                                         left: '50%',
                                                         marginTop: -20,
                                                         marginLeft: -20}}/>
                                }
                            </div>
                        }
                        {   // log in button
                            this.props.isAuthenticated ?
                                <div>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={this.handleAppBarMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={this.state.appBarMenuAnchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(this.state.appBarMenuAnchorEl)}
                                        onClose={this.closeAppBarMenu}
                                    >
                                        <MenuItem >{this.props.userEmail}</MenuItem>
                                        <MenuItem onClick={() => {
                                            this.closeAppBarMenu()
                                            this.props.logout()
                                        }}>{i18nStrings['logoutItemText']}</MenuItem>

                                    </Menu>
                                </div>
                                :
                                <Button color="inherit" onClick={() => {
                                    history.push("/" + pageName.SIGNIN.description)
                                }}>{i18nStrings[LOGIN_ITEM_TEXT_ID]}</Button>
                        }
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: !!state.authStatus.uid,
    userEmail: state.authStatus.email,
    uid:state.authStatus.uid,
    newWordsSortType: state.newWordsSortType,
    isShowingRememberWords: state.isShowingRememberWords,
    isLoadingNewWords: state.loadingNewWords
})

const mapDispatchToProps = (dispatch) => ({
    setNewWordsSortType: (sortType) => dispatch(setNewWordsSortType(sortType)),
    showRememberWords: () => dispatch(showRememberWords()),
    hideRememberWords: () => dispatch(hideRememberWords()),
    logout: () => dispatch(startLogout()),
    startNewWordsLoading: () => dispatch(startNewWordsLoading()),
    startSetNewWords: (userRef) => dispatch(startSetNewWords(userRef)),
    cleanNewWords: () => dispatch(cleanNewWords())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ResponsiveDrawer));