import React from 'react'
import {connect} from "react-redux"
import PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import {getEntryTypeFullName} from "../small/DictionaryEntryList";

const SAOL_DIALOG_TITLE_ID = 'saolDialogTitle'
const NO_RESULT_TEXT_ID = 'noResultsText'
const CLOSE_BUTTON_TEXT_ID = 'closeButtonText'

class SaolInflectionDialog extends React.Component {
    static propTypes = {
        keyWord: PropTypes.string.isRequired,
        wordClass: PropTypes.string.isRequired,
        htmlString: PropTypes.string.isRequired,
        isFetching: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        i18nStrings: PropTypes.object.isRequired
    }

    handleClose = () => {
        this.props.onClose()
    }

    render() {
        // we list dispatch here just to avoid passing it to the component's child nodes
        // passing it will cause a warning of "Invalid value for prop `dispatch` on <div>"
        const { keyWord, wordClass, isFetching, htmlString, dispatch, i18nStrings, ...other } = this.props

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle onClick={() => this.handleClose()} id="simple-dialog-title">{keyWord + (wordClass === '' ? '' : (' (' + getEntryTypeFullName(i18nStrings, wordClass, false) + ') '))}{i18nStrings[SAOL_DIALOG_TITLE_ID]}</DialogTitle>
                <div onClick={() => this.handleClose()}>
                    {
                        isFetching
                        ? (<CircularProgress size={68} color="primary" />)
                        : (<div dangerouslySetInnerHTML={{ __html: htmlString !== '' ? htmlString : i18nStrings[NO_RESULT_TEXT_ID] }} />)
                    }
                </div>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" style={{textTransform: 'none'}}>
                        {i18nStrings[CLOSE_BUTTON_TEXT_ID]}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    const { dataBySource } = state

    let isFetching = false
    let htmlString = ''
    let presentIndex

    if (dataBySource['saol'] && dataBySource['saol']['inflection']) {
        isFetching = dataBySource['saol']['inflection'].isFetching
        if (!isFetching) {
            presentIndex = dataBySource['saol']['inflection'].presentIndex
            if (presentIndex >= 0) {
                let details = dataBySource['saol']['inflection'].history[presentIndex].details
                htmlString = typeof details === 'string' ? details : ''
            }
        }
    }

    return {
        isFetching,
        htmlString,
    }
}

export default connect(mapStateToProps)(SaolInflectionDialog)