import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {startEmailSignup} from "../../actions/actions";
import {LOCAL_STORAGE_KEY_NAME, saveStringToLocalStorage, loadStringFromLocalStorage} from "../../common/util";
import {appbarHeigh, drawerWidth} from "../ResponsiveDrawer"


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,
            marginLeft: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 10,
            marginLeft: drawerWidth + 50
        },
        marginTop: appbarHeigh + 10
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignUpPage({startEmailSignup, i18nStrings}) {
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const savedEmail = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.USER_EMAIL_INPUT.description)
        if (savedEmail !== '') {
            setEmail(savedEmail)
        }
    })

    const onSubmit = event => {
        startEmailSignup(email, password)
        event.preventDefault()
    }
    return (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <CssBaseline />
            <div className={classes.paper}>
                <Box mt={2}>
                    <Typography component="h1" variant="h5">
                        {i18nStrings['headText']}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label={i18nStrings['emailLabel']}
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={event =>  {
                                    const email = event.target.value
                                    setEmail(email)
                                    saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.USER_EMAIL_INPUT.description, email)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label={i18nStrings['passwordLabel']}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={event => setPassword(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {i18nStrings['signupText']}
                    </Button>
                    <Grid container >
                        <Grid item xs style={{textAlign: 'left'}}>
                            <Link to="/signin">
                                {i18nStrings['signinText']}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}

const mapDispatchToProps = (dispatch) => ({
    startEmailSignup: (email, password) => dispatch(startEmailSignup(email, password)),
});

export default connect(undefined, mapDispatchToProps)(SignUpPage)