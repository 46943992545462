/*
    here we define the utilities
 */

// sometimes the text will contain some 'irregular' word, for example abbreviation
// (like eller -> el.), which will cause some strange translation
export function polishText(srcLang, text) {
    if (srcLang === 'sv') {
        return text.replace(/el\./g, 'eller')
    }
}

export function isStringText(strInput) {
    const str = strInput.trim()
    return str.includes(',') || str.includes(' ')
}

export function isObjectEmpty(obj) {
    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const timeoutableFetch = (url, options = {}) => {
    let { timeout = 5000, ...rest } = options;
    if (rest.signal) throw new Error("Signal not supported in timeoutable fetch");
    const controller = new AbortController();
    const { signal } = controller;
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            reject(new Error("Time out"));
            controller.abort();
        }, timeout);
        fetch(url, { signal, ...rest })
            .finally(() => clearTimeout(timer))
            .then(resolve, reject);
    })
}

export const copyTextToClipboard = (text) => {
    let textarea = document.createElement('textarea');
    textarea.textContent = text;
    document.body.appendChild(textarea);

    let selection = document.getSelection();
    let range = document.createRange();
    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);

    // !!!NOTE: do not remove this log output
    console.log('copy success', document.execCommand('copy'));
    selection.removeAllRanges();

    document.body.removeChild(textarea);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const LOCAL_STORAGE_KEY_NAME = {
    NATIVE_LANGUAGE: Symbol('plugghastNativeLanguage'),
    UI_LANGUAGE: Symbol('plugghastUiLanguage'),
    TEXT_TRANS_ENGINE: Symbol('plugghastTextTransEngine'),
    LAST_LOOKUP: Symbol('plugghastLastLookup'),
    LAST_INPUT_DICTIONARY: Symbol('plugghastLastInputDictionary'),
    LAST_TRANSLATE: Symbol('plugghastLastTranslate'),
    LAST_INPUT_TRANSLATE: Symbol('plugghastLastInputTranslate'),
    LAST_FROM_LANGUAGE: Symbol('plugghastLastFromLanguage'),
    LAST_TO_LANGUAGE: Symbol('plugghastLastToLanguage'),
    USER_EMAIL_INPUT: Symbol('plugghastUserEmailInput')
}

export function saveStringToLocalStorage(keyName, keyValue) {
    localStorage.setItem(keyName, keyValue)
}

export function loadStringFromLocalStorage(keyName) {
    const keyValue = localStorage.getItem(keyName)
    return keyValue ? keyValue : ''
}

// parseString('test %s %s me', 'abc', 'bcd') will return 'test abc bcd me'
export function parseString(str) {
    let args = [].slice.call(arguments, 1), i = 0;

    return str.replace(/%s/g, () => args[i++]);
}

export function getDayOfDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();

    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return day + '-' + month + '-' + year
}

