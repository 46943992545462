import React, { Component } from 'react'
import '../css/App.css';
import ResponsiveDrawer from '../components/ResponsiveDrawer'
import PageContainer from "./PageContainer"
import {connect} from "react-redux"
import PropTypes from "prop-types"
import LoadingOverlay from 'react-loading-overlay'
import {
    setNativeLanguage,
    setUiLanguage,
    cleanHistoryData,
    DATA_SOURCE_MSAPI,
    showRequestFailure,
    setTextTranslateEngine,
    DATA_SOURCE_GAPI
} from "../actions/actions"
import { getI18nStrings, getPossibleNativeLangFromBrowserLang,getPossibleUiLangFromBrowserLang } from "../i18n/i18ncommon"
import SelectLanguageDialog from '../components/dialogs/SelectLanguageDialog'
import {saveStringToLocalStorage, loadStringFromLocalStorage, isObjectEmpty, LOCAL_STORAGE_KEY_NAME} from '../common/util'
import AlertDialog from '../components/dialogs/AlertDialog'

import nyheter from '../images/nyheter.jpg'
import quiz from '../images/quiz.png'
import specialty from '../images/specialty.jpg'
import task from '../images/task.png'
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";

export const history = createBrowserHistory()

const LOADING_LOOKUP_TEXT_ID = 'loadingLookupText'
const LOADING_SUGGESTIONS_TEXT_ID = 'loadingSuggestionText'
const LOADING_TRANS_PAGE_TEXT_ID = 'loadingTransPageText'

const ALERT_NETWORK_ISSUE_TITLE_TEXT_ID = 'alertNetworkIssueTitle'
const ALERT_NETWORK_TIMEOUT_TEXT_ID = 'alertNetworkTimeoutText'
const ALERT_NETWORK_UNAVAILABLE_TEXT_ID = 'alertNetworkUnavailableText'
const ALERT_NETWORK_ISSUE_TEXT_ID = 'alertNetworkIssueText'
const CLOSE_BUTTON_TEXT_ID = 'closeButtonText'

// New design for the pages:
// 1. save the current page name in App
// 2. PageContainer contains all the pages
// 3. when users click an item in navigation drawer, the current page name will be changed
// 4. PageContainer will render the current page
export const pageName = {
    DICTIONARY: Symbol('dictionary'),
    TRANSLATE: Symbol('translate'),
    NEW_WORDS: Symbol('new_words'),
    SETTINGS: Symbol('settings'),
    DAILY_TASK: Symbol('daily_task'),
    NEWS: Symbol('news'),
    SPECIALTY: Symbol('specialty'),
    QUIZ_GAME: Symbol('quiz_game'),
    TIPS: Symbol('tips'),
    LOGIN: Symbol('login'),
    SIGNIN: Symbol('signin'),
    SIGNUP: Symbol('signup')
}

export const PAGE_INFORMATION = {
    news: {
        headImage: nyheter
    },
    specialty: {
        headImage: specialty
    },
    quiz_game: {
        headImage: quiz
    },
    daily_task: {
        headImage: task
    }
}

// different alert dialog type (used to differentiate the parameters of openAlertDialog)
export const ALERT_TYPE = {
    NORMAL: Symbol('normal'),   // one parameter followed: network error code
    NETWORK: Symbol('network')  // parameters followed: alert dialog title, description and actions
}


class App extends Component {
    static propTypes = {
        isFetchingDicLookup: PropTypes.bool.isRequired,
        isFetchingDictSuggestions: PropTypes.bool.isRequired,
        nativeLanguage: PropTypes.string.isRequired,
        uiLanguage: PropTypes.string.isRequired,
        textTranslateEngine: PropTypes.string.isRequired,
        currentRequestStatus: PropTypes.object.isRequired,
        dataSource: PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)
        this.state = {
            openSelectLangDialog: false,
            selectLangDialogHook: null,

            openAlertDialog: false,
            alertTitle: '',
            alertText: '',
            dialogActions: [],
        }
    }

    componentDidMount() {
        let savedNativeLang = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.NATIVE_LANGUAGE.description)
        if (savedNativeLang !== '') {
            this.props.dispatch(setNativeLanguage(savedNativeLang))
        } else {
            let possibleNative = getPossibleNativeLangFromBrowserLang(navigator.language)

            if (possibleNative !== '') {
                this.props.dispatch(setNativeLanguage(possibleNative))
            }
        }

        let savedUiLanguage = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.UI_LANGUAGE.description)
        if (savedUiLanguage !== '') {
            this.handleUiLangChange((savedUiLanguage))
        } else  {
            let possibleUi = getPossibleUiLangFromBrowserLang(navigator.language)
            this.handleUiLangChange(possibleUi)
        }

        let savedTextTransEngine = loadStringFromLocalStorage(LOCAL_STORAGE_KEY_NAME.TEXT_TRANS_ENGINE.description)
        if (savedTextTransEngine !== '') {
            this.handleTextEngineChange(savedTextTransEngine)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentRequestStatus, dispatch, dataSource} = this.props

        if (currentRequestStatus.source === dataSource
            && currentRequestStatus.code !== 200
            && currentRequestStatus.isShown === false) {
            dispatch(showRequestFailure(true))  // tell the state that the error message will be shown in an alert
            this.openAlertDialog(ALERT_TYPE.NETWORK, currentRequestStatus.code)
        }
    }

    handleNativeLangChange = (lang) => {
        const { dispatch, nativeLanguage } = this.props
        if (lang !== '' && lang !== nativeLanguage) {
            dispatch(setNativeLanguage(lang))

            if ( nativeLanguage !== '') {   // user may already do some translation, for example, "try luck"
                dispatch(cleanHistoryData(DATA_SOURCE_MSAPI))
                dispatch(cleanHistoryData(DATA_SOURCE_GAPI))
            }
        }
    }

    handleUiLangChange = (lang) => {
        const { dispatch, uiLanguage } = this.props
        if (lang !== '' && lang !== uiLanguage) {
            dispatch(setUiLanguage(lang))
        }
    }

    handleTextEngineChange = (newEngine) => {
        const { dispatch, textTranslateEngine } = this.props
        if (newEngine !== textTranslateEngine) {
            dispatch(setTextTranslateEngine(newEngine))
        }
    }

    closeSelectLanguageDialog = (callHook) => {
        if ( callHook && this.state.selectLangDialogHook != null) {
            this.state.selectLangDialogHook()
        }

        this.setState(state =>
            ({
                ...state,
                openSelectLangDialog: false,
                selectLangDialogHook: null
            }));
    }

    onSelectNativeLang = (newLang) => {
        if (newLang !== '') {
            saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.NATIVE_LANGUAGE.description, newLang)
        }

        this.handleNativeLangChange(newLang)
    }

    onSelectUiLang = (newLang) => {
        if (newLang !== '') {
            saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.UI_LANGUAGE.description, newLang)
        }

        this.handleUiLangChange(newLang)
    }

    onSelectTextTranslateEngine = (newEngine) => {
        const { textTranslateEngine } = this.props
        if (newEngine !== textTranslateEngine) {
            saveStringToLocalStorage(LOCAL_STORAGE_KEY_NAME.TEXT_TRANS_ENGINE.description, newEngine);
        }

        this.handleTextEngineChange(newEngine)
    }

    openSelectLanguageDialog = (dialogHook) => {

        this.setState(state =>
            ({
                ...state,
                openSelectLangDialog: true,
                selectLangDialogHook: dialogHook
            }));
    }

    closeAlertDialog = () => {
        this.setState(state =>
            ({
                ...state,
                openAlertDialog: false
            }));
    }

    openAlertDialog = (type, ...param) => {
        let alertTile, alertText, dialogActions
        switch (type) {
            case ALERT_TYPE.NORMAL:
                if (param.length !== 3)
                    return

                alertTile = param[0]
                alertText = param[1]
                dialogActions = param[2]
                break
            case ALERT_TYPE.NETWORK:
                if (param.length !== 1)
                    return

                alertTile = getI18nStrings(this.props.uiLanguage, 'App')[ALERT_NETWORK_ISSUE_TITLE_TEXT_ID]
                alertText = getMessageFromNetworkErrorCode(this.props.uiLanguage, param[0])
                dialogActions = [
                    {
                        actionText: getI18nStrings(this.props.uiLanguage, 'App')[CLOSE_BUTTON_TEXT_ID],
                        action: null
                    }
                ]
                break
            default:
                return
        }

        this.setState(state =>
            ({
                ...state,
                openAlertDialog: true,
                alertTitle: alertTile,
                alertText: alertText,
                dialogActions: dialogActions
            }));
    }

  render() {
    const { isFetchingDicLookup, isFetchingDictSuggestions, isFetchingTransPage, nativeLanguage, uiLanguage, textTranslateEngine } = this.props
    let loadingText = ''
    if (isFetchingDicLookup) {
        loadingText = getI18nStrings(uiLanguage, 'App')[LOADING_LOOKUP_TEXT_ID]
    } else if (isFetchingDictSuggestions) {
        loadingText = getI18nStrings(uiLanguage, 'App')[LOADING_SUGGESTIONS_TEXT_ID]
    } else {
        loadingText = getI18nStrings(uiLanguage, 'App')[LOADING_TRANS_PAGE_TEXT_ID]
    }

    return (
      <div className="App">
          <LoadingOverlay
              active={isFetchingDicLookup || isFetchingDictSuggestions || isFetchingTransPage}
              spinner
              text={loadingText}
          >
          <ResponsiveDrawer
              nativeLanguage={nativeLanguage}
              i18nStrings={getI18nStrings(uiLanguage, 'ResponsiveDrawer')}
          />
          <Router history={history}>
              <PageContainer
                  i18nStrings={getI18nStrings(uiLanguage, 'PageContainer')}
                  handleNativeLangChange={this.onSelectNativeLang}
                  nativeLanguage={nativeLanguage}
                  onOpenSelectNativeDialog={this.openSelectLanguageDialog}
                  openAlertDialog={this.openAlertDialog}
                  onSelectUiLang={this.onSelectUiLang}
                  uiLanguage={uiLanguage}
                  onSelectTextTransEngine={this.onSelectTextTranslateEngine}
                  textTranslateEngine={textTranslateEngine}
              />
          </Router>
          <SelectLanguageDialog
              open={this.state.openSelectLangDialog}
              onCloseDialog={this.closeSelectLanguageDialog}
              selectNativeLang={this.onSelectNativeLang}
              i18nStrings={getI18nStrings(uiLanguage, 'SelectLanguageDialog')}
          />
          <AlertDialog
              onClose={this.closeAlertDialog}
              dialogOpen={this.state.openAlertDialog}
              dialogText={this.state.alertText}
              dialogTitle={this.state.alertTitle}
              dialogActions={this.state.dialogActions}
          />
          </LoadingOverlay>
      </div>
    );
  }
}

function getMessageFromNetworkErrorCode(lang, code){
    switch (code) {
        case 408:
            return getI18nStrings(lang, 'App')[ALERT_NETWORK_TIMEOUT_TEXT_ID]
        case 404:
            return getI18nStrings(lang, 'App')[ALERT_NETWORK_UNAVAILABLE_TEXT_ID]
        default:
            return getI18nStrings(lang, 'App')[ALERT_NETWORK_ISSUE_TEXT_ID]
    }
}

const mapStateToProps = state => {
    const { nativeLanguage, uiLanguage, textTranslateEngine, dataBySource, dataSource, currentRequestStatus } = state

    let isFetchingDicLookup = false, isFetchingDictSuggestions = false, isFetchingTransPage = false

    if (dataBySource['folkLexikon'] && dataBySource['folkLexikon']['lookupword']) {
        isFetchingDicLookup = dataBySource['folkLexikon']['lookupword'].isFetching

    }

    if (dataBySource['folkLexikon'] && dataBySource['folkLexikon']['generatecompletion']) {
        isFetchingDictSuggestions = dataBySource['folkLexikon']['generatecompletion'].isFetching
    }

    let apiType = textTranslateEngine + 'Api'
    if (dataBySource[apiType] && !isObjectEmpty(dataBySource[apiType])) {
        let requestType =  dataBySource[apiType]['selectRequestType']

        if (requestType && dataBySource[apiType][requestType]) {
            isFetchingTransPage = dataBySource[apiType][requestType].isFetching
        }
    }

    return {
        isFetchingDicLookup,
        isFetchingDictSuggestions,
        isFetchingTransPage,
        nativeLanguage,
        currentRequestStatus,
        dataSource,
        uiLanguage,
        textTranslateEngine
    }
}

export default connect(mapStateToProps)(App)
