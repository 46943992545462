import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { SwapHoriz, SwapVert } from "@material-ui/icons"

export default function AdaptiveSwapButton(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    if (matches) {
        return <SwapHoriz/>
    } else {
        return <SwapVert/>
    }
}