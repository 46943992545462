import React from "react"
import PropTypes from "prop-types"
import {appbarHeigh, drawerWidth} from "../ResponsiveDrawer"
import Paper from '@material-ui/core/Paper'
import {withStyles} from "@material-ui/core"
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import { Translate, InfoOutlined, ContactSupport, ContactMail, AttachMoney, Language, LibraryBooks, Motorcycle } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import NativeLanguageSelect from '../small/NativeLanguageSelect'
import CustomSelect from '../small/CustomSelect'
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import donation from '../../images/donation.png'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {connect} from "react-redux";
import {showMessage} from "../../actions/actions";


const styles = theme => ({
    nested: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,
            marginLeft: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 100,
            marginLeft: drawerWidth + 100
        }
    }
});

const SET_NATIVE_LANG_HEAD_TEXT_ID = 'setNativeLangHead'
const SET_NATIVE_LANG_DESC_TEXT_ID = 'setNativeLangDesc'
const SET_UI_LANG_HEAD_TEXT_ID = 'setUILanguageHead'
const SET_UI_LANG_DESC_TEXT_ID = 'setUILanguageDesc'
const SET_TRANSLATE_ENGINE_HEAD_TEXT_ID = 'setTextTranslateEngineHead'
const SET_TRANSLATE_ENGINE_DESC_TEXT_ID = 'setTextTranslateEngineDesc'
const MS_ENGINE_NAME_TEXT_ID = 'ms'
const GOOGLE_ENGINE_NAME_TEXT_ID = 'google'
const USER_MANUAL_HEAD_TEXT_ID = 'userManualHead'
const USER_MANUAL_DESC_TEXT_ID = 'userManualDesc'


const ABOUT_HEAD_TEXT_ID = 'aboutHead'
const ABOUT_DESC_TEXT_ID = 'aboutDesc'
const CONTACT_AUTHOR_HEAD_TEXT_ID = 'contactHead'
const CONTACT_AUTHOR_DESC_TEXT_ID = 'contactDesc'
const DONATION_HEAD_TEXT_ID = 'donationHead'
const DONATION_DESC_TEXT_ID = 'donationDesc'

class SettingsPage extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        nativeLanguage: PropTypes.string.isRequired,
        i18nStrings: PropTypes.object.isRequired,
        handleNativeLangChange: PropTypes.func.isRequired,
        onSelectUiLang: PropTypes.func.isRequired,
        uiLanguage: PropTypes.string.isRequired,
        onSelectTextTransEngine: PropTypes.func.isRequired,
        textTranslateEngine: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            expandAbout: false,
            expandManual: false,
            expandContact: false,
            expandDonation: true
        }
    }

    onExpandSection = name => () => {
        this.setState(
            state => ({
                ...state,
                [name]: !state[name]
            })
        )
    }

    render() {
        const { classes, i18nStrings, nativeLanguage, uiLanguage, textTranslateEngine, handleNativeLangChange, onSelectUiLang, onSelectTextTransEngine, showMessage } = this.props

        return (
            <div className={classes.nested}>
                <Paper style={{ marginTop: appbarHeigh + 10}}>
                    <List component="nav"  aria-label="Mailbox folders">
                        <ListItem key={'nativelang'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Translate />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18nStrings[SET_NATIVE_LANG_HEAD_TEXT_ID]} style={{marginRight: 10}}/>
                            <NativeLanguageSelect
                                nativeLanguage={nativeLanguage}
                                i18nStrings={i18nStrings}
                                selectNativeLang={handleNativeLangChange}/>
                            <IconButton size="small" color="primary" onClick={() =>
                                showMessage(i18nStrings[SET_NATIVE_LANG_DESC_TEXT_ID])
                            }>
                                <InfoOutlined />
                            </IconButton>
                        </ListItem>
                        <Divider />
                        <ListItem key={'uilang'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Language />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18nStrings[SET_UI_LANG_HEAD_TEXT_ID]} style={{marginRight: 10}}/>
                            <CustomSelect
                                initialValue={uiLanguage}
                                optionValues={['zh-Hans', 'en']}
                                optionTexts={['中文', 'English']}
                                onSelectOption={onSelectUiLang}/>
                            <IconButton size="small" color="primary" onClick={() =>
                                showMessage(i18nStrings[SET_UI_LANG_DESC_TEXT_ID])
                            }>
                                <InfoOutlined />
                            </IconButton>
                        </ListItem>
                        <Divider />
                        <ListItem key={'transengine'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Motorcycle />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18nStrings[SET_TRANSLATE_ENGINE_HEAD_TEXT_ID]} style={{marginRight: 10}}/>
                            <CustomSelect
                                initialValue={textTranslateEngine}
                                optionValues={['ms', 'google']}
                                optionTexts={[i18nStrings[MS_ENGINE_NAME_TEXT_ID], i18nStrings[GOOGLE_ENGINE_NAME_TEXT_ID]]}
                                onSelectOption={onSelectTextTransEngine}/>
                            <IconButton size="small" color="primary" onClick={() =>
                                showMessage(i18nStrings[SET_TRANSLATE_ENGINE_DESC_TEXT_ID])
                            }>
                                <InfoOutlined />
                            </IconButton>
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={this.onExpandSection('expandAbout')} key={'about'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ContactSupport />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18nStrings[ABOUT_HEAD_TEXT_ID]} />
                            {this.state.expandAbout ? <ExpandMore /> : <ExpandLess />}
                        </ListItem>
                        <Collapse in={this.state.expandAbout} timeout="auto" unmountOnExit >
                            <Typography variant="body2" align='left' color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap', margin: 10}}>
                                {i18nStrings[ABOUT_DESC_TEXT_ID]}
                            </Typography>
                        </Collapse>
                        <Divider />
                        <ListItem button onClick={this.onExpandSection('expandManual')} key={'usermanual'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <LibraryBooks />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18nStrings[USER_MANUAL_HEAD_TEXT_ID]} />
                            {this.state.expandManual ? <ExpandMore /> : <ExpandLess />}
                        </ListItem>
                        <Collapse in={this.state.expandManual} timeout="auto" unmountOnExit >
                            <Typography variant="body2" align='left' color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap', margin: 10}}>
                                {i18nStrings[USER_MANUAL_DESC_TEXT_ID]}
                            </Typography>
                        </Collapse>
                        <Divider />
                        <ListItem button onClick={this.onExpandSection('expandContact')} key={'contact'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ContactMail />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18nStrings[CONTACT_AUTHOR_HEAD_TEXT_ID]} />
                            {this.state.expandContact ? <ExpandMore /> : <ExpandLess />}
                        </ListItem>
                        <Collapse in={this.state.expandContact} timeout="auto" unmountOnExit >
                            <Typography variant="body2" color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap', margin: 10}}>
                                {i18nStrings[CONTACT_AUTHOR_DESC_TEXT_ID]}
                                <Link href={'mailto:plugghast.nu@gmail.com?subject=Mail for Plugghäst'}>
                                    plugghast.nu@gmail.com
                                </Link>
                            </Typography>
                        </Collapse>
                        <Divider />
                        <ListItem button onClick={this.onExpandSection('expandDonation')}  key={'donation'}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AttachMoney />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18nStrings[DONATION_HEAD_TEXT_ID]} />
                            {this.state.expandDonation ? <ExpandMore /> : <ExpandLess />}
                        </ListItem>
                        <Collapse in={this.state.expandDonation} timeout="auto" unmountOnExit >
                            <Typography variant="body2" align='left' color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap', margin: 10}}>
                                {i18nStrings[DONATION_DESC_TEXT_ID]}
                            </Typography>
                            <div style={{ marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img src={donation} alt="Donation QR code" />
                            </div>
                        </Collapse>
                    </List>
                </Paper>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    showMessageStatus: state.showMessageStatus
})

const mapDispatchToProps = (dispatch) => ({
    showMessage: (message) => dispatch(showMessage(message, 10000))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsPage))