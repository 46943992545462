export default {
    FirebaseErrors: {
        'auth/email-already-in-use': '该电子邮箱已被其他账号使用。',
        'auth/invalid-email': '无效的电子邮箱。',
        'auth/operation-not-allowed': '该种注册方式已被禁用。请联系网站开发者。',
        'auth/weak-password': '密码过于简单。',

        'auth/account-exists-with-different-credential': '与已有账号冲突。',
        'auth/auth-domain-config-required': 'Domain配着有误，请联系网站开发者。',
        'auth/cancelled-popup-request': '多个登录弹出对话框被并存，请联系网站开发者。',
        'auth/operation-not-supported-in-this-environment': '改操作不被支持，请联系网站开发者。',
        'auth/popup-blocked': '登录弹出对话框被浏览器阻止。请检查浏览器的设置。',
        'auth/popup-closed-by-user': '在登录完成之前，登录弹出对话框被意外关闭。请重试。',
        'auth/unauthorized-domain': '程序domain未被授权，请联系网站开发者。',

        'auth/user-disabled': '该电子邮箱关联的账号已被停止。请联系网站开发者。',
        'auth/user-not-found': '该电子邮箱无关联账号。请先注册。',
        'auth/wrong-password': '密码无效。请重试。',

        newWordExists: '该词已经在您的生词本中了，请点击界面上方的同步按钮以从云端加载最新的生词本。',
        newWordNotExists: '该词并不在您的生词本中，请点击界面上方的同步按钮以从云端加载最新的生词本。',
    },
    App: {
        loadingLookupText: '查询中...',
        loadingSuggestionText: '获取输入建议...',
        loadingTransPageText: '正在翻译...',
        alertNetworkIssueTitle: '网络连接问题？',
        alertNetworkTimeoutText: '网络连接超时，请稍后再试。',
        alertNetworkUnavailableText: '网络服务暂时不可用，请稍后再试或联系网站管理员。',
        alertNetworkIssueText: '网络无法连接。请检查网络设置是否正常。',
        closeButtonText: '关闭',
    },
    SelectLanguageDialog: {
        dialogTitle: '设置母语',
        dialogText: '请告诉Plugghäst您的母语。之后，如果您点击字典词条中的英语/瑞典语释义，例句，成语，复合词，Plugghäst将为您把相应信息翻译成您的母语。',
        noNeedToSetText: '不设置',
        notSelectedText: '未设置',
        closeButtonText: '关闭',
        okButtonText: '确定',
        okWithNotSelected: '您没有选择任何母语。',
        okWithSelected: '您选择了"%s"作为您的母语。',
        selectNoNeed: '您选择了"不设置"。您可以随时在"程序设置"页面指定您的母语。'
    },
    ResponsiveDrawer: {
        dictItemText: '瑞典语字典',
        transItemText: '瑞典语翻译',
        newWordItemText: '云端生词本',
        settingsItemText: '程序设置',
        newsItemText: '瑞典语新闻',
        specialtySwedish: '职业瑞典语',
        gameAndTestItemText: '测验与游戏',
        tipOfTheDayItemText: '每日推荐',
        dailyTasksItemText: '每日复习任务',
        loginItemText: '登录',
        logoutItemText: '登出',

        sortByDateItemText: '按时间排序',
        sortByAlphaItemText: '按字母排序',
        sortByClassItemText: '按词性排序',
        sortByReviewStatusItemText: '按复习状态排序',
        hideRemembersItemText: '隐藏已记住生词',
        showRemembersItemText: '显示已记住生词',
        syncCloudItemText: '与云端同步'
    },
    PageContainer: {
        dictionaryTitle: '瑞典语字典',
        translateTitle: '瑞典语翻译',
        settingsTitle: '程序设置',
        new_wordsTitle: '云端生词本',
        daily_taskTitle: '每日复习任务',
        daily_taskDesc: '基于艾宾浩斯遗忘曲线，每天推送给您一个生词复习任务，让您的词汇量飞起！ \n 该页面即将推出，敬请期待！',
        newsTitle: '瑞典语新闻',
        newsDesc: '通过阅读瑞典语新闻，提升您的阅读水平。',
        specialtyTitle: '职业瑞典语',
        specialtyDesc: '工作场合该怎么说瑞典语？这里您可以找到有用的资料。',
        quiz_gameTitle: '小测验和小游戏',
        quiz_gameDesc: '学中玩，玩中学，从乐学到爱学到好学。',
        tipsTitle: '每日推荐',
        tipsDesc: '日积月累，每天为您推荐一些瑞典语小知识。',
        signinTitle: '登录账号',
        signupTitle: '注册账号',
        reset_passwordTitle: '重置密码',

        NativeTranslateDialog: {
            svLabelText: '瑞: ',
            enLabelText: ' 英: ',
            nativeTranslationTitle: '母语翻译',
            confidenceLabelText: '. 可信度: ',
            noResultsText: '没有查询结果',
            copyToClipboardText: '拷贝文字',
            detectBrowserLanguageDesc: 'Plugghäst可以为您把字典词条中的信息翻译成您的母语。Plugghäst根据您的系统设置猜测您的母语为"%s"。如果正确，请点击"OK"。如果"%s"不是您的母语，您可以点击"设置母语"。如果您不需要该功能，请点击"不设置"。',
            setNativeLangButtonText: '设置母语',
            noNeedToSetText: '不设置',
            closeButtonText: '关闭',
            okButtonText: 'OK',
            ms: '微软服务',
            google: '谷歌服务',
            usingEngine: '使用'
        },

        TryLuckResultDialog: {
            svLabelText: '瑞: ',
            title: '试试你的运气',
            noResultsText: '没有查询结果',
            copyToClipboardText: '拷贝文字',
            okButtonText: 'OK',
            translationLabel: '英语释义: '

        },

        DictionaryPage: {
            pageTile: '瑞典语字典',
            lookupButtonText: '查字典',
            AutoSuggestInput: {
                inputPlaceholderText: '输入待查瑞典语或英文单词/词组...'
            },
            DictionaryEntryList: {
                wordClassNoun: '名词',
                wordClassVerb: '动词',
                wordClassAdj: '形容词',
                wordClassInterj: '叹词',
                wordClassPron: '代词',
                wordClassAdv: '副词',
                wordClassConj: '连词',
                wordClassPrep: '介词',
                wordClassNum: '数词',
                wordClassAbbrev: '缩略语',
                NestedList: {
                    addNewWord: '已被加入您的生词本',
                    removeNewWord: '已被从您的生词本中删除',
                    EmbeddedList: {
                        enDefinitionText: '英语释义: ',
                        svDefinitionText: '瑞典语释义: ',
                        synonymText: '近义词: ',
                        inflectionsText: '词形变化: ',
                        pronunciationText: '发音: ',
                        explanationText: '瑞典语解释: ',
                        examplesText: '例句',
                        idiomsText: '成语',
                        compoundsText: '复合词',
                        noNeedToSetText: '不设置',
                        setNativeLangButtonText: '设置母语',

                    }
                }
            },
            NativeTranslateDialog: {
                svLabelText: '瑞: ',
                enLabelText: ' 英: ',
                nativeTranslationTitle: '母语翻译',
                confidenceLabelText: '. 可信度: ',
                noResultsText: '没有查询结果',
                copyToClipboardText: '拷贝文字',
                detectBrowserLanguageDesc: 'Plugghäst可以为您把字典中的英语/瑞典语释义，例句，成语和复合词翻译成您的母语。Plugghäst根据您的系统设置猜测您的母语为"%s"。如果"%s"不是您的母语，您可以点击下面的"设置母语"进行设置。如果您不需要该功能，请点击"不设置"。',
                setNativeLangButtonText: '设置母语',
                noNeedToSetText: '不设置',
                closeButtonText: '关闭',
                okButtonText: 'OK',
                ms: '微软服务',
                google: '谷歌服务',
                usingEngine: '使用'
            },
            FuzzyLookupDialog: {
                noEntryInDictText: '字典中无词条',
                listSuggestionsText: '。您想查的是不是下列之一？',
                notInSuggestionListText: '如果上面这些都不是您想查的，',
                tryNativeTranslationText: '点我试试运气吧？:)',
                closeButtonText: '关闭'
            },
            SaolInflectionDialog: {
                saolDialogTitle: '的各种变形',
                noResultsText: '没有查询结果',
                closeButtonText: '关闭',
                wordClassNoun: '名词',
                wordClassVerb: '动词',
                wordClassAdj: '形容词',
                wordClassInterj: '叹词',
                wordClassPron: '代词',
                wordClassAdv: '副词',
                wordClassConj: '连词',
                wordClassPrep: '介词',
                wordClassNum: '数词',
                wordClassAbbrev: '缩略语',
            }
        },
        TranslatePage: {
            pageTile: '瑞典语翻译',
            pageDescription: 'Here you can translate',
            transButtonText: '翻译',
            sameLanguagesAlert: '翻译的源语言和目的语言应该不相同',
            emptyInputAlert: '请输入待翻译文字',
            labelFrom: '源语言',
            labelTo: '目的语言',
            placeholderFrom: '请输入您要翻译的文字',
            TranslateLanguageSelect: {
                englishName: '英语',
                swedishName: '瑞典语'
            },
            jump2DictButtonText: '查字典',
            dictItemText: '瑞典语字典',
        },
        NewWordsPage: {
            pageTile: '云端生词本',
            enDefinitionText: '英语释义: ',
            svDefinitionText: '瑞典语释义: ',
            synonymText: '近义词: ',
            inflectionsText: '词形变化: ',
            pronunciationText: '发音: ',
            explanationText: '瑞典语解释: ',
            examplesText: '例句: ',
            idiomsText: '成语: ',
            compoundsText: '复合词: ',
            forgetStatus: '忘了',
            knowStatus: '脸熟',
            rememberStatus: '记住',
            notReviewedStatus: '未复习',
            signinText: '登录',
            askSinginText: '使用云端生词本，请先',
            landingText1: '您可以通过在字典词条中点击 ',
            landingText2: ' 按钮将生词加入您的生词本，之后您就可以在生词本中复习您的生词；如果您要删除已有生词，可以点击相应生词卡片或者字典词条上的按钮 ',
            headText: '您已经添加%s个生词：',
            secondaryText: '记住 %r, 脸熟 %k, 忘了 %f, 未复习 %n',
            wordClassNoun: '名词',
            wordClassVerb: '动词',
            wordClassAdj: '形容词',
            wordClassInterj: '叹词',
            wordClassPron: '代词',
            wordClassAdv: '副词',
            wordClassConj: '连词',
            wordClassPrep: '介词',
            wordClassNum: '数词',
            wordClassAbbrev: '缩略语',
            wordClassNounFull: '名词',
            wordClassVerbFull: '动词',
            wordClassAdjFull: '形容词',
            wordClassInterjFull: '叹词',
            wordClassPronFull: '代词',
            wordClassAdvFull: '副词',
            wordClassConjFull: '连词',
            wordClassPrepFull: '介词',
            wordClassNumFull: '数词',
            wordClassAbbrevFull: '缩略语',
            wordClassNone: 'N/A',
            reviewStatusUpdating: '"%s"的复习状态已更新为',
            removeNewWord: '已被从您的生词本中删除',
        },
        SettingsPage: {
            pageTile: '程序设置',
            pageDescription: 'App Settings',
            setNativeLangHead: '母语',
            setNativeLangDesc: '在指定母语之后，如果您点击字典词条中的英语/瑞典语释义，例句，成语，复合词，Plugghäst将为您把相应信息翻译成您的母语。',
            setUILanguageHead: '界面语言',
            setUILanguageDesc: 'Plugghäst界面文字（如对话框和按钮等）所使用的语言。限于我们的能力水平，我们目前只提供了英文和中文两种选择。如果您感兴趣，欢迎加入我们，方便更多朋友使用Plugghäst :)',
            setTextTranslateEngineHead: '文字翻译引擎',
            setTextTranslateEngineDesc: 'Plugghäst默认使用Google Translate API翻译文字。您也可以选择使用Microsoft Translator API。',
            ms: '微软',
            google: '谷歌',
            aboutHead: '关于Plugghäst',
            aboutDesc: `	2014年五月， “小马” Plugghäst在Google Play上线了，一年半之后，这匹小马又在App Store登陆了。在两大平台上，Plugghäst的下载量累计都超过了两万次，评分也都保持在4.6颗星左右。此后，我们收到了很多来自热心用户的意见和建议，可惜的是，由于种种原因，一直没有时间改进Plugghäst。2019年初，我们终于下定决心，对Plugghäst进行全面改版和升级，方便您更好地学习和应用瑞典语。 2019年10月，新Plugghäst发布了v0.1版本，供测试和意见收集。
	本次发布的是新Plugghäst的正式版本v1.0，主要的新功能包括用户登录和云端生词本。使用新Plugghäst v1.0无需安装，您只用在您的设备（Android，iOS和桌面均可）上的浏览器（推荐使用Chrome浏览器）中输入网址www.plugghast.nu即可加载。如果在浏览器中已经打开了Plugghäst，那么只要刷新一下，就能加载最新版本。
	新版的字典功能比以前更为强大，它提供了动词，名词和形容词的详细词形变化供您学习参考之用。此外，新版Plugghäst的母语翻译功能进一步增强。对于瑞典语词条的英文释义，如果是单个英文单词，Plugghäst会提供尽量多的母语解释，供您选择和匹配。对于一般的英文释义，母语翻译质量也有明显提高。此外，词条中的例句，成语，合成词和瑞典语释义都可以进行母语翻译，方便您更好理解词条。新版Plugghäst支持四十余种语言的母语翻译，具体支持语言列表，见“程序设置”页面中的“母语”设置。新版的瑞典语翻译功能，则提供了母语，瑞典语和英语之间的翻译。
	1.0版本的重要更新是基于云服务的生词本，您的生词将可以在任何设备上保存和同步。
	下一步，我们将在云端生词本的基础上，推出基于艾宾浩斯遗忘曲线的每日生词复习任务，帮助您科学有效的提升词汇量。此外，我们还列出了一些将来可能开辟的频道：瑞典语新闻，职业瑞典语以及测验与游戏。如果您什么新的想法和建议，欢迎您致信我们。
	您在使用新版Plugghäst的过程中，如果发现任何性能相关的问题（比如查询反馈的速度），敬请您及时告知我们。新版Plugghäst的后端服务部署于微软Azure云平台之上。为了节约成本，我们使用了Azure提供的基本服务计划。如果大多数用户反映性能问题，我们将考虑升级现有计划。
	再次感谢您对Plugghäst的一贯支持！如果我们的工作能为您的瑞典语学习之路带来一丝帮助，那将是我们最大的快慰。我们真诚期待您对新版的意见和建议。祝您学习愉快！`,
            userManualHead: '如何使用',
            userManualDesc: `概览
版本：1.0.2

新功能特别推荐
1.0版本(2021.10)推出了云端生词本。您在任何设备上查询生词时，可以随时把生词加入生词本。生词本会实时同步到云端，这样，在您所有设备上都可以浏览和复习生词。特别提醒：使用云端生词本需要注册账户并登录; 登录后，您会发现，每个瑞典语词条右侧都有一个"A+"按钮，点击该按钮就可以将该词条加入您的生词本
0.2版本(2019.12)为您带来了原生app级体验。在iOS上，使用Safari浏览器打开www.plugghast.nu，然后点击分享按钮，然后选择"添加到主屏幕"，然后新版Plugghäst的图标就会出现在您的手机桌面上，然后您可以和使用原生app一下使用新版Plugghäst啦。在Android上的设置也基本类似，您只需要用Chrome打开www.plugghast.nu，然后点击菜单按钮（竖排的三个点），随后选择"添加到主屏幕"即可（菜单项也可能显示为"安装应用"）。

适用设备：Android和iOS的手机或者平板，以及个人电脑（Windows/Linux/Mac）
推荐浏览器：Chrome（建议更新到最新版）
新版Plugghäst是基于Web的app，您无需任何安装，只要在您的设备上打开浏览器（推荐使用Chrome）并输入网址www.plugghast.nu，就可以加载Plugghäst。
新版Plugghäst的界面主要包括导航栏和主视图。在个人电脑和平板上使用时，导航栏总是显示在屏幕左侧，点击导航栏中的相应条目，主视图中将显示相应的功能页面。在手机上使用时，导航栏默认是隐藏的。点击左上方的菜单按钮（三条白线），导航栏将弹出，然后可以点击相应条目跳转到功能页面。

功能介绍
•	用户注册和登录：v1.0提供了用户注册和登录功能。注意，只有在登录成功之后，才能使用云端生词本。
	o	加载Plugghäst后，点击界面右上方的登录按钮就可以进入登录页面。
	o	您可以通过Plugghäst的用户注册页面，输入您的电子邮箱并设定密码先进行注册（密码需要至少六位字母或者数字）。建议使用您的常用邮箱进行注册，这样您可以在忘记密码时通过电子邮件重置密码。
	
•	瑞典语字典：该页提供了瑞英双向字典以及字典词条的母语翻译功能。可以在该页中查询瑞典语或者英语单词或者词组。输入待查单词或者词组后，点击"查字典"按钮即可查询。查询结果中标有瑞典国旗的是瑞典语词条，标有英国国旗的是英语词条。词条默认处于折叠状态，折叠的词条包含了词性和简要释义。以瑞典语词条为例。点击展开您感兴趣的词条，一般情况下，您将看到如下子条目：
	o	英文释义：词条的英文解释。点击该条目，Plugghäst会将该英文解释翻译成您的母语（您需要在”程序设置”页面中设置您的母语）
	o	发音：该条目显示词条的发音标注。点击该条目，您会听到标准的瑞典语发音
	o	词形变化：该条目显示词条的词形变化（主要是名词，动词和形容词）。点击该条目，Plugghäst会显示非常详细的词形变化供您参考
	o	近义词：该条目显示词条的近义词
	o	例句：该条目默认处于折叠状态，点击该条目，您将看到词条相关例句，点击例句，Plugghäst会将该例句翻译成您的母语
	o	复合词：该条目显示词条相关的复合词。注意，部分复合词中间有分隔符”|”。点击复合词，Plugghäst会将该复合词翻译成您的母语
	o	成语：该条目默认处于折叠状态，点击该条目，您将看到词条相关的成语或者习惯用法。点击成语，Plugghäst会将其翻译成您的母语
	o	瑞典语释义：部分瑞典语词条还提供了瑞典语解释。点击该条目，Plugghäst会将该瑞典语解释翻译成您的母语

    特别提请注意：
	o	每个瑞典语词条右侧都有一个"A+"按钮，点击该按钮就可以将该词条加入您的生词本（需要登录才能显示该按钮）。随后，该按钮会显示为"A-"。如果点击"A-"，该词会被从您的生词本里面删除。
	o	只要您看到子条目的右侧带有图标，就表明该子条目可点击。您可以点击一下看看会发生什么。
	o	如果您要查询的词在字典中没有相关词条，Plugghäst会根据您的输入提供一个模糊查询结果的列表，如果模糊查询结果都不是您的期望，Plugghäst还提供了”尝试运气”按钮，您可以试试您的运气。

•	瑞典语翻译：该页提供了在您的母语，瑞典语和英语之间的相互翻译功能。建议在查询句子的时候使用该功能。

•	云端生词本：您在字典界面中加入的生词将显示在该页中，同时，所有生词将随时同步到云端，方便您在您的所有设备上浏览和复习。
	o	每个生词都有三种复习状态：忘了、脸熟和记住。当您看到一个生词，大概能猜到它的意思，但是不是很确切，这样可以标记为"脸熟"，而"记住"就意味着当您看到这个生词时，它的所有信息能在您脑海中马上显现。
	o	生词默认以加入时间进行分类和排序，您还可以选择按照字母排序，词性排序或者复习状态排序。您可以选择隐藏已经标记为"记住"的生词。
	o	您在任何设备上成功添加/删除/更新生词，都会实时同步到云端。如果您在添加/删除/更新生词时出现问题，那么很有可能是因为您当前设备上的本地生词本并不是最新。您可以通过点击界面上方的同步按钮以从云端加载最新的生词本。
	o	点击列表中的分类标签，可以展开该标签下的所有生词，点击某个生词，就可以展开该生词的卡片。卡片上显示该词的详细信息，同时显示该生词的复习状态。您可根据当前对该词的掌握状况，更新复习状态。卡片还提供了母语翻译按钮。您还可以点击卡片上的"A-"按钮来删除该生词。
	o	每天，我们都有很多的时间碎片，或在地铁里，或在马桶上，使用Plugghäst的生词本，您可以利用这些碎片来复习生词。更重要的是，您的生词本是存在云端的，而新版Plugghäst是基于Web的app，所以您可以在您的任何设备上随时随地复习生词，也不用担心生词本会丢失。

•	程序设置：该页面中包含如下设置
	o	母语：设定母语后，就可以将字典词条里面的相关信息以母语展示。目前Plugghäst支持46种语言
	o	界面语言：即Plugghäst界面文字（如对话框和按钮等）所使用的语言。目前提供中文和英文两种选择
	o	文字翻译引擎：Plugghäst的母语翻译功能默认使用Google翻译服务。如果您在使用中碰到问题，可以考虑尝试一下微软翻译服务`,
            contactHead: '联系我们',
            contactDesc: '如果您有任何关于Plugghäst的意见和建议，欢迎您致信',
            donationHead: '捐赠',
            donationDesc: '\t从一开始，我们就把Plugghäst作为一个公益项目来做，并且将来也会继续坚持把她作为公益项目做下去。现在，我们正式发布了新版Plugghäst，希望新的版本能给您带来新的帮助和体验。如果您觉得Plugghäst对您的瑞典语学习有所帮助，欢迎您赞助我们的工作。无论多少，五元，十元，都是对我们的莫大鼓励！我们向您保证，您赞助的每一分钱，都会用在Plugghäst的开发维护上，例如域名和云服务相关的费用。感谢您的大力支持！',
            notSelectedText: '未设置'
        },
        NewsPage: {
            pageTile: '新闻',
            pageDescription: 'Read news in Swedish'
        },
        TipsPage: {
            pageTile: '每日一贴',
            pageDescription: 'useful tips'
        },
        QuestionPage: {
            pageTile: '论坛问道',
            pageDescription: 'Ask questions'
        },
        SignInPage: {
            headText: '使用电子邮箱登录',
            emailLabel: '电子邮箱',
            passwordLabel: '密码',
            signinText: '登录',
            forgetPasswordText: '忘记密码了？',
            dontHaveAccountText: '还没有账号？注册',
            signinDirectlyText: '更便捷的登录方式',
            facebookAccoount: 'Facebook账号',
            googleAccount: 'Google账号'
        },
        SignUpPage: {
            headText: '使用电子邮箱注册',
            emailLabel: '电子邮箱',
            passwordLabel: '密码',
            signupText: '注册',
            signinText: '登录',
            haveAccountText: '已经有账号了？登录'
        },
        ResetPasswordPage: {
            headText: '重置密码',
            emailLabel: '电子邮箱',
            resetText: '重置',
            signinText: '登录',
            signupText: '注册',
            sentMsgText: '密码重置邮件已发送至您的邮箱。请点击邮件中的链接以重置密码。'
        }
    }
}