import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import {drawerWidth} from "../ResponsiveDrawer";



function renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) =>
                        part.highlight ? (
                            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
                        ) : (
                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </strong>
                        ),
                )}
            </div>
        </MenuItem>
    );
}

function getSuggestions(value, suggestions) {
    if (suggestions) {
        const inputValue = value.trim().toLowerCase();

        const inputLength = inputValue.length;
        let count = 0;

        if (inputLength === 0) {
            return []
        }

        const matches = suggestions.filter(suggestion => {
                const keep =
                    count < 10 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });

        // if there is only one suggestion and the suggestion is actually the same as the text in the input, no need to show the suggestion
        if (matches.length === 1 && matches[0].label === inputValue) {
            return []
        }

        return matches
    } else {
        return []
    }
}

function getSuggestionValue(suggestion) {
    return suggestion.label;
}

const styles = theme => ({
    root: {
        height: 'auto',
        flexGrow: 1,
        marginTop: 10,
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: drawerWidth + 10
        },
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: 200,
        overflowY: 'auto'
    },
});

const INPUT_PLACEHOLDER_TEXT_ID = 'inputPlaceholderText'

class AutoSuggestInput extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        wordLookup: PropTypes.string.isRequired,        // current lookup word in the input
        lookupSuggestions: PropTypes.array.isRequired,
        handleLookupChange: PropTypes.func.isRequired,   // handler passed from parent node
        onInputBlur: PropTypes.func.isRequired,
        storeAutoSuggestInputRef: PropTypes.func.isRequired,
        onSelectSuggestion: PropTypes.func.isRequired,
        onKeyPress: PropTypes.func.isRequired,
        i18nStrings: PropTypes.object.isRequired
    }

    constructor (props) {
        super(props)
        this.state = {
            suggestions: [],
        }
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.props.lookupSuggestions),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = (event, { newValue, method }) => {
        this.props.handleLookupChange(newValue, method)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lookupSuggestions !== this.props.lookupSuggestions) {
            this.setState({
                suggestions: getSuggestions(this.props.wordLookup, this.props.lookupSuggestions),
            });
        }
    }

    componentDidMount() {
        this.popperNode.focus()
        this.props.storeAutoSuggestInputRef(this.popperNode)
    }

    render() {
        const { classes, i18nStrings } = this.props;

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            onSuggestionSelected: this.props.onSelectSuggestion,
            getSuggestionValue,
            renderSuggestion,
        };

        return (
            <div className={classes.root}>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        placeholder: i18nStrings[INPUT_PLACEHOLDER_TEXT_ID],
                        value: this.props.wordLookup,
                        onChange: this.handleChange,
                        onBlur: this.props.onInputBlur,
                        inputRef: node => {
                            this.popperNode = node;
                        },
                        InputLabelProps: {
                            shrink: true,
                        },
                        onKeyPress: this.props.onKeyPress   // capture key press
                    }}
                    theme={{
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
                            <Paper
                                square
                                {...options.containerProps}
                                style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
                            >
                                {options.children}
                            </Paper>
                        </Popper>
                    )}
                />
            </div>
        );
    }
}

export default withStyles(styles)(AutoSuggestInput);