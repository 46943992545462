import React from 'react'
import PropTypes from 'prop-types'
import NativeSelect from '@material-ui/core/NativeSelect'
import { languages, langCodes } from "../../i18n/i18ncommon"

const NOT_SELECTED_TEXT_ID = 'notSelectedText'

class NativeLanguageSelect extends React.Component{
    static propTypes = {
        nativeLanguage: PropTypes.string.isRequired,
        i18nStrings: PropTypes.object.isRequired,
        selectNativeLang: PropTypes.func.isRequired,
    }

    handleChange = event => {
        const newValue = event.target.value
        this.props.selectNativeLang(newValue)
    }

    render() {
        const { nativeLanguage, i18nStrings } = this.props
        let languagesWithNotSelected = [i18nStrings[NOT_SELECTED_TEXT_ID]].concat(languages)
        let langCodesWithNotSelected = ['noNeed'].concat(langCodes)

        return (
            <div>
                <NativeSelect
                    value={nativeLanguage}
                    onChange={this.handleChange}
                    name="lang"
                >
                    {
                        languagesWithNotSelected.map((lang, i) => <option key={i} value={langCodesWithNotSelected[i]}>{lang}</option>)
                    }
                </NativeSelect>
            </div>
        )
    }
}

export default NativeLanguageSelect
